import { DataTypeSpecifier } from "verditypes";

import { PrettifiedDataTypeByDataType } from "./multidepthGraphConstants";
import { prettifyDepth } from "./MultiDepthSoilMoistureUtils";
import { SentekSoilMoistureGraphDataStream } from "./types";

type Grid = echarts.EChartOption.Grid;
type XAxis = echarts.EChartOption.XAxis;
type YAxis = echarts.EChartOption.YAxis;

export function useMultiDepthSoilMoistureGraphOptions({
    seperateDepths,
    seperateDataTypes,
    useBoostToSeperateDepths,
    depths,
    dataTypes,
}: {
    seperateDepths: boolean;
    seperateDataTypes: boolean;
    useBoostToSeperateDepths: boolean;
    depths: number[];
    dataTypes: string[];
}) {
    /**
     * This is a doozy of a function, but basically it starts out as basic as possible, and then builds in
     * complexity as it defines the various parts of the graph.
     */

    /**
     * only if we are using boost to "stack" depths and putting different depths on the same graph do we
     * need a separate y axis for each one.
     * @type {boolean}
     */
    const generateYAxisForEachDepth = useBoostToSeperateDepths && !seperateDepths;

    /**
     * Each grid will have only one X axis, but could have several y axes depending on what kind of graph it is
     * @type {number}
     */
    const getYAxesPerGridIndex =
        (seperateDataTypes ? 1 : dataTypes.length) * (generateYAxisForEachDepth ? depths.length : 1);

    /**
     * How many grids we need
     * @type {number}
     */
    const numberOfGrids = (seperateDataTypes ? dataTypes.length : 1) * (seperateDepths ? depths.length : 1);

    /**
     * For each set of x and y axes, there needs to be a unique grid index for them to exist in.
     * @param depth
     * @param dataType
     * @returns {number}
     */
    const getGridIndexOfDepthAndDatatype = (depth: number, dataType: string) => {
        if (dataType === "moisture" && seperateDepths) {
            return 0;
        }
        if (seperateDepths && seperateDataTypes) {
            return depths.indexOf(depth) * dataTypes.length + dataTypes.indexOf(dataType);
        }
        if (seperateDepths) {
            return depths.indexOf(depth);
        }
        if (seperateDataTypes) {
            return dataTypes.indexOf(dataType);
        }
        return 0;
    };

    /**
     * Given a data type and a depth, we should be able to determine the yAxis that represents it. The only thing that matters
     * here is the index of that axis within the grid space it has been assigned. This is helpful for calculating the
     * absolute yAxis index later
     * @param dataType
     * @param depth
     * @returns {any}
     */
    const getYAxisIndexOfDataTypeAndDepthWithinGrid = (dataType: string, depth: number) => {
        if (seperateDataTypes) {
            if (useBoostToSeperateDepths && !seperateDepths) {
                return depths.indexOf(depth);
            }
            return 0;
        }
        if (useBoostToSeperateDepths && !seperateDepths) {
            return dataTypes.indexOf(dataType) * depths.length + depths.indexOf(depth);
        }
        // make sure the special summed graph only appears in the first depth when splitting by depth
        if (depth !== 0 && seperateDepths) {
            return dataTypes.filter((t) => t !== "moisture").indexOf(dataType);
        }
        return dataTypes.indexOf(dataType);
    };

    /**
     * given a data stream, we need to know what the index is of the yAxis it is being rendered on.
     * @param dataStream
     * @returns {number}
     */
    const getYAxisIndexForDataStream = (dataStream: SentekSoilMoistureGraphDataStream): number => {
        const gridIndex = getGridIndexOfDepthAndDatatype(dataStream.depth ?? 0, dataStream.dataType);
        return (
            gridIndex * getYAxesPerGridIndex +
            getYAxisIndexOfDataTypeAndDepthWithinGrid(dataStream.dataType, dataStream.depth ?? 0)
        );
    };

    /**
     * given a data stream, we need to know what the index is of the xAxis it is being rendered on. This
     * is pretty simple because there is only one xAxis per grid
     * @param dataStream
     * @returns {number}
     */
    const getXAxisIndexForDataStream = (dataStream: SentekSoilMoistureGraphDataStream): number =>
        getGridIndexOfDepthAndDatatype(dataStream.depth ?? 0, dataStream.dataType);

    /**
     * The "Boost" is the amount by which a line needs to be offset vertically in order to not touch the line
     * beneath it in a stacked trend graph.
     *
     * Each line has an individual boost, but it is notable that when actually
     * offsetting the line, you must sum all the boosts of all the lines beneath the given one in order to calculate
     * its actual position. This function only returns the individual boost to keep it above the line directly below it.
     *
     * @param dataStreamByID
     * @returns {Record<string, Record<number, number>>}
     */
    const getBoostsByDepthFromDatastreams = (
        dataStreamByID: Record<string, SentekSoilMoistureGraphDataStream>,
    ): Record<string, Record<number, number>> => {
        const boostsByDataTypeByDepth: Record<string, Record<number, number>> = {};
        dataTypes.forEach((dataType) => {
            boostsByDataTypeByDepth[dataType] = {};
            depths.forEach((depth) => {
                const relevantDataStreams = Object.values(dataStreamByID).filter(
                    (ds) => ds.depth === depth && ds.dataType === dataType,
                );
                boostsByDataTypeByDepth[dataType][depth] = Math.max(
                    ...relevantDataStreams.map((ds) => ds.boostNeededToStayAboveLowerDepthDataStream),
                );
            });
        });

        return boostsByDataTypeByDepth;
    };

    /**
     * This determines the minimum and maximum value for each data type, given a collection of data streams. This
     * does not take into account Boost, padding, or any other factors. It only tracks the actual range of values
     * contained in the data stream
     *
     * @param dataStreamByID
     * @returns {Record<string, Record<number, number>>}
     */
    const getRangeByDataTypeFromDatastreams = (
        dataStreamByID: Record<string, SentekSoilMoistureGraphDataStream>,
    ): Record<string, [number, number]> => {
        const rangeByDataType: Record<string, [number, number]> = {};
        dataTypes.forEach((dataType) => {
            const relevantDataStreams = Object.values(dataStreamByID).filter((ds) => ds.dataType === dataType);
            rangeByDataType[dataType] = [
                Math.min(...relevantDataStreams.map((ds) => ds.range[0])),
                Math.max(...relevantDataStreams.map((ds) => ds.range[1])),
            ];
        });
        return rangeByDataType;
    };
    /**
     * Gets yAxes descriptors consistent with apache E-charts based on the datastreams provided
     * @param dataStreamByID
     * @returns {Array<YAxis>}
     */
    const getYAxes = (dataStreamByID: Record<string, SentekSoilMoistureGraphDataStream>) => {
        const yAxes: YAxis[] = [];

        // first we need to know the ranges and boost for each data stream
        const rangesByDataType = getRangeByDataTypeFromDatastreams(dataStreamByID);
        const boostsByDataTypeByDepth = getBoostsByDepthFromDatastreams(dataStreamByID);

        // Then, for each depth and data type, we may need a yAxis
        depths.forEach((depth) => {
            dataTypes.forEach((dataType) => {
                // we must identify where this yAxis will be
                const gridIndex = getGridIndexOfDepthAndDatatype(depth, dataType);
                const yAxisIndexWithinGrid = getYAxisIndexOfDataTypeAndDepthWithinGrid(dataType, depth);
                const yAxisIndex = gridIndex * getYAxesPerGridIndex + yAxisIndexWithinGrid;

                // if the yAxis for this data has already been created, then just return.
                if (yAxes[yAxisIndex]) {
                    return;
                }

                // some constants to make later calculations easier
                const sizeOfRange = rangesByDataType[dataType][1] - rangesByDataType[dataType][0];
                const boostPadding = sizeOfRange * 0.1;

                // this is the total amount of boost that will be applied to the whole set of data.
                // this is useful for informing how much vertical space the entire graph will take up.
                const totalBoostForDataType = useBoostToSeperateDepths
                    ? Object.values(boostsByDataTypeByDepth[dataType])
                          .filter((v) => !Number.isNaN(v) && Number.isFinite(v))
                          .reduce((total, cur) => total + cur + boostPadding, 0)
                    : 0;

                // boostBy is the total amount of boost that will be applied to data streams that use this YAxis
                // boostBy needs to factor in the required boost to get above all the lines below this one
                // as well as some padding
                let boostBy = 0;
                if (useBoostToSeperateDepths) {
                    boostBy = Object.entries(boostsByDataTypeByDepth[dataType])
                        // first we only need to factor in lines with a depth
                        .filter(([_depth, v]) => !Number.isNaN(v) && Number.isFinite(v))
                        // then we only care about lines that are deeper than our line (greater depth)
                        .filter(([entryDepth, _boost]) => Number.parseFloat(entryDepth) >= depth)
                        // then we want to add up the boost for each, including a bit of padding
                        .reduce((total, [_depth, boost]) => total + boost + boostPadding, 0);
                }

                // eslint-disable-next-line prefer-template
                let name = `${PrettifiedDataTypeByDataType[dataType as DataTypeSpecifier] ?? dataType}${seperateDepths ? " " + prettifyDepth(depth) : ""}`;

                // Couple things
                //  - we need to use the base range obviously to get the range we need to show
                //  - we need use sizeOfRange to add paddding to the base range
                //  - According to my math, we should be able to chop totalBoostForDataType / 2 off of the bottom
                //    and it should be accounted for by the shifting y axes and still show the whole graph. I'm a little unsure
                //    about that. If we are having problems, this is where we should fiddle
                //  - We subtract the boost from both min and max to shift the line up by the boost amount
                const minY = rangesByDataType[dataType][0] - sizeOfRange * 0.1 + totalBoostForDataType / 3 - boostBy;
                const maxY = rangesByDataType[dataType][1] + sizeOfRange * 0.1 + totalBoostForDataType - boostBy;

                if (dataType === "moisture") {
                    name = "Summed Moisture (m)";
                }
                else if(dataType === "soilTemperature") {
                    name = `${PrettifiedDataTypeByDataType[dataType as DataTypeSpecifier] ?? dataType} (°C) ${seperateDepths ? ` ${  prettifyDepth(depth)}` : ""}`;
                    // strings form: Soil Temperature (°C) 0" or Soil Temperature (°C) etc.
                }
        
                else if(dataType === "moisture_drillAndDrop") {
                    name = `${PrettifiedDataTypeByDataType[dataType as DataTypeSpecifier] ?? dataType} (%) ${seperateDepths ? ` ${  prettifyDepth(depth)}` : ""}`;
                    // strings form: Drill and Drop Moisture (%) 0" or Drill and Drop Moisture (%) etc.
                }
                let showAxis = yAxisIndexWithinGrid < 2;
                if (seperateDataTypes && yAxisIndexWithinGrid > 0) {
                    showAxis = false;
                }
                const showTicks = !(useBoostToSeperateDepths && dataType !== "moisture") && showAxis;
                // leave this as it is useful if we wanna debug
                // console.log(`Y axis ${name} show: ${show} yAxisIndexInGrid: ${yAxisIndexWithinGrid}, depth:  ${prettifyDepth(depth)}, boostBy: ${boostBy}, individualBoost ${boostsByDataTypeByDepth[dataType][depth]}, minY: ${minY}, maxY: ${maxY}`)
                yAxes[yAxisIndex] = {
                    name: name,
                    type: "value",
                    min: minY,
                    max: maxY,
                    gridIndex: gridIndex,
                    position: yAxisIndexWithinGrid === 0 ? "left" : "right",
                    show: showAxis,
                    axisLabel: showTicks
                        ? {
                              formatter: (value: number) => `${Number.parseFloat(value.toPrecision(4))}`,
                          }
                        : {
                              formatter: () => "",
                          },
                    axisLine: {
                        show: showAxis,
                    },
                    axisPointer: {
                        show: showTicks,
                        type: showTicks ? "line" : "none",
                    },
                };
            });
        });
        return yAxes;
    };

    /**
     * Gets xAxes descriptors consistent with apache E-charts
     * @returns {Array<YAxis>}
     */
    const getXAxes = (): XAxis[] => {
        const xAxes: XAxis[] = [];
        depths.forEach((depth) => {
            dataTypes.forEach((dataType) => {
                const gridIndex = getGridIndexOfDepthAndDatatype(depth, dataType);
                if (xAxes[gridIndex]) {
                    return;
                }
                xAxes[gridIndex] = {
                    type: "time",
                    boundaryGap: false,
                    axisLine: { onZero: true },
                    gridIndex: gridIndex,
                };
            });
        });
        return xAxes;
    };

    /**
     * Gets Grid descriptors consistent with apache E-charts based on the datastreams provided
     * @returns {Array<Grid>}
     */
    const getGrids = (): Grid[] => {
        const grids: Grid[] = [];
        depths.forEach((depth) => {
            dataTypes.forEach((dataType) => {
                const gridIndex = getGridIndexOfDepthAndDatatype(depth, dataType);
                if (grids[gridIndex]) {
                    return;
                }
                grids[gridIndex] = {
                    height: `${60/ numberOfGrids}%`,
                    top: `${10 + (90 / numberOfGrids) * gridIndex}%`, // 10 is the inital distance, every other graph will be 100 / num of grids * i
                };
            });
        });
        if (grids.length === 1) {
            grids[0] = {
                height: `auto`,
            };
        }
        return grids;
    };
    return {
        getXAxisIndexForDataStream,
        getYAxisIndexForDataStream,
        getYAxes,
        getXAxes,
        getGrids,
        numberOfGrids,
    };
}
