import { CalendarToday } from "@mui/icons-material";
import { Icon, Stack, Typography } from "@mui/material";

/**
 * Date icon, shows calendar icon with day number inside and short month name below
 */
export function DateIcon({ date }: { date: Date }) {
    return (
        <Stack
            sx={{
                position: "relative",
                direction: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Icon component={CalendarToday} sx={{ color: "grey", fontSize: 19 }} />
            <Typography
                sx={{
                    color: "grey",
                    fontSize: 8,
                    fontWeight: 500,
                }}
            >
                {date?.toLocaleString("default", { month: "short" }).toUpperCase()}
            </Typography>
            <Typography
                sx={{
                    position: "absolute",
                    top: "6px",
                    fontSize: "8px",
                    fontWeight: 400,
                }}
            >
                {date?.getDate()}
            </Typography>
        </Stack>
    );
}
