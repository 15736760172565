/* eslint-disable class-methods-use-this */
import FocusContext from "../FocusContext";

export default class GhostFocusContext extends FocusContext {
    ghost = true;

    name = "ghost context";

    /**
     *
     * @param {MapEntityBase} mapEntity
     * @return {"hidden" | "focused" | "selected" | "inactive" | "active"}
     */
    defaultFunction = (mapEntity) => this.getPrevContext().defaultFunction(mapEntity);

    /**
     *
     * @param {MouseEvent | TouchEvent} event The event (mouse click, double click, etc.)
     * @param {MapEntityBase} mapEntity
     * @return {boolean} Should this interaction be passed to the next
     * focus handler or the default iteraction handler?
     *
     */
    onInteract = async () => true;

    /**
     * If true, then an element which was focused in the FocusContext frames beneath this
     * focusContext in the stack will still appear focused,
     *
     * This is useful if you are using a new focus context to change what zone a device belongs to
     * or something similar, where the previously focused item is still relevant.
     * @type {boolean}
     */
    enableChainedFocus = true;

    updateMapEntityState(mapEntity, force = false, newEntity = false) {
        this.getPrevContext().updateMapEntityState(mapEntity, force, newEntity);
    }

    get MapEntityStatusOptions() {
        return this.getPrevContext().MapEntityStatusOptions;
    }

    set MapEntityStatusOptions(v) {
        // pass
    }

    setMapEntityStatusOptions(mapEntity, options) {
        this.getPrevContext().setMapEntityStatusOptions(mapEntity, options);
    }

    get hidden() {
        return this.getPrevContext().hidden;
    }

    set hidden(v) {
        // pass
    }

    get focused() {
        return this.getPrevContext().focused;
    }

    set focused(v) {
        // pass
    }

    get selected() {
        return this.getPrevContext().selected;
    }

    set selected(v) {
        // pass
    }

    get inactive() {
        return this.getPrevContext().inactive;
    }

    set inactive(v) {
        // pass
    }

    get active() {
        return this.getPrevContext().active;
    }

    set active(v) {
        // pass
    }

    setHidden(mapEntity, hide = true) {
        this.getPrevContext().setHidden(mapEntity, hide);
    }

    setFocused(mapEntity, focus = true) {
        this.getPrevContext().setFocused(mapEntity, focus);
    }

    get onFocusChanged() {
        return this.getPrevContext().onFocusedChanged;
    }

    set onFocusChanged(v) {
        // pass
    }

    setSelected(mapEntity, select = true) {
        this.getPrevContext().setSelected(mapEntity, select);
    }

    setInactive(mapEntity, inactive = true) {
        this.getPrevContext().setInactive(mapEntity, inactive);
    }

    clearHidden() {
        this.getPrevContext().clearHidden();
    }

    clearFocused() {
        this.getPrevContext().clearFocused();
    }

    clearSelected() {
        this.getPrevContext().clearSelected();
    }

    clearInactive() {
        this.getPrevContext().clearInactive();
    }

    clearActive() {
        this.getPrevContext().clearActive();
    }

    /**
     * Moves map view to map entity given
     * @param {MapEntityBase} mapEntity
     */
    MapViewToEntity(mapEntity) {
        FocusContext.MapViewToEntity(mapEntity);
    }

    getMapEntityState(mapEntity) {
        return this.getPrevContext().getMapEntityState(mapEntity);
    }
}
