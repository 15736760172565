import "./ErrorPageNotFound.scss";

import { Button, Container, Typography } from "@mui/material";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

function ErrorPageNotFound() {
    // This is a 404 page not found error page
    // This will be used when a user tries to access a page that does not exist
    const { t } = useTranslation("pages", { keyPrefix: "errorPageNotFound" });
    const navigate = useNavigate();
    const location = useLocation();

    // Log the attempted path for monitoring purposes
    React.useEffect(() => {
        console.error(`404 error: User attempted to access ${location.pathname}`);
    }, [location.pathname]);

    const handleReturn = () => {
        // Check if we can go back in history, otherwise go home
        if (window.history.length > 2) {
            navigate(-1);
        } else {
            navigate("/");
        }
    };

    const isShortScreen = window.innerHeight < 600;

    return (
        <HelmetProvider>
            <Helmet>
                <title>{t("pageTitle", "Page Not Found")}</title>
                <meta name={"description"} content={t("metaDescription", "The requested page could not be found.")} />
            </Helmet>

            <Container component={"main"} className={"error-page__container"}>
                <section className={"error-page__card"} role={"alert"}>
                    <Typography variant={isShortScreen ? "h2" : "h1"} className={"error-page__title"}>
                        404
                    </Typography>

                    <img
                        src={"/assets/images/404-illustration.webp"}
                        alt={t("notFoundIllustration")}
                        className={"error-page__illustration"}
                    />

                    <Typography variant={isShortScreen ? "h3" : "h2"} className={"error-page__header"}>
                        {t("ErrorMessageHeader")}
                    </Typography>

                    <Typography variant={isShortScreen ? "body2" : "body1"} className={"error-page__body"}>
                        {t("NotFoundMessageDescription")}
                    </Typography>

                    <div className={"error-page__actions"}>
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            className={"error-page__button"}
                            onClick={handleReturn}
                        >
                            {t("goBack")}
                        </Button>

                        <Button
                            variant={"outlined"}
                            color={"primary"}
                            className={"error-page__button"}
                            onClick={() => navigate("/")}
                        >
                            {t("backToDashboard")}
                        </Button>
                    </div>
                </section>
            </Container>
        </HelmetProvider>
    );
}

export default ErrorPageNotFound;
