import { CircleOutlined, Schedule, Sync, WaterDrop } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { blue, red } from "@mui/material/colors";

import { ManualModeOverrideSettings } from "../../../../../../services/deviceDataAccessors/manualMode";

const ICON_SIZE = 16;
const FONT_SIZE = 12;
const ICON_COLOR = blue[500];
const CLOSING_ICON_COLOR = red[500];

const ManualModeTransitioningLabels: Record<TransitionDirection, string> = {
    [ManualModeOverrideSettings.OPEN]: "Opening valve",
    [ManualModeOverrideSettings.CLOSE]: "Closing valve",
};

export enum IconType {
    Static = "static",
    Irrigating = "irrigating",
    Transitioning = "transitioning",
}
export type TransitionDirection = Exclude<ManualModeOverrideSettings, "none">;

interface IconTextProps {
    children: React.ReactNode;
    color: string;
}

function IconText({ children, color }: IconTextProps) {
    return (
        <Typography
            sx={{
                fontWeight: 500,
                color,
                fontSize: FONT_SIZE,
            }}
        >
            {children}
        </Typography>
    );
}

function makeDateTimeString(date: Date) {
    // if date is on current day, do not show the month or day
    const isToday = date.toLocaleDateString() === new Date().toLocaleDateString();
    return `${date.toLocaleString("default", {
        month: isToday ? undefined : "short",
        day: isToday ? undefined : "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    })}`;
}

function StatusText({ type, percentage, direction, isOpen, irrigationEndTime }: IrrigationProgressIconProps) {
    switch (type) {
        case IconType.Transitioning:
            return direction ? (
                <IconText color={direction === "open" ? ICON_COLOR : CLOSING_ICON_COLOR}>
                    {ManualModeTransitioningLabels[direction]}
                </IconText>
            ) : null;
        case IconType.Irrigating:
            return percentage !== undefined ? (
                <IconText color={ICON_COLOR}>
                    {percentage}% {irrigationEndTime ? `ends ${makeDateTimeString(irrigationEndTime)}` : ""}
                </IconText>
            ) : null;
        case IconType.Static:
            return isOpen ? <IconText color={ICON_COLOR}>Valve is open</IconText> : null;
        default:
            return null;
    }
}

const renderIcon = ({
    type,
    isOpen,
    direction,
}: {
    type: IconType;
    isOpen: boolean;
    direction?: TransitionDirection;
}) => {
    if (type === IconType.Irrigating) {
        return (
            <Schedule
                sx={{
                    fontSize: ICON_SIZE,
                    color: ICON_COLOR,
                }}
            />
        );
    }
    if (type === IconType.Transitioning) {
        return (
            <Sync
                sx={{
                    fontSize: ICON_SIZE,
                    color: direction === "open" ? ICON_COLOR : CLOSING_ICON_COLOR,
                }}
            />
        );
    }
    if (type === IconType.Static && isOpen) {
        return (
            <>
                <CircleOutlined
                    sx={{
                        fontSize: ICON_SIZE,
                        color: ICON_COLOR,
                    }}
                />
                <WaterDrop
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: ICON_SIZE * 0.7,
                        color: ICON_COLOR,
                    }}
                />
            </>
        );
    }
    return null;
};

interface IrrigationProgressIconProps {
    type: IconType;
    isOpen?: boolean;
    percentage?: number;
    direction?: TransitionDirection;
    irrigationEndTime?: Date;
}
/**
 * IrrigationProgressIcon displays three distinct states:
 * 1. Static - Shows a circle with Open/Closed text (and static water drop if open)
 * 2. Irrigating - Shows a circle with animated water drop and percentage
 * 3. Transitioning - Shows a spinning icon with direction text
 */
export function IrrigationProgressIcon({
    type,
    isOpen = false,
    percentage,
    direction,
    irrigationEndTime,
}: IrrigationProgressIconProps) {
    return (
        <Box
            sx={{
                display: "inline-flex",
                alignItems: "center",
                gap: 1,
                borderRadius: "24px",
            }}
        >
            <Box sx={{ position: "relative" }}>{renderIcon({ type, isOpen, direction })}</Box>
            <StatusText
                type={type}
                isOpen={isOpen}
                percentage={percentage}
                direction={direction}
                irrigationEndTime={irrigationEndTime}
            />
        </Box>
    );
}
