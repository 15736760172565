import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

/**
 * Sentry initialization and configuration - only enabled in non-local environments
 */
if (import.meta.env.VITE_ENVIRONMENT && import.meta.env.VITE_ENVIRONMENT.trim() !== "local") {
    Sentry.init({
        dsn: "https://ff573f0ef3cb11a503741ded2c568717@o4507340886310912.ingest.us.sentry.io/4507340973932544",
        environment: import.meta.env.VITE_ENVIRONMENT,
        integrations: [
            // Browser performance tracing integration
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),

            // Opt out of masking user data
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
                maskAllInputs: false,
            }),

            // Enable canvas recording
            Sentry.replayCanvasIntegration(),
        ],

        // Capture % of transactions for performance monitoring.
        tracesSampleRate: 1,

        // Capture Replay for % of all sessions,
        replaysSessionSampleRate: 0.1,
        // plus for % of sessions with an error
        replaysOnErrorSampleRate: 1.0,
    });
}
