import { Box } from "@mui/material";
import { isMobile } from "react-device-detect";
import { ThirdPartyDeviceBase } from "verdiapi/dist/Models/Devices/ThirdPartyDeviceBase";
import { DataTypeSpecifier, SDI12SensorClusterConfig, SensorConfig, SensorType } from "verditypes";
import {
    SDI12SensorClusterConfiguration,
    SDI12SensorClusterType,
} from "verditypes/dist/Configurations/SDI12SensorClusterConfiguration";
import { SensorCategory, SensorConfiguration } from "verditypes/dist/Configurations/SensorConfiguration";

import { MultiDepthSoilMoistureGraphModal } from "../../../MultiDepthSoilMoistureGraph/MultiDepthSoilMoistureGraphModal";
import { SDI12SensorTypeSelector } from "./SDI12SensorTypeSelector";

export function DraginoSDI12DeviceOverview({ Device }: { Device: ThirdPartyDeviceBase }) {
    const SDI12DeviceType = Device.deviceTypeSpecificInformation?.SDI12NodeType;
    const clusterConfig: SDI12SensorClusterConfiguration =
        // @ts-ignore
        SDI12SensorClusterConfig.SDI12SensorClusterConfigurations[SDI12DeviceType as SDI12SensorClusterType];
    const dataTypeBySensorCategory: Partial<Record<SensorCategory, DataTypeSpecifier>> = {};
    if (clusterConfig) {
        clusterConfig.connectedSensors.forEach((connectedSensor): void => {
            const connectedSensorConfig: SensorConfiguration =
                SensorConfig.SensorConfigurations[connectedSensor.sensorType as SensorType];
            if (connectedSensorConfig) {
                dataTypeBySensorCategory[connectedSensorConfig.sensorCategory as SensorCategory] =
                    connectedSensorConfig.parsedDataType as DataTypeSpecifier;
            }
        });
    }
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4, py: 4, px: isMobile ? 4 : 0 }}>
            <MultiDepthSoilMoistureGraphModal
                device={Device}
                moistureDataType={
                    dataTypeBySensorCategory.volumetricSoilMoisture ??
                    dataTypeBySensorCategory.resistiveSoilMoisture ??
                    dataTypeBySensorCategory.soilTension
                }
                salinityDataType={dataTypeBySensorCategory.soilElectricalConductivity}
                temperatureDataType={dataTypeBySensorCategory.soilTemperature}
                graphSubtitle={clusterConfig?.manufacturerName}
            />

            <SDI12SensorTypeSelector Device={Device} />
        </Box>
    );
}
