import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, TableCell, TableRow, TextField } from "@mui/material";
import { CSSProperties, useState } from "react";
import { ExternalLink, ExternalLinkConfigInfo } from "verditypes";

interface ExternalLinkTableRowProps {
    externalLink: ExternalLink;
    isPresetLink: boolean;
    presetLinkConfig?: ExternalLinkConfigInfo;
    handleSave: (rowKey: string, updatedRow: ExternalLink, isNewRow: boolean) => Promise<boolean>
    onDelete: (key: string) => void;
    styles: {
        nameWidth: string;
        urlWidth: string;
        actionWidth: string;
        cellPadding: string;
        defaultStyles: CSSProperties;
        buttonStyles: CSSProperties;
    };
    isNewRow?: boolean;
}

function ExternalLinkTableRow({
    externalLink,
    isPresetLink,
    presetLinkConfig,
    handleSave,
    onDelete,
    styles,
    isNewRow = false
}: ExternalLinkTableRowProps) {
    // Flag to decide if the current row is being edited
    const [isEditing, setIsEditing] = useState(isNewRow);

    // Temp data to store the changes made to the row while editing
    const [tempData, setTempData] = useState<ExternalLink>(externalLink);


    // Function to cancel the editing of the row
    const onCancel = () => {
        setTempData(externalLink);
        setIsEditing(false);
        if(isNewRow){
            onDelete(externalLink.key);
        }
    };
    // if you are editing a row then it will show the editing options which are Save, Cancel and Delete (if this is a non-preconfigured link row)
    // if you aren't editing the row then it will show you: open in a new tab, copy, and edit
    return (
        <TableRow key={externalLink.key}>
            <TableCell
                sx={{
                    width: styles.nameWidth,
                    padding: styles.cellPadding,
                    ...styles.defaultStyles,
                }}
            >
                {isEditing && !isPresetLink ? (
                    <TextField
                        fullWidth
                        variant={"outlined"}
                        size={"small"}
                        value={tempData.name}
                        onChange={(e) => setTempData({ ...tempData, name: e.target.value })}
                        placeholder={"Name"}
                        multiline
                        maxRows={Infinity}
                    />
                ) : (
                    <p
                        style={{
                            maxWidth: "100%",
                            wordWrap: "break-word",
                            padding: styles.cellPadding,
                            display: "block",
                            textAlign: "center",
                            margin: "auto",
                            ...styles.defaultStyles,
                        }}
                    >
                        {externalLink.name}
                    </p>
                )}
            </TableCell>
            <TableCell
                sx={{
                    width: styles.urlWidth,
                    wordWrap: "break-word",
                    padding: styles.cellPadding,
                    ...styles.defaultStyles,
                }}
            >
                {isEditing ? (
                    <TextField
                        fullWidth
                        variant={"outlined"}
                        size={"small"}
                        value={tempData.url}
                        onChange={(e) => setTempData({ ...tempData, url: e.target.value })}
                        placeholder={presetLinkConfig?.sampleURL || "URL"}
                        sx={{ display: "block" }}
                        multiline
                        maxRows={Infinity}
                    />
                ) : (
                    <a
                        href={externalLink.url.startsWith("http") ? externalLink.url : `https://${externalLink.url}`}
                        target={"_blank"}
                        rel={"noreferrer"}
                        style={{
                            display: "block",
                            maxWidth: "100%",
                            padding: styles.cellPadding,
                            ...styles.defaultStyles,
                        }}
                    >
                        {externalLink.url || "Not Set"}
                    </a>
                )}
            </TableCell>
            <TableCell sx={{ width: styles.actionWidth, padding: styles.cellPadding }}>
                {isEditing ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "4px",
                            padding: "4px",
                            paddingLeft: { xs: "5px", sm: "20px", md: "30px" }
                        }}
                    >
                        <Button
                            variant={"contained"}
                            color={"success"}
                            size={"small"}
                            sx={{ ...styles.buttonStyles }}
                            onClick={async () => {

                                const successfulSave = await handleSave(externalLink.key, tempData, isNewRow);
                                if (successfulSave) {
                                    setIsEditing(false);
                                }
                                }
                            }

                        >
                            Save
                        </Button>
                        <Button
                            variant={"outlined"}
                            color={"error"}
                            size={"small"}
                            sx={{ ...styles.buttonStyles }}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>

                        {!isPresetLink && (
                            <Button
                                variant={"outlined"}
                                color={"error"}
                                size={"small"}
                                sx={{ ...styles.buttonStyles }}
                                onClick={() => onDelete(externalLink.key)}
                            >
                                <DeleteIcon fontSize={"small"} />
                            </Button>
                        )}
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "4px",
                            padding: "4px",
                            flexWrap: "wrap",
                        }}
                    >
                        <Button
                            size={"small"}
                            startIcon={<EditIcon fontSize={"small"} />}
                            onClick={() => setIsEditing(true)}
                            sx={{ ...styles.buttonStyles, minWidth: "60px" }}
                        >
                            <span style={{ fontSize: "0.7rem", marginRight: "4px" }}>Edit</span>
                        </Button>

                        <Button
                            size={"small"}
                            startIcon={<FileCopyIcon fontSize={"small"} />}
                            onClick={() => navigator.clipboard.writeText(externalLink.url)}
                            sx={{ ...styles.buttonStyles, minWidth: "60px" }}
                        >
                            <span style={{ fontSize: "0.7rem", marginRight: "4px" }}>Copy</span>
                        </Button>

                        <Button
                            size={"small"}
                            startIcon={<OpenInNewIcon fontSize={"small"} />}
                            onClick={() => {
                                const url = externalLink.url;
                                if (url) {
                                    const fixedUrl = url.startsWith("http") ? url : `https://${url}`;
                                    window.open(fixedUrl, "_blank", "noopener,noreferrer");
                                }
                            }}
                            sx={{ ...styles.buttonStyles, minWidth: "60px" }}
                        >
                            <span style={{ fontSize: "0.7rem", marginRight: "4px" }}>Open</span>
                        </Button>
                    </Box>
                )}
            </TableCell>
        </TableRow>
    );
}

export default ExternalLinkTableRow;
