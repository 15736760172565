import { styled } from "@mui/material";

import { SPACING } from "./constants";

export const StyledList = styled("ul")({
    "listStyleType": "disc",
    "paddingLeft": SPACING.MEDIUM,
    "fontSize": "14px",
    "& > li": {
        marginBottom: SPACING.SMALL,
    },
    "& > li:last-child": {
        marginBottom: 0,
    },
    // nested lists use hollow circle
    "& ul": {
        marginTop: SPACING.SMALL,
        marginBottom: SPACING.SMALL,
        listStyleType: "circle",
    },
});

export const StyledListItem = styled("li")({
    "&::marker": {
        color: "#666",
    },
});

// Centers image horizontally
export const ImageContainer = styled("div")({
    textAlign: "center",
    width: "100%",
    display: "flex",
    justifyContent: "center",
});

// Standardized image sizes
export const SmallImage = styled("img")({
    maxWidth: "108px",
    width: "100%",
    height: "auto",
    borderRadius: "8px",
});

export const MediumImage = styled("img")({
    maxWidth: "220px",
    width: "100%",
    height: "auto",
    borderRadius: "8px",
});

export const LargeImage = styled("img")({
    width: "100%",
    maxWidth: "576px",
    height: "auto",
    borderRadius: "8px",
});

// Two column layout with a gap between the two columns
export const TwoColumnLayout = styled("div")({
    "display": "grid",
    "gridTemplateColumns": "1fr 1fr",
    "gap": SPACING.MEDIUM,
    "marginBottom": SPACING.MEDIUM,
    "@media (max-width: 600px)": {
        gridTemplateColumns: "1fr",
        justifyItems: "center",
    },
});
