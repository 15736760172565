import { useCallback, useEffect, useState } from "react";

import { getValue, LocalStorageKey, setValue } from "../../services/localStorage";
import { PRODUCT_UPDATE_VERSION_NUMBER } from "./constants";
import { type ProductUpdate } from "./productUpdates/productUpdates";

let openedThisSession = false;
/**
 * Hook for managing product update modal state and automatically opening the modal on startup
 */
export function useProductUpdateModal({ defaultProductUpdate }: { defaultProductUpdate: ProductUpdate }) {
    const [isOpen, setIsOpen] = useState(false);
    const [currentProductUpdate, setCurrentProductUpdate] = useState<ProductUpdate>(defaultProductUpdate);

    const productUpdateDismissed = getValue(LocalStorageKey.PRODUCT_UPDATE_DISMISSED);

    const setProductUpdateDismissed = useCallback(() => {
        setValue(LocalStorageKey.PRODUCT_UPDATE_DISMISSED, true);
    }, []);

    const closeProductUpdateModal = useCallback(() => {
        setIsOpen(false);
        setProductUpdateDismissed();
    }, [setIsOpen, setProductUpdateDismissed]);

    const openProductUpdateModal = useCallback(
        (newProductUpdate: ProductUpdate) => {
            setCurrentProductUpdate(newProductUpdate);
            setIsOpen(true);
        },
        [setIsOpen, setCurrentProductUpdate],
    );

    // Reset the key if the version has changed
    useEffect(() => {
        const productUpdateVersion = getValue(LocalStorageKey.PRODUCT_UPDATE_VERSION);
        // Initial or reset the key if the version has changed
        if (productUpdateVersion === undefined || productUpdateVersion !== PRODUCT_UPDATE_VERSION_NUMBER) {
            setValue(LocalStorageKey.PRODUCT_UPDATE_VERSION, PRODUCT_UPDATE_VERSION_NUMBER);
            setValue(LocalStorageKey.PRODUCT_UPDATE_DISMISSED, false);
        }
    }, []);

    // Trigger the modal to open once per session, if the active product update is not marked as dismissed
    useEffect(() => {
        if (!productUpdateDismissed && !openedThisSession && defaultProductUpdate.openOnStartup) {
            openProductUpdateModal(defaultProductUpdate);
            openedThisSession = true;
        }
    }, [openProductUpdateModal, defaultProductUpdate, productUpdateDismissed]);

    return { isOpen, productUpdateDismissed, closeProductUpdateModal, openProductUpdateModal, currentProductUpdate };
}
