import { dayMs } from "verditypes/dist/timeConstants";

export const PRODUCT_UPDATE_PAGE_URL =
    "https://grow-with-verdi.notion.site/Verdi-Product-Updates-1dfba6ac5d1f47a5baf7affefeb65aed";

/**
 * Indicates current version of product update.
 *
 * Change this number when the product update is updated to reset the dismissed state
 * and open the product update on startup again.
 */
export const PRODUCT_UPDATE_VERSION_NUMBER = 2;

/**
 * Time after a product update's date that the new tag on the product update icon and product update menu will be hidden
 */
export const DEFAULT_NEW_TAG_EXPIRY_TIME = dayMs * 14; // 14 days in ms
