import { Stack } from "@mui/material";

import { GroupMenuCategoryTitle } from "./GroupMenuCategoryTitle";
import { GroupMenuItem } from "./GroupMenuItem";
import { type GroupItem, SharedGroupMenuProps } from "./types";

interface GroupMenuContentsProps extends SharedGroupMenuProps {
    categorizedItems: Record<string, GroupItem[]>;
}
/**
 * Displays the contents of the group menu (shared between mobile and desktop)
 */
export function GroupMenuContents({ categorizedItems, onClose, groupMenuOptions }: GroupMenuContentsProps) {
    const { menuItemHeight } = groupMenuOptions;

    // Close the menu on clicking a menu item
    const handleClickMenuItem = (e: React.MouseEvent<HTMLElement>, group: GroupItem) => {
        onClose();
        group.onClick(e);
    };

    return (
        <>
            {Object.entries(categorizedItems).map(([groupName, groupItems]) => (
                <Stack key={groupName} flexDirection={"column"} gap={1}>
                    {groupName && groupItems[0]?.zones && <GroupMenuCategoryTitle zones={groupItems[0].zones} />}
                    {groupItems.map((item) => (
                        <GroupMenuItem
                            key={item.id}
                            icon={item.icon}
                            label={item.label}
                            onClick={(e) => handleClickMenuItem(e, item)}
                            menuItemHeight={menuItemHeight}
                        />
                    ))}
                </Stack>
            ))}
        </>
    );
}
