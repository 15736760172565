import { dayMs, hourMs, minuteMs } from "verdiapi/dist/HelperFunctions";

// Returns a string in the format "1d 12:00:00"
// Collapses down to minutes:seconds when no values eg 12:00
export function timeElapsedString(ms: number, dayChar: string = "d") {
    if (ms < 1000) {
        return "00:00";
    }
    const days = Math.floor(ms / dayMs).toString();
    const hours = Math.floor((ms % dayMs) / hourMs)
        .toString()
        .padStart(2, "0");
    const minutes = Math.floor((ms % hourMs) / minuteMs)
        .toString()
        .padStart(2, "0");
    const seconds = Math.floor((ms % minuteMs) / 1000)
        .toString()
        .padStart(2, "0");

    if (Number(days) !== 0) {
        return `${days}${dayChar} ${hours}:${minutes}:${seconds}`;
    }
    if (Number(hours) !== 0) {
        return `${hours}:${minutes}:${seconds}`;
    }

    return `${minutes}:${seconds}`;
}
