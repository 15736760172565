import { DataThresholding } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { ThirdPartyDeviceBase } from "verdiapi/dist/Models/Devices/ThirdPartyDeviceBase";
import { DataTypeSpecifier } from "verditypes";

import { MultiDepthSoilMoistureGraph } from "./MultiDepthSoilMoistureGraph";

export function MultiDepthSoilMoistureGraphModal({
    device,
    ...graphProps
}: {
    device: ThirdPartyDeviceBase;
    moistureDataType?: DataTypeSpecifier;
    salinityDataType?: DataTypeSpecifier;
    temperatureDataType?: DataTypeSpecifier;
    graphSubtitle?: string;
    graphTitle?: string;
}) {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <>
            <Button
                variant={"outlined"}
                sx={{ display: "flex", alignItems: "center", gap: 2, width: "max-content" }}
                onClick={() => {
                    setIsOpen(true);
                }}
            >
                View Data
                <DataThresholding />
            </Button>

            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                maxWidth={"lg"}
                fullWidth={true}
                style={{
                    overflow: "visible",
                }}
            >
                <DialogTitle>Soil moisture graph multi depth probe</DialogTitle>
                <DialogContent>
                    <MultiDepthSoilMoistureGraph device={device} {...graphProps} />
                </DialogContent>
            </Dialog>
        </>
    );
}
