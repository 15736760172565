import { SxProps } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { isMobile } from "react-device-detect";

export const SPACING = {
    SMALL: "6px", // Gaps between items within a list
    MEDIUM: "12px", // Gaps between items within a section
    LARGE: "24px",
    XLARGE: "32px", // Gaps between sections
};

const variants: Record<"pageTitle" | "title" | "subtitle" | "body", Variant> = {
    pageTitle: isMobile ? "h3" : "h2",
    title: isMobile ? "h4" : "h3",
    subtitle: isMobile ? "h6" : "h5",
    body: isMobile ? "body1" : "body1",
};

export const typographyOptions: Record<
    "pageTitle" | "title" | "subtitle" | "body",
    { sx?: SxProps; variant: Variant }
> = {
    pageTitle: {
        sx: {
            fontWeight: 600,
            fontSize: "24px",
            mb: "12px",
        },
        variant: variants.pageTitle,
    },
    title: {
        sx: {
            mb: "8px",
        },
        variant: variants.title,
    },
    subtitle: {
        sx: {
            my: "8px",
            fontWeight: 500,
        },
        variant: variants.subtitle,
    },
    body: {
        sx: {
            my: "8px",
            fontSize: "14px",
        },
        variant: variants.body,
    },
} as const;
