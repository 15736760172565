import { Divider, Stack, Typography } from "@mui/material";

import { SPACING, typographyOptions } from "../constants";
import {
    ImageContainer,
    LargeImage,
    MediumImage,
    StyledList,
    StyledListItem,
    TwoColumnLayout,
} from "../ProductUpdateComponents";
// Import media assets
import chatbotIcon from "./assets/docsAIChatbotIcon.jpeg";
import docsAiScreenshot from "./assets/docsAIScreenshot.png";

export function DocsAIProductUpdate() {
    return (
        <Stack sx={{ gap: SPACING.XLARGE }}>
            <section>
                <Typography sx={typographyOptions.pageTitle.sx} variant={typographyOptions.pageTitle.variant}>
                    Verdi Docs AI assistant — Instant answers. Any time. Any language. 🤖
                </Typography>

                <ImageContainer sx={{ marginY: SPACING.LARGE }}>
                    <LargeImage src={docsAiScreenshot} alt={"Verdi Docs AI assistant screenshot"} />
                </ImageContainer>

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    At Verdi, we know that after-sales support is critical. This is why we offer remote phone, text, and
                    email support along with in-person field visits. But we also understand that sometimes you need
                    quick answers on your own schedule—even outside of our support hours.
                </Typography>

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    That's why we've built Docs AI directly into the Verdi Dashboard. This AI-powered assistant is
                    available 24/7, ready to answer your questions instantly. Whether you need help with product
                    features, troubleshooting, or step-by-step instructions, Docs AI is here to assist.
                </Typography>
            </section>

            <section>
                <Typography sx={typographyOptions.title.sx} variant={typographyOptions.title.variant}>
                    Try it for Yourself
                </Typography>

                <TwoColumnLayout>
                    <div>
                        <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                            Simply click the Docs AI icon in the top navigation bar on the Verdi Dashboard, type your
                            question in any language (including English and Spanish), and the AI assistant will provide
                            an instant response in your language. Here are a few examples of what you can ask:
                        </Typography>
                    </div>
                    <div>
                        <ImageContainer>
                            <MediumImage src={chatbotIcon} alt={"Verdi Dashboard Chatbot Icon"} />
                        </ImageContainer>
                    </div>
                </TwoColumnLayout>

                <StyledList>
                    <StyledListItem>How do I schedule an irrigation?</StyledListItem>
                    <StyledListItem>
                        I scheduled an irrigation, but the valve isn't opening. What should I do?
                    </StyledListItem>
                    <StyledListItem>How do I check if my Block Controller is online?</StyledListItem>
                    <StyledListItem>How do I stop an irrigation?</StyledListItem>
                    <StyledListItem>How do I calculate the field capacity threshold?</StyledListItem>
                    <StyledListItem>How do I view multi-depth soil moisture data?</StyledListItem>
                    <StyledListItem>What solenoids are compatible with the Verdi Block Controller?</StyledListItem>
                    <StyledListItem>What are the main features of Verdi?</StyledListItem>
                </StyledList>

                <Typography
                    sx={{ ...typographyOptions.body.sx, marginTop: SPACING.LARGE }}
                    variant={typographyOptions.body.variant}
                >
                    Keep in mind that Docs AI uses our documentation to provide answers to your questions so please let
                    us know if you run into a situation where it doesn't have the answers you're looking for and we can
                    expand our documentation.
                </Typography>
            </section>

            <Divider />

            <section>
                <Typography sx={typographyOptions.title.sx} variant={typographyOptions.title.variant}>
                    Let us know what you think! 💭
                </Typography>

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    Docs AI is an early-release feature, and we're continuously improving it. We have lots of ideas for
                    how we can expand this tool to provide more powerful functionality which we're super excited to
                    release.
                </Typography>

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    As always, we want to make sure these changes work for you and improve your growing experience. We'd
                    love to get your feedback on ways we can improve the design and any issues you run into.
                </Typography>

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    Have any feedback or questions?
                </Typography>

                <StyledList>
                    <StyledListItem>Use the feedback form on the Verdi Dashboard</StyledListItem>
                    <StyledListItem>
                        Email us at <a href={"mailto:support@verdiag.com"}>support@verdiag.com</a>
                    </StyledListItem>
                    <StyledListItem>
                        Call or text us at <a href={"tel:+1-855-945-5628"}>1-855-945-5628</a>
                    </StyledListItem>
                </StyledList>

                <Divider sx={{ marginY: SPACING.LARGE }} />

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    We hope you love this new update and we look forward to sharing more exciting releases soon!
                </Typography>

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    Happy growing! 🌱
                </Typography>

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    The Verdi Team
                </Typography>
            </section>
        </Stack>
    );
}
