import { StateCreator } from "zustand";

import { INFO_CARD_TYPE } from "../components/specialized/infoCards/InfoCardTypes";
import MapEntityBase from "../services/mapManagement/mapEntities/MapEntityBase";
import { CurrentSlice } from "./currentSlice";

export interface LayoutSlice {
    isCardOpen: boolean;
    cardHeight: number;
    cardType: INFO_CARD_TYPE | undefined;
    setCardHeight: (height: number) => void;
    openCard: ({ entity, cardType }: { entity?: MapEntityBase; cardType: INFO_CARD_TYPE }) => void;
    closeCard: () => void;
}

export const createLayoutSlice: StateCreator<LayoutSlice & CurrentSlice, [], [], LayoutSlice> = (set, get) => ({
    isCardOpen: false,
    cardHeight: 0,
    cardType: undefined,
    setCardHeight: (height: number) => set({ cardHeight: height }),
    /**
     * Sets all necessary state for displaying a card
     */
    openCard: ({ entity, cardType }: { entity?: MapEntityBase; cardType: INFO_CARD_TYPE }) => {
        set({ isCardOpen: true, cardType });
        if (entity) {
            get().setSelectedMapEntity(entity);
        }
    },
    closeCard: () => set({ isCardOpen: false, cardType: undefined }),
});
