import React from "react";
import { DeviceConfigurationsByType } from "verditypes/dist/Configurations/DeviceConfiguration";

import DraginoGatewayOverview from "../infoCards/DeviceInfoCard/DraginoGateway/DraginoGatewayOverview";
import { DraginoSDI12DeviceOverview } from "../infoCards/DeviceInfoCard/DraginoSDI12Device/DraginoSDI12DeviceOverview";
import { BlockValveStatus } from "./BlockValveStatus";
import { MicrovalveStatus } from "./Microvalve/MicrovalveStatus";
import SensoterraOverview from "./SensoterraOverview";

export default class DeviceOverview extends React.Component {
    /**
     * @param {{Device: Device, StartEditing: Boolean, OnZonesChanged: function(Zone, Zone), ShowValveState: Boolean}} props
     */
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if (this.props.Device) {
            const DeviceType = this.props.Device.type;
            const valveType = DeviceConfigurationsByType[DeviceType]?.valveType;
            if (valveType === "block" && DeviceConfigurationsByType[DeviceType]?.valveCount < 2) {
                return <BlockValveStatus {...this.props} />;
            }
            if (valveType === "micro" || DeviceConfigurationsByType[DeviceType]?.supportedSensors > 0) {
                return <MicrovalveStatus {...this.props} />;
            }
            if (DeviceType === "msense") {
                return <SensoterraOverview {...this.props} />;
            }
            if (DeviceType === "draginoSDI12LBLSNode") {
                return <DraginoSDI12DeviceOverview {...this.props} />;
            }
            if (DeviceType === "draginoDL0S8N") {
                // Dragino Base Station Overview, please note that this is currently empty but will likely be implemented in the future (as of 2025 03 13)
                return <DraginoGatewayOverview />;
            }
            console.warn(`No overview panel for device type ${DeviceType}`);
        }
        return null;
    }
}
