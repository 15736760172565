import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SessionHandler } from "verdiapi";

import { FilledSelect } from "../../components/generic/FilledSelect";
import FocusContext from "../../services/mapManagement/FocusContext";

export function AccountSelect({ label, showDefaultValue = true }) {
    const { t } = useTranslation("specializedComponents", { keyPrefix: "mapControlBar.accountSelect" });
    const defaultValue = t("selectAccount");
    const defaultState = useRef(defaultValue);

    // if the default value is shown, set the selected account to the default value else set it to an empty string so that MUI's label animation works
    const [selectedAccountId, setSelectedAccountId] = React.useState(showDefaultValue ? defaultValue : "");
    const onSelectAccount = (e) => {
        const value = e.target.value;
        FocusContext.startLoadHandler("Loading Account", "sessionLoad");
        SessionHandler.changeUser(value);
        setSelectedAccountId(value);
    };

    const sortAccounts = () => {
        if (!SessionHandler.accounts) {
            return;
        }

        SessionHandler.accounts.sort((a, b) => {
            if (a.name && b.name) {
                return a.name.localeCompare(b.name);
            }
            if (a.name) {
                return 1;
            }
            return -1;
        });
    };

    // Reset the default state on a language change (if the default is still selected)
    useEffect(() => {
        if (selectedAccountId === defaultState.current) {
            setSelectedAccountId(defaultValue);
            defaultState.current = defaultValue;
        }
    }, [defaultValue]);

    // Load the listeners for account
    useEffect(() => {
        const accountChangeListener = () => {
            sortAccounts();

            if (SessionHandler.currentUserObject && SessionHandler.currentUserObject.id !== selectedAccountId) {
                setSelectedAccountId(SessionHandler.currentUserObject.id);
            }
        };

        SessionHandler.onSessionLoad.addListener(accountChangeListener);

        return () => {
            SessionHandler.onSessionLoad.removeListener(accountChangeListener);
        };
    }, []);

    // Accounts that only admins can access and are meant for testing
    const adminUseAccountIDs = new Set([
        "608b4eb9cb713f0004608602", // FakeAccount
        "60e4a6a1ed3ff36a3cb2a224", // NotReal
        "6254dcf5d5cf7e0004bc4614", // CJU
        "5eb9b7291a398f0004be34c6", // Jason
        "6041a14d58dac000042d8416", // Jacky2
        "602b1cda2fbbd93930be735c", // NewTestUser
        "5eb2181db8ab856ba18b83ae", // Arthur
    ]);

    // Create the select options and subheaders for admin and user accounts
    const selectOptions = useMemo(() => {
        const availableAccounts = SessionHandler.accounts || [];
        // Separate the accounts into admin-use and customer accounts by seeing if the account ID is in the adminUseAccountIDs set
        // Reducer function iterates through the available accounts and separates them into two arrays: adminUseAccounts and customerAccounts
        const { customerAccounts, adminUseAccounts } = availableAccounts.reduce(
            (acc, account) => {
                if (adminUseAccountIDs.has(account.id)) {
                    acc.adminUseAccounts.push(account);
                } else {
                    acc.customerAccounts.push(account);
                }
                return acc;
            },
            { customerAccounts: [], adminUseAccounts: [] },
        );
        return [
            ...(adminUseAccounts.length > 0
                ? [
                      { id: "admin-header", name: "Admin-use accounts", subheader: true },
                      ...adminUseAccounts.map((account) => ({
                          id: account.id,
                          value: account.id,
                          name: account.name,
                      })),
                  ]
                : []),
            ...(adminUseAccounts.length > 0 && customerAccounts.length > 0
                ? [{ id: "customer-accounts-header", name: "Customer accounts", subheader: true }]
                : []),
            ...customerAccounts.map((account) => ({
                id: account.id,
                value: account.id,
                name: account.name,
            })),
        ];
    }, [SessionHandler.accounts]);
    // Form control for selecting an account and separates admin and user accounts
    return (
        <FilledSelect
            labelId={"AdminAccountSelectorInputLabel"}
            defaultOptionLabel={showDefaultValue ? defaultValue : undefined} // show the default value if showDefaultValue is true
            selectOptions={selectOptions}
            onSelect={onSelectAccount}
            value={selectedAccountId}
            label={label}
            // Since this component lives in the TopNavbar, we override the default white bg style
            backgroundColor={"unset"}
            // This is so that in the case of a label, the highlight from MUI won't be shown
            inputLabelProps={{
                color: "dark",
            }}
            disableUnderline={true}
            formControlProps={{
                sx: {
                    height: "var(--nav-bar-height, 55px)",
                },
            }}
            selectProps={{
                sx: {
                    height: "var(--nav-bar-height, 55px)",
                },
            }}
        />
    );
}
