/**
 * width at which the card is considered to take up most of viewport width
 * and we should pan the map to move the selected marker into view (to avoid behing hidden by the card)
 */
export const deviceCardPanBreakpoint = 800;

/**
 * The card should take up the entire width (no x-margin)
 */
export const deviceCardFullWidthBreakpoint = 500;
