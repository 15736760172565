import "./document360Chatbot.scss";

import { SvgIcon } from "@mui/material";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { IconButtonWithTooltip } from "src/components/generic/IconButtonWithTooltip/IconButtonWithTooltip";

import { Tag } from "../../components/generic/Tag/Tag";
import { TAG } from "../../components/generic/Tag/types";

const blurChatbotInput = () => {
    const iframeBody: HTMLIFrameElement | null = document.getElementById(
        "document360-body-iframe",
    ) as HTMLIFrameElement;
    if (iframeBody) {
        const textArea: HTMLTextAreaElement | null = iframeBody?.contentWindow?.document.getElementsByClassName(
            "search-input",
        )?.[0] as HTMLTextAreaElement;
        textArea?.blur();
    }
};

const replaceGreetingMessage = () => {
    const iframeBody: HTMLIFrameElement | null = document.getElementById(
        "document360-body-iframe",
    ) as HTMLIFrameElement;
    if (iframeBody) {
        const greetingMessage: HTMLDivElement | null = iframeBody?.contentWindow?.document.getElementsByClassName(
            "primary-msg",
        )?.[0] as HTMLDivElement;
        if (greetingMessage && greetingMessage.innerHTML !== "Hi, I'm Verdi's Docs AI!") {
            greetingMessage.innerHTML = "Hi, I'm Verdi's Docs AI!";
        }
    }
};

export function Document360ChatbotButton() {
    /**
     * Constantly poll to replace the greeting message with a custom one
     *
     * NOTE: The user can trigger a "new chat" from within the window which once again displays the default greeting message,
     * so we need to constantly poll checking for this element while the chatbot is open. This interval is always running
     * and though lightweight with minimal performance impact, it is bad practice
     */
    useEffect(() => {
        const interval = setInterval(() => {
            const chatbotIsOpen = document.getElementById("assistantDoc360")?.classList.contains("scale-in-open-br");
            if (chatbotIsOpen) {
                replaceGreetingMessage();
            }
        }, 50);
        return () => clearInterval(interval);
    }, []);

    const openChatbot = () => {
        // Open chatbot by simulating click on original (hidden) trigger
        const iframe: HTMLIFrameElement | null = document.getElementById(
            "document360-widget-iframe",
        ) as HTMLIFrameElement;
        if (iframe) {
            const element = iframe?.contentWindow?.document.getElementById("doc360-button");
            element?.click();
        }

        /**
         * Doc360 automatically focuses on the chat input when the chat is opened. This sometimes means
         * the keyboard will popup before the chat is done animating-in, which prevents the mobile keyboard from
         * pushing the chat's textarea above the keyboard.
         *
         * Since we cannot affect this timing (embedded in the Doc360 script), manually blur the input and
         * force the user to focus the input themselves after the chat finishes animating in
         */
        if (isMobile) {
            blurChatbotInput();
        }
    };

    return (
        <IconButtonWithTooltip
            badgeContent={<Tag tagVariant={TAG.BETA} sx={{ fontSize: "8px" }} wrapperSx={{ height: "14px" }} />}
            badgeSx={{ top: 2, right: -12 }}
            title={"Docs AI"}
            onClick={openChatbot}
            sx={{
                color: "transparent",
                stroke: "rgba(0, 0, 0, 0.54)",
                backgroundColor: "transparent",
            }}
            icon={
                <SvgIcon
                    width={"24"}
                    height={"24"}
                    viewBox={"0 0 24 24"}
                    strokeWidth={"2.5"}
                    strokeLinecap={"round"}
                    strokeLinejoin={"round"}
                >
                    <path d={"M12 4V2H8"} />
                    <path d={"m8 18-4 4V8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"} />
                    <path d={"M1 12h1"} />
                    <path d={"M9 11v2"} />
                    <path d={"M15 11v2"} />
                    <path d={"M22 12h1"} />
                </SvgIcon>
            }
        />
    );
}
