import { makeIconGraphicConfig } from "../../../components/icons/MapIcons/DeviceIcon/components/IconGraphic/constants";
import DeviceMarkerIcon from "../../../components/icons/MapIcons/DeviceMarkerIcon";
import { FunctionalDeviceInfoCard } from "../../../components/specialized/infoCards/DeviceInfoCard/FunctionalDeviceInfoCard";
import { GenerateUID } from "../../../utils/HelperFunctions";
import { deviceIconUpdateEvent } from "../iconManager/IconLayer";
import MapEntityBase from "./MapEntityBase";

const allDeviceEntities = new Set();

export default class DeviceMapEntity extends MapEntityBase {
    static infoCardClass = FunctionalDeviceInfoCard;

    distanceToNearest = 100000000;

    /**
     *@type {HistoricalDataBase}
     */
    moistureDataBase;

    infoCardClass = FunctionalDeviceInfoCard;

    warningText = undefined;

    warningToShow = undefined;

    manualModeWarning = false;

    /**
     *  @type {irrigationDevice}
     */
    // redeclaration of model is just to specify a different type from the base class
    // required for the migration to es2022 with Vite
    get model() {
        return this.model;
    }

    sensorOutOfDate = false;
    /**
     * @param {irrigationDevice} IrrigationDevice
     */

    get type() {
        return this.model.type || this.model.deviceType;
    }

    get DOMClassNames() {
        const initialClassNames = super.DOMClassNames;
        if (this.manualModeWarning) {
            initialClassNames.push("ME_ManualMode");
        }
        return initialClassNames;
    }

    constructor(IrrigationDevice, renderImmediately = true) {
        super(IrrigationDevice, GenerateUID("DevE"), false);
        this.lat = this.model.lat;
        this.long = this.model.long;
        allDeviceEntities.add(this);
        this.pickIcon();
    }

    // TODO: Required because this sets the icon used by the device card. Should decouple from the card and remove this
    pickIcon() {
        const deviceType = this.type;
        this.icon = DeviceMarkerIcon;

        const { iconGraphicConfig } = makeIconGraphicConfig({
            deviceType,
            manualMode: this.manualModeWarning,
        });
        this.icon = iconGraphicConfig.icon;

        // TODO keep logic for now, until we have settled on the new icons ref: https://linear.app/verdiag/issue/SWD-1017/implement-new-icon-symbols
        // if (DeviceConfigurationsByType[deviceType]?.valveType === "block") {
        //     this.icon = BlockValveMarkerIcon;
        // } else if (deviceType === "msense") {
        //     this.icon = MoistureSensorMarkerIcon;
        // } else if (deviceType === "moisture2v") {
        //     this.icon = DeviceMarkerIcon;
        // }
        // if (DeviceConfigurationsByType[deviceType]?.valveCount === 0) {
        //     if (this.model.connectedSensors && this.model.connectedSensors.some((s) => s.sensorType === "irrometer")) {
        //         this.icon = TensiometerMarkerIcon;
        //     } else if (
        //         this.model.connectedSensors &&
        //         this.model.connectedSensors.some((s) => s.sensorType === "watermark")
        //     ) {
        //         this.icon = WatermarkMarkerIcon;
        //     }
        // }
    }

    updateFocusState() {
        deviceIconUpdateEvent.trigger({ mapEntityId: this.id });
        super.updateFocusState();
    }

    setStatePreRender() {
        this.renderState.beingRendered = true;
    }

    setStatePostRender() {
        this.renderState.onMap = true;
        this.renderState.beingRendered = false;
        this.updateFocusState();
    }

    remove() {
        super.remove();
        allDeviceEntities.delete(this);
    }
}
