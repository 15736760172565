import { Button, Stack, SxProps, Theme } from "@mui/material";

const Buttons = [
    {
        label: "Last Year",
        getDates: () => {
            const now = new Date();
            return {
                startDate: new Date(now.getFullYear() - 1, 0, 1, 0, 0, 0, 0),
                endDate: new Date(now.getFullYear() - 1, 11, 31, 23, 59, 59, 999),
            };
        },
    },
    {
        label: "This Year",
        getDates: () => {
            const now = new Date();
            return {
                startDate: new Date(now.getFullYear(), 0, 1, 0, 0, 0, 0),
                endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999),
            };
        },
    },
    {
        label: "Last Month",
        getDates: () => {
            const now = new Date();
            const lastMonth = now.getMonth() === 0 ? 11 : now.getMonth() - 1;
            const yearOfLastMonth = now.getMonth() === 0 ? now.getFullYear() - 1 : now.getFullYear();
            return {
                startDate: new Date(yearOfLastMonth, lastMonth, 1, 0, 0, 0, 0),
                endDate: new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59, 999),
            };
        },
    },
    {
        label: "This Month",
        getDates: () => {
            const now = new Date();
            return {
                startDate: new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0),
                endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999),
            };
        },
    },
    {
        label: "Last Week",
        getDates: () => {
            const now = new Date();
            const startOfWeek = now.getDate() - now.getDay();
            const startDate = new Date(now);
            startDate.setDate(startOfWeek - 7);
            const endDate = new Date(now);
            endDate.setDate(startOfWeek - 1);
            return {
                startDate: new Date(startDate.setHours(0, 0, 0, 0)),
                endDate: new Date(endDate.setHours(23, 59, 59, 999)),
            };
        },
    },
    {
        label: "This Week",
        getDates: () => {
            const now = new Date();
            const startOfWeek = now.getDate() - now.getDay();
            const startDate = new Date(now);
            startDate.setDate(startOfWeek);
            return {
                startDate: new Date(startDate.setHours(0, 0, 0, 0)),
                endDate: new Date(now.setHours(23, 59, 59, 999)),
            };
        },
    },
];

interface DateRangeButtonsProps {
    onClick: (startDate: Date, endDate: Date) => void;
    sx?: SxProps<Theme>;
    startDate: Date;
    endDate: Date;
    presetDateButtonOptions: {label:string,getDates():{startDate:Date, endDate:Date}}[] | undefined;
}
export function DateRangeButtons({ onClick, sx, startDate, endDate, presetDateButtonOptions=Buttons }: DateRangeButtonsProps) {
    return (
        <Stack direction={"row"} spacing={2} sx={{ ...sx }}>
            {presetDateButtonOptions.map((dateRange) => {
                const bufferMs = 120000; // 2 minutes buffer
                const isActive =
                Math.abs(startDate.getTime() - dateRange.getDates().startDate.getTime()) <= bufferMs &&
                Math.abs(endDate.getTime() - dateRange.getDates().endDate.getTime()) <= bufferMs;
                return (
                    <Button
                        size={"small"}
                        sx={{
                            borderRadius: 10,
                            borderColor: "#b8b8b8",
                            color: "#b8b8b8",
                            ...(isActive
                                ? {
                                      "&:hover": {
                                          backgroundColor: "#6a6a6a",
                                      },
                                      "backgroundColor": "#9f9f9f",
                                      "color": "#ffffff",
                                  }
                                : {}),
                        }}
                        color={"inherit"}
                        variant={"outlined"}
                        onClick={() => {
                            const dates = dateRange.getDates();
                            onClick(dates.startDate, dates.endDate);
                        }}
                        key={dateRange.label}
                    >
                        {dateRange.label}
                    </Button>
                );
            })}
        </Stack>
    );
}
