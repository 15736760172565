import { Box, Stack, Tooltip as MUITooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ReactElement } from "react";

import { getDisplayName } from "../../../../../../services/deviceDataAccessors/deviceAttributes";
import { composeDeviceState } from "../../../../../../services/deviceDataAccessors/deviceDataAccessors";
import MapEntityBase from "../../../../../../services/mapManagement/mapEntities/MapEntityBase";
import { disabledByNotes, makeWarnings } from "../../../../../../services/mapManagement/mapEntities/shared";
import SaplingVerification from "../../../../../specialized/SaplingVerification/SaplingVerification";
import BatteryStatusIcon from "../../../../StatusIcons/BatteryStatusIcon";
import SignalStrengthStatusIcon from "../../../../StatusIcons/SignalStrengthStatusIcon";
import { badgeColorMapping } from "../../constants";
import { BADGE_VARIANT, WARNING_STATUS } from "../../types";
import { IconType, IrrigationProgressIcon, type TransitionDirection } from "./IrrigationProgressIcon";

// Constants for tooltip content
const STATUS_TEXT_COLOR = "#fdfdfd";
const STATUS_TEXT_FONT_WEIGHT = 500;
const STATUS_TEXT_FONT_SIZE = 12;
const STATUS_TEXT_PADDING_X = 8;
const STATUS_TEXT_PADDING_Y = 6;

// Map warning status to badge variant, to resuse the colors from badgeColorMapping
const warningStatusToBadgeVariant: Record<WARNING_STATUS, BADGE_VARIANT> = {
    [WARNING_STATUS.ERROR]: BADGE_VARIANT.ERROR,
    [WARNING_STATUS.WARNING]: BADGE_VARIANT.WARNING,
};

const makeNotesDisabledText = (model: MapEntityBase["model"]) => {
    if (model?.notes) {
        return disabledByNotes(model.notes) ? "Disabled" : undefined;
    }
    return undefined;
};

function TooltipContent({ model }: { model: MapEntityBase["model"] }) {
    const title = getDisplayName({ model });

    const { warningText, status } = makeWarnings({ model, type: model.type });
    const capitalizedWarningText = String(warningText).charAt(0).toUpperCase() + String(warningText).slice(1);
    const statusText = warningText ? capitalizedWarningText : makeNotesDisabledText(model) || undefined;
    const statusBgColor = status ? badgeColorMapping[warningStatusToBadgeVariant[status]].bg : grey[500];

    const { irrigationEventState, isIrrigating, manualModeState } = composeDeviceState({ model });

    // Compose state for the irrigation progress icon
    let irrigationProgressIconType: IconType = IconType.Static;
    const percentage = isIrrigating
        ? Number(Math.round(irrigationEventState.percentageOfIrrigationCompleted ?? 0))
        : undefined;
    const irrigationEndTime = irrigationEventState.closestEvent ? irrigationEventState.closestEvent.endTime : undefined;
    if (manualModeState.isTransitioning) {
        irrigationProgressIconType = IconType.Transitioning;
    } else if (isIrrigating && percentage) {
        irrigationProgressIconType = IconType.Irrigating;
    }

    const showValveStatus = manualModeState.isTransitioning || isIrrigating;
    return (
        <Stack
            direction={"column"}
            spacing={1}
            sx={{
                width: "max-content",
                maxWidth: "200px",
            }}
        >
            {Boolean(statusText) && (
                <Typography
                    component={"div"}
                    style={{
                        borderTopLeftRadius: 3,
                        borderTopRightRadius: 3,
                    }}
                    sx={{
                        backgroundColor: statusBgColor,
                        px: `${STATUS_TEXT_PADDING_X}px`,
                        py: `${STATUS_TEXT_PADDING_Y}px`,
                        color: STATUS_TEXT_COLOR,
                        fontWeight: STATUS_TEXT_FONT_WEIGHT,
                        fontSize: `${STATUS_TEXT_FONT_SIZE}px`,
                    }}
                >
                    {statusText}
                </Typography>
            )}
            <Stack
                direction={"row"}
                spacing={6}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{
                    "px": `${STATUS_TEXT_PADDING_X}px`,
                    "py": `${STATUS_TEXT_PADDING_Y}px`,
                    // the status buttons
                    "& div button.MuiButtonBase-root": {
                        height: "10px",
                        width: "10px",
                    },
                }}
            >
                <Typography
                    sx={{
                        color: "#222222",
                        fontSize: `${STATUS_TEXT_FONT_SIZE}px`,
                        fontWeight: STATUS_TEXT_FONT_WEIGHT,
                        overflowWrap: "break-word",
                        overflow: "hidden",
                    }}
                    component={"div"}
                >
                    {title}
                </Typography>
                <Stack
                    direction={"row"}
                    spacing={3}
                    alignItems={"center"}
                    sx={{
                        paddingRight: 1, // icons overflow their size slightly, need to add slight additional
                    }}
                >
                    <BatteryStatusIcon size={"small"} Target={model} />
                    <SignalStrengthStatusIcon size={"small"} Target={model} />
                    <SaplingVerification size={"small"} device={model} />
                </Stack>
            </Stack>
            {showValveStatus && (
                <Box
                    sx={{
                        borderTop: "1px solid #F1EFEF",
                        px: `${STATUS_TEXT_PADDING_X}px`,
                        py: `${STATUS_TEXT_PADDING_Y}px`,
                    }}
                >
                    <IrrigationProgressIcon
                        type={irrigationProgressIconType}
                        isOpen={isIrrigating ?? false}
                        direction={manualModeState.targetMode as TransitionDirection}
                        percentage={percentage}
                        irrigationEndTime={irrigationEndTime}
                    />
                </Box>
            )}
        </Stack>
    );
}

interface TooltipProps {
    placement?: "top" | "bottom" | "left" | "right";
    model?: MapEntityBase["model"];
    children: ReactElement;
    enabled?: boolean;
}

export function Tooltip({ placement = "right", model, children, enabled = true }: TooltipProps) {
    return (
        <MUITooltip
            disableHoverListener={!enabled}
            disableFocusListener={true}
            disableTouchListener={true}
            title={<TooltipContent model={model} />}
            placement={placement}
            enterDelay={0}
            leaveDelay={0}
            disableInteractive
            slotProps={{
                popper: {
                    disablePortal: true,
                    sx: {
                        "& .MuiTooltip-tooltip": {
                            bgcolor: "background.paper",
                            color: "text.primary",
                            boxShadow: 1,
                            p: 0,
                        },
                        "& .MuiTooltip-arrow": {
                            color: "background.paper",
                        },
                    },
                },
            }}
        >
            {children}
        </MUITooltip>
    );
}
