import { Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Accordion } from "src/components/generic/Accordion";
import { ComboBoxOption } from "src/components/generic/ComboBox/ComboBox";
import FocusContext from "src/services/mapManagement/FocusContext";
import * as VerdiAPI from "verdiapi";
import { dayMs } from "verdiapi/dist/HelperFunctions";
import { DataTypeSpecifier } from "verditypes";

import { Tag } from "../../components/generic/Tag/Tag";
import ColumnGroupSelector from "./components/ColumnGroupSelector";
import { DataTypeSelector } from "./components/DataTypeSelector";
import { DateRangeSelector } from "./components/DateRangeSelector/DateRangeSelector";
import { PositionDepthToggle } from "./components/PositionDepthToggle";
import { SourceSelector } from "./components/SourceSelector";
import { SOURCE_TYPE } from "./constants";
import { createCSVData } from "./helpers/csv";
import { exportToCsv, generateFileName } from "./helpers/file";
import { ColumnGrouping } from "./types";

const columnGroupingKeyOptions: { value: ColumnGrouping; label: string }[] = [
    { value: ColumnGrouping.SOURCE_TYPE, label: "Data source type (default)" },
    { value: ColumnGrouping.DATA_TYPE, label: "Data type" },
    { value: ColumnGrouping.ZONE_NAME, label: "Zone" },
];

export function DataExport() {
    const now = new Date(Date.now());
    const eodToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
    const [startDate, setStartDate] = useState<Date>(new Date(eodToday.valueOf() - dayMs * 7 + 1));
    const [endDate, setEndDate] = useState<Date>(eodToday);

    const [columnGroupingKey, setColumnGroupingKey] = useState<ColumnGrouping>(columnGroupingKeyOptions[0].value);

    const [dataTypes, setDataTypes] = useState<ComboBoxOption[]>([]);
    const [sources, setSources] = useState<ComboBoxOption[]>([]);
    const [splitByPositionDepth, setSplitByPositionDepth] = useState<boolean>(false);

    // Resolve the session load handler on page mount so the loading spinner goes away as there's no map to load
    useEffect(() => {
        VerdiAPI.SessionHandler.onSessionLoad.addListener(async () => {
            FocusContext.resolveLoadHandler("sessionLoad");
        });
    }, []);

    const onClickExport = async () => {
        // Update the loading overlay
        FocusContext.updateLoadHandler("Exporting data...", "DataExport");
        console.info("DataExport: Exporting data...");

        // Convert the sources to the format required by the database
        const sourcesToExport = sources.map((source) => ({ id: source.value, type: source.group as SOURCE_TYPE }));

        // Convert the data types to the format required by the database
        const dataTypesToExport = dataTypes.map((option) => option.value) as DataTypeSpecifier[];

        // Create the CSV data
        const { rows, columns } = await createCSVData({
            sources: sourcesToExport,
            dataTypes: dataTypesToExport,
            startDate,
            endDate,
            columnGroupKey: columnGroupingKey,
            splitByPositionDepth,
        });

        // Update the loading overlay
        FocusContext.updateLoadHandler("Downloading data...", "DataExport");
        console.info("DataExport: Downloading data...");

        // Convert the data types to the format required for the filename
        const dataTypeLabelsToExport = dataTypes.map((option) => option.label) as string[];

        // Download & export the data
        const filename = generateFileName(sourcesToExport, dataTypeLabelsToExport, startDate, endDate);
        exportToCsv(filename, rows, columns);

        // End the loading overlay
        FocusContext.resolveLoadHandler("DataExport");
    };

    const onChangeColumnGroupingKey = (value: string) => {
        setColumnGroupingKey(value as ColumnGrouping);
    };

    const onChangeSplitByPositionDepth = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSplitByPositionDepth(event.target.checked);
    };

    return (
        <Stack
            direction={"column"}
            alignItems={"center"}
            sx={{ p: 12, mx: "auto", overflow: "auto", maxHeight: "calc(100% - var(--nav-bar-height))" }}
        >
            <Stack direction={"column"} spacing={12} sx={{ maxWidth: 800 }}>
                <Stack direction={"column"} sx={{ mb: 8 }}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                        <Typography variant={"h2"} sx={{ fontWeight: 600 }}>
                            Data Export
                        </Typography>
                        <Tag />
                    </Stack>
                </Stack>
                <DateRangeSelector
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    presetDateButtonOptions={undefined} // use the preset
                    showDescriptionText={true}
                />
                <SourceSelector sources={sources} setSources={setSources} />
                <DataTypeSelector dataTypes={dataTypes} setDataTypes={setDataTypes} />
                <PositionDepthToggle checked={splitByPositionDepth} onChange={onChangeSplitByPositionDepth} />
                <Accordion
                    title={"Advanced Options"}
                    accordionSummarySx={{
                        borderBottom: "1px solid #E0E0E0",
                    }}
                >
                    <Stack direction={"column"} spacing={12}>
                        <ColumnGroupSelector options={columnGroupingKeyOptions} onChange={onChangeColumnGroupingKey} />
                    </Stack>
                </Accordion>

                <Button sx={{ width: 150, mt: 6 }} variant={"contained"} onClick={onClickExport}>
                    Export to CSV
                </Button>
            </Stack>
        </Stack>
    );
}
