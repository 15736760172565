import "./Navigation.css";

import CachedIcon from "@mui/icons-material/Cached";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MasterIndex, SessionHandler } from "verdiapi";

import { IconButtonWithTooltip } from "../../components/generic/IconButtonWithTooltip";
import { LanguageSelector } from "../../components/specialized/LanguageSelector/LanguageSelector";
import FocusContext from "../../services/mapManagement/FocusContext";
import GlobalOptions from "../../utils/GlobalOptions";
import ReactComponentBase from "../../utils/ReactComponentBase";
import { Document360ChatbotButton } from "../document360Chatbot/document360ChatbotButton";
import { ProductUpdateMenu } from "../productUpdate/ProductUpdateMenu";
import { AccountSelect } from "./AccountSelect";
import { Drawer } from "./Drawer/Drawer";
import { SettingsMenu } from "./SettingsMenu";

function HomeButton() {
    const { t } = useTranslation("modules");

    return (
        <IconButtonWithTooltip
            component={Link}
            to={`/`}
            title={t("navigation.navBar.home")}
            icon={<HomeIcon color={"gray"} />}
        />
    );
}

function RefreshButton() {
    const { t } = useTranslation("modules");
    return (
        <IconButtonWithTooltip
            onClick={async () => {
                window.location.reload();
            }}
            title={t("navigation.navBar.refresh")}
            icon={<CachedIcon />}
        />
    );
}

class NavBar extends ReactComponentBase {
    constructor(props) {
        super(props);

        this.state = {
            menuAnchor: null,
            mobileMenuAnchor: null,
            admin: SessionHandler.admin,
            loggedIn: SessionHandler.loggedIn,
            selectedAccount: "",
            selectedAOI: "",
        };

        SessionHandler.onLogin.addListener(() => {
            this.setState({
                accounts: SessionHandler.accounts,
                admin: SessionHandler.admin,
                loggedIn: SessionHandler.loggedIn,
            });
        });

        SessionHandler.onLogout.addListener(() => {
            this.setState({
                accounts: SessionHandler.accounts,
                admin: SessionHandler.admin,
                loggedIn: SessionHandler.loggedIn,
            });
        });

        sortAccounts();

        SessionHandler.onSessionLoad.addListener(() => {
            sortAccounts();
            setTimeout(() => {
                if (
                    SessionHandler.currentUserObject &&
                    SessionHandler.currentUserObject.id !== this.state.selectedAccount
                ) {
                    this.setState({
                        selectedAccount: SessionHandler.currentUserObject.id,
                    });
                } else {
                    this.forceUpdate();
                }
            }, 100);
        });

        FocusContext.onAOIChange.addListener(() => {
            if (FocusContext.selectedAOI !== this.state.selectedAOI) {
                this.setState({
                    selectedAOI: FocusContext.selectedAOI,
                });
            } else {
                this.forceUpdate();
            }
        });
    }

    componentDidMount() {
        // pass
    }

    render() {
        sortAccounts();
        const multipleAreasOfInterest = MasterIndex.aoi.all.length > 1;

        // Get navbar height from css variable
        const root = document.querySelector("#root");
        const styles = getComputedStyle(root);
        const navbarHeight = parseInt(styles.getPropertyValue("--nav-bar-height"), 10);

        return (
            <>
                <AppBar position={"static"} style={{ zIndex: 10 }}>
                    <Toolbar style={{ minHeight: "var(--nav-bar-height, 55px)" }} />
                </AppBar>
                <AppBar
                    position={"fixed"}
                    style={{ height: "var(--nav-bar-height, 55px)", zIndex: 1300 }}
                    id={this.uid}
                    elevation={0}
                >
                    <Toolbar style={{ minHeight: "var(--nav-bar-height, 55px)" }}>
                        {this.state.loggedIn && (
                            <MobileView style={{ display: "flex" }}>
                                <IconButton
                                    edge={"start"}
                                    color={"inherit"}
                                    aria-label={"menu"}
                                    onClick={(e) => {
                                        this.setState({ mobileMenuAnchor: e.currentTarget });
                                    }}
                                    size={"large"}
                                >
                                    <MenuIcon />
                                </IconButton>

                                <Drawer
                                    multipleAOI={multipleAreasOfInterest}
                                    open={Boolean(this.state.mobileMenuAnchor)}
                                    onClose={() => {
                                        this.setState({ mobileMenuAnchor: null });
                                    }}
                                    admin={this.state.admin}
                                    loggedIn={this.state.loggedIn}
                                    mobileMenuAnchorSetter={(anchor) => {
                                        this.setState({ mobileMenuAnchor: anchor });
                                    }}
                                />
                            </MobileView>
                        )}
                        <Link to={"/"}>
                            <img
                                style={{
                                    minHeight: isMobile ? "30px" : "25px",
                                }}
                                src={
                                    "https://verdi-web-assets.s3.us-east-2.amazonaws.com/logo/PNG/white_wordmark@0.5x.png"
                                }
                                id={"main-logo"}
                            />
                        </Link>
                        <div style={{ flexGrow: "10" }} />
                        {this.state.loggedIn && (
                            <MobileView>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <RefreshButton />
                                    <Document360ChatbotButton />
                                    <HomeButton />
                                </div>
                            </MobileView>
                        )}

                        <BrowserView className={"MainBrowserToolbar"} style={{ display: "flex" }}>
                            {this.state.loggedIn ? (
                                <>
                                    {this.state.admin && <AccountSelect />}
                                    <RefreshButton />
                                    <HomeButton />
                                    <Document360ChatbotButton />
                                    <ProductUpdateMenu navBarHeight={navbarHeight} />
                                    <SettingsMenu navBarHeight={navbarHeight} />
                                    {GlobalOptions.enableLanguageSelector && <LanguageSelector />}
                                </>
                            ) : (
                                <Button
                                    color={"inherit"}
                                    onClick={() => {
                                        window.open("https://www.verdi.ag/about", "_blank");
                                    }}
                                >
                                    About
                                </Button>
                            )}
                        </BrowserView>
                    </Toolbar>
                </AppBar>
            </>
        );
    }
}

export default withTranslation(["modules"])(NavBar);

function sortAccounts() {
    if (!SessionHandler.accounts) {
        return;
    }

    SessionHandler.accounts.sort((a, b) => {
        if (a.name && b.name) {
            return a.name.localeCompare(b.name);
        }
        if (a.name) {
            return 1;
        }
        return -1;
    });
}
