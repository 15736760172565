import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { secondMs } from "verditypes/dist/timeConstants";

import MapEntityBase from "../../../../services/mapManagement/mapEntities/MapEntityBase";
import { DotIcon, DotIconProps } from "./components/DotIcon";
import { FullIcon, FullIconProps } from "./components/FullIcon";
import { GroupMenu } from "./components/GroupMenu/GroupMenu";
import { GroupItem, type GroupMenuOptions } from "./components/GroupMenu/types";
import { Tooltip } from "./components/Tooltip/Tooltip";

const ICON_CONTRACT_DELAY = 0.45 * secondMs;
const TRANSITION = {
    duration: 0.25,
};

const animationStates = {
    visible: { opacity: 1, transform: "scale(1)" },
    fullInitialMount: { opacity: 1, transform: "scale(0.5)" },
    dotInitialMount: {
        opacity: 0.3,
        transform: "scale(0.5)",
    },
    dotInitial: { opacity: 1, transform: "scale(1.5)" },
    dotExit: { opacity: 0.3, transform: "scale(0.5)" },
};

export type DeviceIconProps = FullIconProps &
    DotIconProps & {
        forceExpand?: boolean;
        dotSize?: number;
        groupMenu?: {
            menuItems: GroupItem[];
            groupMenuOptions: GroupMenuOptions;
        };
        onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void;
        onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void;
        tooltip?:
            | {
                  deviceModel: MapEntityBase["model"];
                  enabled: true;
              }
            | {
                  enabled: false;
              };
    };

/**
 * Device Icon component that composes the FullIcon, DotIcon, and GroupMenu together.
 * Manages expanding/contracting between a FullIcon and a DotIcon, as well as rendering the group icon menu if needed
 */
export function DeviceIcon({
    forceExpand = false,
    size = 24,
    dotSize,
    dimmed,
    onClick,
    groupMenu,
    children,
    onMouseEnter,
    onMouseLeave,
    tooltip = {
        enabled: false,
    },
    ...sharedIconProps
}: DeviceIconProps) {
    const [shouldExpand, setShouldExpand] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const menuAnchor = useRef<HTMLElement | null>(null);

    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, []);

    // Contract to "dot" after delay
    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (!shouldExpand) {
            timer = setTimeout(() => {
                setExpanded(false);
            }, ICON_CONTRACT_DELAY);
        }
        return () => clearTimeout(timer);
    }, [shouldExpand]);

    // Handle contraction after forceExpand is removed
    useEffect(() => {
        if (!forceExpand) {
            setExpanded(false);
        }
    }, [forceExpand]);

    const handleClickIcon = (e: React.MouseEvent<HTMLDivElement>) => {
        if (groupMenu) {
            setMenuOpen(true);
        }
        onClick?.(e);
    };

    const onCloseMenu = () => {
        setMenuOpen(false);
        setExpanded(false);
    };

    const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
        setExpanded(true);
        setShouldExpand(true);
        onMouseEnter?.(e);
    };

    const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!menuOpen) {
            setShouldExpand(false);
        }
        onMouseLeave?.(e);
    };

    const dotIconInitialAnimation = () => (hasMounted ? animationStates.dotInitial : animationStates.dotInitialMount);

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <AnimatePresence mode={"wait"}>
                {forceExpand || expanded ? (
                    <motion.div
                        key={"full-icon"}
                        initial={animationStates.fullInitialMount}
                        animate={animationStates.visible}
                        transition={TRANSITION}
                        style={{
                            willChange: "transform, opacity",
                            backfaceVisibility: "hidden",
                        }}
                    >
                        <Tooltip model={tooltip?.enabled ? tooltip.deviceModel : undefined} enabled={tooltip?.enabled}>
                            <span>
                                <FullIcon
                                    {...sharedIconProps}
                                    ref={menuAnchor}
                                    onClick={handleClickIcon}
                                    size={size}
                                    dimmed={dimmed}
                                >
                                    {children}
                                </FullIcon>
                            </span>
                        </Tooltip>
                        {groupMenu && (
                            <GroupMenu
                                items={groupMenu.menuItems}
                                open={menuOpen}
                                onClose={onCloseMenu}
                                anchorEl={menuAnchor.current}
                                groupMenuOptions={groupMenu.groupMenuOptions}
                            />
                        )}
                    </motion.div>
                ) : (
                    <motion.div
                        key={"dot-icon"}
                        initial={dotIconInitialAnimation()}
                        animate={animationStates.visible}
                        transition={TRANSITION}
                        style={{
                            willChange: "transform, opacity",
                            backfaceVisibility: "hidden",
                        }}
                    >
                        <DotIcon
                            {...sharedIconProps}
                            onClick={handleClickIcon}
                            size={dotSize ?? 12}
                            dimmed={dimmed}
                            expandedClickZone={isMobile}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}
