import { ArrowOutward, Timeline } from "@mui/icons-material";
import { Icon, Link, MenuItem } from "@mui/material";

interface ProductUpdateMenuLinkProps {
    title: string;
    url: string;
    onClick?: () => void;
}
/**
 * Menu item for product update, shows icon, title, and tag
 */
export function ProductUpdateMenuLink({ title, url, onClick }: ProductUpdateMenuLinkProps) {
    return (
        <MenuItem
            key={`ProductUpdateMenu_${url}`}
            component={Link}
            href={url}
            onClick={onClick}
            target={"_blank"}
            rel={"noopener noreferrer"}
            sx={{ gap: 2 }}
        >
            <Icon component={Timeline} sx={{ color: "grey", fontSize: 19 }} />
            {title}
            <Icon component={ArrowOutward} sx={{ color: "grey", fontSize: 14 }} />
        </MenuItem>
    );
}
