import { Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";

/**
 * Measure the width of text as it would be rendered
 */
function measureText(text: string, font: string): number {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (!context) return 0;
    context.font = font;
    return context.measureText(text).width;
}

interface TruncationResult {
    displayText: string;
    wasTruncated: boolean;
}
/**
 * Determine the text + truncation text that fits within maxWidth
 */
function getTruncatedText(zoneNames: string[], maxWidth: number, font: string): TruncationResult {
    if (!zoneNames.length) {
        return { displayText: "", wasTruncated: false };
    }

    const separator = ", ";
    const ellipsis = ", ...";
    const getMoreText = (count: number) => ` +${count} more`;

    // Pre-calculate common measurements
    const separatorWidth = measureText(separator, font);
    const ellipsisWidth = measureText(ellipsis, font);
    const getMoreTextWidth = (count: number) => measureText(getMoreText(count), font);

    const visibleZones: string[] = [];
    let currentWidth = 0;

    // Try adding zones one by one
    for (let i = 0; i < zoneNames.length; i++) {
        const zoneName = zoneNames[i];
        const zoneWidth = measureText(zoneName, font);
        const isLastZone = i === zoneNames.length - 1;

        const newWidth = currentWidth + (visibleZones.length > 0 ? separatorWidth : 0) + zoneWidth;

        const remainingZones = zoneNames.length - i - 1;
        const moreTextWidth = remainingZones > 0 ? getMoreTextWidth(remainingZones) : 0;
        const wouldOverflow = newWidth + ellipsisWidth + moreTextWidth > maxWidth;

        if (wouldOverflow && !isLastZone) {
            break;
        }

        visibleZones.push(zoneName);
        currentWidth = newWidth;
    }

    // If all zones fit, return them all
    if (visibleZones.length === zoneNames.length) {
        return {
            displayText: visibleZones.join(separator),
            wasTruncated: false,
        };
    }

    // Remove zones from the end until it fits with ellipsis and "+N more"
    while (visibleZones.length > 0) {
        const remainingCount = zoneNames.length - visibleZones.length;
        const text = visibleZones.join(separator);
        const moreText = getMoreText(remainingCount);

        if (measureText(text + ellipsis + moreText, font) <= maxWidth) {
            return {
                displayText: text + ellipsis + moreText,
                wasTruncated: true,
            };
        }

        visibleZones.pop();
    }

    // Fallback if nothing fits
    return {
        displayText: ellipsis + getMoreText(zoneNames.length),
        wasTruncated: true,
    };
}

interface GroupMenuCategoryTitleProps {
    zones: { name: string; id: string }[];
    className?: string;
}
/**
 * A component that displays a list of zones, truncating with "+N more" when the text would overflow its container
 */
export function GroupMenuCategoryTitle({ zones, className }: GroupMenuCategoryTitleProps) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [maxWidth, setMaxWidth] = useState<number>(450); // Default width for the title
    const [font, setFont] = useState<string>("14px system-ui");

    // Update maxWidth when size changes
    useEffect(() => {
        let observer: ResizeObserver | undefined;
        if (containerRef.current) {
            observer = new ResizeObserver((entries) => {
                entries.forEach((entry) => {
                    setMaxWidth(entry.contentRect.width);
                });
            });

            observer.observe(containerRef.current);

            // Get computed font style
            const computedStyle = window.getComputedStyle(containerRef.current);
            setFont(`${computedStyle.fontSize} ${computedStyle.fontFamily}`);
        }

        return () => {
            observer?.disconnect();
        };
    }, []);

    // Calculate truncated text
    const { displayText } = useMemo(() => {
        if (!maxWidth) return { displayText: "", wasTruncated: false };

        const zoneNames = zones.map((zone) => zone.name);
        return getTruncatedText(zoneNames, maxWidth, font);
    }, [zones, maxWidth, font]);

    return (
        <Typography
            sx={{ paddingLeft: 2, paddingTop: 2, cursor: "pointer" }}
            variant={"h6"}
            ref={containerRef}
            className={className}
            title={zones.map((zone) => zone.name).join(", ")}
        >
            {displayText}
        </Typography>
    );
}
