import { MenuItem, Stack, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";

import { menuIconSizeWithProgressRing } from "../../constants";

interface GroupMenuItemProps {
    icon: React.ReactNode;
    label: string;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    menuItemHeight: number;
}
/**
 * A single item in the group menu
 */
export function GroupMenuItem({
    icon,
    label,
    onClick,
    onMouseEnter,
    onMouseLeave,
    menuItemHeight,
}: GroupMenuItemProps) {
    return (
        <MenuItem
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            sx={{
                "borderRadius": "4px",
                "backgroundColor": "#fdfdfd",
                "display": "flex",
                "alignItems": "center",
                "gap": "8px",
                "height": `${menuItemHeight}px`,
                "py": "6px",
                "pl": "8px",
                "pr": "18px",
                // hover effect only on desktop`
                "&:hover": isMobile
                    ? {}
                    : {
                          backgroundColor: "#f1efef",
                      },
                // Override "clicked" ripple color
                ".MuiTouchRipple-child": {
                    backgroundColor: "#e4e4e4",
                },
            }}
            onClick={onClick}
        >
            <Stack alignItems={"center"} width={menuIconSizeWithProgressRing}>
                {icon}
            </Stack>
            <Typography fontSize={"14px"}>{label}</Typography>
        </MenuItem>
    );
}
