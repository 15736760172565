import { GroupItem } from "./types";

/**
 * Groups an array of objects by a given key
 * @returns a record where with values of the key are the keys, and the values is an array of objects
 *
 * eg: [{type: "a", value: 1}, {type: "a", value: 1}, {type: "b", value: 2}] grouped by "type"
 * => { a: [{type: "a", value: 1}, {type: "a", value: 1}], b: [{type: "b", value: 2}] }
 */
export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce(
        (previous, currentItem) => {
            const group = getKey(currentItem);
            if (!previous[group]) previous[group] = [];
            previous[group].push(currentItem);
            return previous;
        },
        {} as Record<K, T[]>,
    );

/**
 * Gets a string representation of the zones in a group item
 */
export const getCategoryTitle = (zones: { name: string }[]) => zones.map((zone) => zone.name).join(", ");

/**
 * Sorts an array of group items by their zone name, then by their label
 */
export const sortGroupItems = (groupItems: GroupItem[]) => {
    const sortedOptions = [...groupItems];
    sortedOptions.sort((a, b) => {
        if (a.zones && b.zones) {
            return getCategoryTitle(a.zones).localeCompare(getCategoryTitle(b.zones));
        }

        return a.label?.localeCompare(b.label ?? "") ?? 0;
    });

    return sortedOptions;
};
