import { ModelBase } from "verdiapi/dist/Models/ModelBase";

import type MapEntityBase from "../../../services/mapManagement/mapEntities/MapEntityBase";

export enum INFO_CARD_TYPE {
    ADD_DEVICE = "add-device",
    SCHEDULER = "scheduler",
    DEVICE = "device",
    SEARCH = "search",
    IRRIGATION_REPORT = "irrigation-report",
    ZONE = "zone",
    AOI = "aoi",
}

/**
 * Map of model category to the map entity and card type
 */
const modelCategoryToCardType: Record<ModelBase["category"], INFO_CARD_TYPE | undefined> = {
    irrigationDevice: INFO_CARD_TYPE.DEVICE,
    blockValve: INFO_CARD_TYPE.DEVICE,
    doubleMoistureDevice: INFO_CARD_TYPE.DEVICE,
    SensoterraMoisture: INFO_CARD_TYPE.DEVICE,
    zone: INFO_CARD_TYPE.ZONE,
    overlay: undefined,
    aoi: INFO_CARD_TYPE.AOI,
    irrigationSet: undefined,
    thirdPartyDevice: INFO_CARD_TYPE.DEVICE,
};
/**
 * Returns the card type from a map entity. Defaults to device card if unknown
 */
export const getCardTypeFromEntity = (mapEntity: MapEntityBase): INFO_CARD_TYPE => {
    // get from infocard if available
    if (mapEntity?.infoCard?.currentInfoCardType) {
        return mapEntity.infoCard.currentInfoCardType;
    }

    const type = modelCategoryToCardType[mapEntity.model.category];
    if (type) {
        return type;
    }
    return INFO_CARD_TYPE.DEVICE;
};
