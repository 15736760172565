import canvasSize from "canvas-size";
import { isMobile } from "react-device-detect";

import { SIZES } from "../components/icons/MapIcons/DeviceIcon/types";
import { URLParams } from "./URLParams";

const GlobalOptions = {
    showVerification: true,
    showIrrigationReport: !isMobile,
    useSimpleBar: true,
    iconSize: 'normal', // TODO remove this in favor of userIconSizePreference once we migrate to the new icons
    userIconSizePreference: SIZES.MD,
    useFakedSchedulerScrolling: true,
    useSchedulerPhoneVersion: false,
    paddingOnSchedulerViewport: 400,
    heightOfSchedulerViewport: 1500,
    antialiasScheduler: true,
    ScheduleBackgroundLineMultiplier: 1,
    AppleDevice: false,
    fullScreenDialogs: false,
    schedulerCompatabilityMode: false,
    isBrokenShaderIOS: false,
    enableLanguageSelector: true,
    enableFullScreenGraph: true,
    showChipsAtSameTimeAsInfoCards: true,
    maxCachedGraphLines: 10,
    cachedInfoCardLimit: 5,
    defaultStartingField: false,
    enableIconClustering: true, // TODO option currently Admin-only
    featureFlags: {
        enableNewDeviceCard: false,
    },
};

const isIOS = ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
    navigator.platform,
);

if (isIOS) {
    const userAgent = window.navigator.userAgent;
    const iOSVersionRegexResults = userAgent.match(/iP(hone|od|ad) OS (\d+)_(\d+)_?(\d+)?/);
    const IOSVersion = iOSVersionRegexResults.slice(2, 5).map((v) => parseInt(v, 10));

    // let IOSVersion = [16, 4, 0];
    if (IOSVersion[0] === 16 && IOSVersion[1] >= 4 && IOSVersion[1] < 6) {
        GlobalOptions.schedulerCompatabilityMode = true;
        GlobalOptions.isBrokenShaderIOS = true;
    }
}

let maxPossibleCanvasHeight = 2000;

const makeCanvasPredictions = () => {
    if (isIOS || isMobile) {
        return;
    }
    canvasSize.maxArea({
        onError: function onCanvasSizeError(width, height, benchmark) {
            console.info("Error rendering canvas with dimensions:", width, height, benchmark);
        },
        onSuccess: function onCanvasSizeSuccess(width, height, benchmark) {
            console.info("Success rendering canvas with dimensions:", width, height, benchmark);
            maxPossibleCanvasHeight = Math.max(height, maxPossibleCanvasHeight);
        },
    });
    const padding = Math.min(100, maxPossibleCanvasHeight / 3);
    GlobalOptions.paddingOnSchedulerViewport = padding;
    GlobalOptions.heightOfSchedulerViewport = Math.min(
        1000 + padding * 3,
        maxPossibleCanvasHeight - GlobalOptions.paddingOnSchedulerViewport * 2,
    );
    let isValidCanvas = canvasSize.test({
        width: window.innerWidth,
        height: GlobalOptions.heightOfSchedulerViewport + GlobalOptions.paddingOnSchedulerViewport * 2,
    });
    let canvasAttempts = 0;

    // canvasAttempts makes sure that if canvases are just not supported or something,
    // we don't enter an infinite loop
    while (!isValidCanvas && canvasAttempts < 50) {
        canvasAttempts++;
        GlobalOptions.heightOfSchedulerViewport *= 0.8;
        GlobalOptions.paddingOnSchedulerViewport *= 0.8;
        isValidCanvas = canvasSize.test({
            width: window.innerWidth,
            height: GlobalOptions.heightOfSchedulerViewport + GlobalOptions.paddingOnSchedulerViewport * 2,
        });
    }
};

setTimeout(() => {
    makeCanvasPredictions();
}, 800);

if (isMobile) {
    GlobalOptions.ScheduleBackgroundLineMultiplier = 0.5;
    GlobalOptions.iconSize = "large";
    GlobalOptions.userIconSizePreference = SIZES.LG;
    GlobalOptions.maxCachedGraphLines = 5;
    GlobalOptions.cachedInfoCardLimit = 3;
}

if (isIOS) {
    GlobalOptions.AppleDevice = true;
    GlobalOptions.useSimpleBar = false;
    GlobalOptions.paddingOnSchedulerViewport = 200;
    GlobalOptions.heightOfSchedulerViewport = 1100;
}
if (navigator.platform.indexOf("Mac") === 0) {
    GlobalOptions.antialiasScheduler = false;
}

if (window.innerWidth < 500 && isMobile) {
    GlobalOptions.useSchedulerPhoneVersion = true;
    GlobalOptions.fullScreenDialogs = true;
    GlobalOptions.enableFullScreenGraph = false;
    GlobalOptions.showChipsAtSameTimeAsInfoCards = false;
}

const gl = document.createElement("canvas").getContext("webgl2");
const supportsWebGL2 = gl;

if (!supportsWebGL2) {
    GlobalOptions.schedulerCompatabilityMode = true;
}

if (URLParams.getParam("schedCompat") !== undefined) {
    GlobalOptions.schedulerCompatabilityMode = Boolean(URLParams.getParam("schedCompat"));
}
export const DefaultGlobalOptions = { ...GlobalOptions };

try {
    Object.assign(GlobalOptions, JSON.parse(localStorage.getItem("LocalSettings")) || {});
} catch (e) {
    localStorage.setItem("LocalSettings", "{}");
}
export default GlobalOptions;
