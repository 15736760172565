import { Menu, Popover } from "@mui/material";
import { isMobile } from "react-device-detect";

import { getCategoryTitle, groupBy, sortGroupItems } from "./constants";
import { GroupMenuContents } from "./GroupMenuContents";
import { type GroupItem, type SharedGroupMenuProps } from "./types";

interface GroupMenuProps extends SharedGroupMenuProps {
    items: GroupItem[];
    anchorEl: HTMLElement | null;
}
/**
 * Group Menu displays a list of the devices/icons present in a group
 */
export function GroupMenu({ items, ...props }: GroupMenuProps) {
    const sortedItems = sortGroupItems(items);
    const categorizedItems = groupBy(sortedItems, (item: GroupItem) => getCategoryTitle(item.zones ?? []));

    return isMobile ? (
        <MobileGroupMenu categorizedItems={categorizedItems} {...props} />
    ) : (
        <DesktopGroupMenu categorizedItems={categorizedItems} {...props} />
    );
}

interface DesktopGroupMenuProps extends Omit<GroupMenuProps, "items"> {
    categorizedItems: Record<string, GroupItem[]>;
}
/**
 * Appears as menu anchored to the side of an icon
 */
function DesktopGroupMenu({ categorizedItems, open, onClose, anchorEl, groupMenuOptions }: DesktopGroupMenuProps) {
    const { menuMaxHeight, menuPadding } = groupMenuOptions;

    return (
        <Menu
            sx={{
                "& .MuiMenu-paper": {
                    maxHeight: `${menuMaxHeight}px`,
                    backgroundColor: "white",
                    border: "1px solid #F2F0F1",
                    overflowY: "auto",
                    padding: `${menuPadding}px`,
                },
                "& .MuiMenu-list": {
                    py: 0,
                },
            }}
            elevation={0}
            open={open}
            onClose={onClose}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "center",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: 20,
                horizontal: -10,
            }}
        >
            <GroupMenuContents
                open={open}
                categorizedItems={categorizedItems}
                onClose={onClose}
                groupMenuOptions={groupMenuOptions}
            />
        </Menu>
    );
}

interface MobileGroupMenuProps extends SharedGroupMenuProps {
    categorizedItems: Record<string, GroupItem[]>;
}
/**
 * Appears as a card-like popup, appearing from the bottom of the screen
 */
function MobileGroupMenu({ categorizedItems, open, onClose, groupMenuOptions }: MobileGroupMenuProps) {
    const { menuMaxHeight, menuPadding } = groupMenuOptions;

    return (
        <Popover
            sx={{
                "& .MuiPaper-root": {
                    position: "absolute",
                    left: "15px",
                    right: "15px",
                    bottom: "15px",
                    margin: "auto",
                    maxHeight: `${menuMaxHeight}px`,
                    border: "1px solid #sF2F0F1",
                    overflowY: "auto",
                    padding: `${menuPadding}px`,
                },
            }}
            elevation={0}
            open={open}
            onClose={onClose}
            anchorReference={"none"}
        >
            <GroupMenuContents
                open={open}
                categorizedItems={categorizedItems}
                onClose={onClose}
                groupMenuOptions={groupMenuOptions}
            />
        </Popover>
    );
}
