import "./marker.css";

import { Marker } from "@adamscybot/react-leaflet-component-marker";
import { debounce } from "@mui/material";
import L from "leaflet";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isIOS, isMobile } from "react-device-detect";
import { EventHandler, GenerateUID } from "verdiapi/dist/HelperFunctions";

import InfoCard from "../../../components/specialized/infoCards/InfoCard";
import { getCardTypeFromEntity, INFO_CARD_TYPE } from "../../../components/specialized/infoCards/InfoCardTypes";
import { useStore } from "../../../store/store";
import GlobalOptions from "../../../utils/GlobalOptions";
import FocusContext from "../FocusContext";
import type MapEntityBase from "../mapEntities/MapEntityBase";
import { DeviceGroupMapEntityIcon } from "./components/DeviceGroupMapEntityIcon";
import { DeviceMapEntityIcon } from "./components/DeviceMapEntityIcon";
import { ZoneHighlightPolygon } from "./components/ZoneHighlightPolygon";
import { Z_INDEX_OFFSETS } from "./constants";
import { centerInViewableArea, mobilePanEnabled } from "./focus";
import { computeIconState, getItemsInCluster } from "./iconState";
import type { ClusterMapItem, DeviceMapItem, MapEntityFocusContextState } from "./types";

export const recomputeIconStateEvent = new EventHandler({}, 1000);
export const deviceIconUpdateEvent = new EventHandler({}, 1000);

/**
 * Determines how an icon should appear (what props to pass) based on the focus context state
 */
const makeIconPropsFromContextState = (state: MapEntityFocusContextState) => {
    switch (state) {
        case "hidden":
            return {
                hidden: true,
            };
        case "focused":
            return {
                highlighted: true,
            };
        case "inactive":
            return {
                dimmed: true,
            };
        case "selected":
        case "active":
            return {};
        default:
            return {};
    }
};

const makeClusterPropsFromContext = (clusterMapItem: ClusterMapItem, allDeviceItems: DeviceMapItem[]) => {
    const clusterItems = getItemsInCluster(clusterMapItem, allDeviceItems).map(
        (deviceMapItem) => deviceMapItem.mapEntity,
    );
    return {
        dimmed:
            clusterItems.some((mapEntity) => mapEntity.focusState === "inactive") &&
            clusterItems.every((mapEntity) => mapEntity.focusState === "inactive" || mapEntity.focusState === "hidden"),
        hidden: clusterItems.every((mapEntity) => mapEntity.focusState === "hidden"),
    };
};

const getExpandedBounds = (bounds: L.LatLngBounds) => {
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    const latPadding = (ne.lat - sw.lat) * 0.1; // 10% padding
    const lngPadding = (ne.lng - sw.lng) * 0.1;
    return L.latLngBounds(
        L.latLng(sw.lat - latPadding, sw.lng - lngPadding),
        L.latLng(ne.lat + latPadding, ne.lng + lngPadding),
    );
};

interface DeviceMarkerProps {
    deviceMapItem: DeviceMapItem;
    currentMapZoom: number;
    hoveredIconId: string | null;
    mapComponent: L.Map;
    onDeviceClick: (e: React.MouseEvent, mapEntity: MapEntityBase) => void;
    onIconHover: (id: string | null) => void;
    highlighted?: boolean;
    dimmed?: boolean;
    hidden?: boolean;
}

const DeviceMarkerMemo = memo(
    ({
        deviceMapItem,
        currentMapZoom,
        hoveredIconId,
        mapComponent,
        onDeviceClick,
        onIconHover,
        highlighted,
        dimmed,
        hidden,
    }: DeviceMarkerProps) => {
        const iconId = deviceMapItem.mapEntity.id ?? deviceMapItem.mapEntity.uid;
        return (
            <Marker
                key={iconId}
                position={[deviceMapItem.lat, deviceMapItem.long]}
                zIndexOffset={hoveredIconId === iconId || highlighted ? Z_INDEX_OFFSETS.ICON_HOVERED : 0}
                icon={
                    <DeviceMapEntityIcon
                        currentMapZoom={currentMapZoom}
                        key={iconId}
                        mapComponent={mapComponent}
                        deviceModel={deviceMapItem.mapEntity.model}
                        onClickIcon={(e) => onDeviceClick(e, deviceMapItem.mapEntity)}
                        onHover={(e, isHovered) => {
                            // iOS simulates the onMouseEnter event on the first tap (instead of the click event).
                            // On mobile, manually trigger the click event to override and fire our onClick anyways
                            if (isHovered && isMobile && isIOS) {
                                onDeviceClick(e, deviceMapItem.mapEntity);
                            }
                            onIconHover(isHovered ? iconId : null);
                        }}
                        highlighted={highlighted}
                        dimmed={dimmed}
                        hidden={hidden}
                    />
                }
            />
        );
    },
);

interface ClusterMarkerProps {
    clusterMapItem: ClusterMapItem;
    deviceItems: DeviceMapItem[];
    hoveredIconId: string | null;
    mapComponent: L.Map;
    onIconHover: (id: string | null) => void;
    hidden?: boolean;
    dimmed?: boolean;
}

const ClusterMarkerMemo = memo(
    ({ clusterMapItem, deviceItems, hoveredIconId, mapComponent, onIconHover, hidden, dimmed }: ClusterMarkerProps) => (
        <Marker
            key={clusterMapItem.id}
            position={[clusterMapItem.lat, clusterMapItem.long]}
            zIndexOffset={hoveredIconId === clusterMapItem.id ? Z_INDEX_OFFSETS.ICON_HOVERED : 0}
            icon={
                <DeviceGroupMapEntityIcon
                    key={clusterMapItem.id}
                    clusterMapItem={clusterMapItem}
                    deviceMapItems={deviceItems}
                    mapComponent={mapComponent}
                    onHover={(isHovered) => onIconHover(isHovered ? clusterMapItem.id : null)}
                    hidden={hidden}
                    dimmed={dimmed}
                />
            }
        />
    ),
);

interface IconLayerProps {
    iconMapEntities: MapEntityBase[];
    mapComponent: L.Map;
}
/**
 * Responsible for rendering the icons at the proper location on the map, manages icon state
 * that is shared across all icons such as zoom, hover, focus, etc.
 */
export const IconLayer = memo(({ iconMapEntities, mapComponent }: IconLayerProps) => {
    const openCard = useStore((state) => state.openCard);
    const isCardOpen = useStore((state) => state.isCardOpen);
    const selectedMapEntity = useStore((state) => state.selectedMapEntity);
    const cardHeight = useStore((state) => state.cardHeight);

    const [currentMapZoom, setCurrentMapZoom] = useState(mapComponent ? mapComponent.getZoom() : 16);
    const [clusterItems, setClusterItems] = useState<ClusterMapItem[]>([]);
    const [deviceItems, setDeviceItems] = useState<DeviceMapItem[]>([]);
    const [visibleMapBounds, setVisibleMapBounds] = useState<L.LatLngBounds | undefined>(undefined);
    const [hoveredIconId, setHoveredIconId] = useState<string | null>(null);
    const [focusedContext, setFocusedContext] = useState<{
        infoCard: InfoCard | null;
        focusedMapEntity: MapEntityBase | null;
    }>({
        infoCard: null,
        focusedMapEntity: null,
    });
    // Cache for icon states
    const iconStatePropsCache = useRef(new Map<string, ReturnType<typeof makeIconPropsFromContextState>>());

    // Hack to force re-render of icons when the focus context changes
    const [, setTriggeredIconUpdates] = useState<number>(0);
    useEffect(() => {
        const debouncedSetTriggeredIconUpdates = debounce(() => {
            setTriggeredIconUpdates((prev) => prev + 1);
        }, 150);

        deviceIconUpdateEvent.addListener(() => {
            debouncedSetTriggeredIconUpdates();
        });
        return () => {
            deviceIconUpdateEvent.removeListener(debouncedSetTriggeredIconUpdates);
        };
    }, []);

    // Event listener for when the icon state needs to be recomputed (eg. Device is repositioned)
    useEffect(() => {
        const forceMapStateComputation = async () => {
            if (!mapComponent) {
                return;
            }
            const newMapState = await computeIconState({
                iconMapEntities,
                mapComponent,
            });

            if (newMapState && newMapState[currentMapZoom]) {
                const stateForCurrentZoomLevel = newMapState[currentMapZoom];
                setClusterItems(stateForCurrentZoomLevel.clusterMapItems);
                setDeviceItems(stateForCurrentZoomLevel.deviceMapItems);
            }
        };

        recomputeIconStateEvent.addListener(forceMapStateComputation);
        return () => {
            recomputeIconStateEvent.removeListener(forceMapStateComputation);
        };
    }, [currentMapZoom, iconMapEntities, mapComponent]);

    // Handle info card changes
    useEffect(() => {
        const handleInfoCardChange = ({ target }: { target: InfoCard }) => {
            setFocusedContext({
                infoCard: target,
                focusedMapEntity: FocusContext.activeContext.focused,
            });
        };

        FocusContext.onInfoCardChanged.addListener(handleInfoCardChange);
        return () => {
            FocusContext.onInfoCardChanged.removeListener(handleInfoCardChange);
        };
    }, [mapComponent]);

    // [FeatureFlag - New Device Card] Measure info card height and pan to avoid cards on mobile
    useEffect(() => {
        const panToAvoidCard = (height: number, focusedMapEntity: MapEntityBase) => {
            centerInViewableArea({
                mapComponent,
                cardHeight: height,
                iconLatLng: new L.LatLng(focusedMapEntity?.lat ?? 0, focusedMapEntity?.long ?? 0),
                // buffer: 10, // DeviceInfoCard takes up a lot of space, so override to offset less
                padding: 0,
            });
        };

        if (
            GlobalOptions.featureFlags.enableNewDeviceCard &&
            mobilePanEnabled() &&
            isCardOpen &&
            selectedMapEntity &&
            cardHeight > 0
        ) {
            panToAvoidCard(cardHeight, selectedMapEntity);
        }
    }, [isCardOpen, selectedMapEntity, mapComponent, cardHeight]);

    // Measure info card height and pan to avoid cards on mobile
    useEffect(() => {
        const panToAvoidCard = (infoCard: InfoCard, focusedMapEntity: MapEntityBase) => {
            const { height } = infoCard.RootElement.getBoundingClientRect();
            centerInViewableArea({
                mapComponent,
                cardHeight: height,
                iconLatLng: new L.LatLng(focusedMapEntity?.lat ?? 0, focusedMapEntity?.long ?? 0),
                // buffer: 10, // DeviceInfoCard takes up a lot of space, so override to offset less
                padding: 0,
            });
        };

        const { infoCard, focusedMapEntity } = focusedContext;
        const focusedCardOpen = infoCard && focusedMapEntity && infoCard.currentInfoCardType === INFO_CARD_TYPE.DEVICE;
        if (!GlobalOptions.featureFlags.enableNewDeviceCard && mobilePanEnabled() && infoCard) {
            // Wait for card animations to finish before measuring & panning
            infoCard.RootElement.addEventListener("animationend", (e: AnimationEvent) => {
                // Account for FirstLoad animation (see InfoCardBase.scss)
                if (e.animationName === "FirstLoad" && focusedCardOpen) {
                    panToAvoidCard(infoCard, focusedMapEntity);
                }
            });
            infoCard.RootElement.addEventListener("transitionend", (e: TransitionEvent) => {
                // Longest transition is max-height (see InfoCardBase.scss), so we need to wait for it to finish
                if (e.propertyName === "max-height" && focusedCardOpen) {
                    panToAvoidCard(infoCard, focusedMapEntity);
                }
            });
        }
        return () => {
            infoCard?.RootElement?.removeEventListener("animationend", panToAvoidCard);
            infoCard?.RootElement?.removeEventListener("transitionend", panToAvoidCard);
        };
    }, [focusedContext, mapComponent]);

    const mapState = useMemo(async () => {
        if (mapComponent) {
            return computeIconState({
                iconMapEntities,
                mapComponent,
            });
        }
        return undefined;
    }, [iconMapEntities, mapComponent]);

    // Populate state when zoom level changes
    useEffect(() => {
        const populateState = async () => {
            const stateForAllZoomLevels = await mapState;

            if (stateForAllZoomLevels?.[currentMapZoom] !== undefined) {
                const stateForCurrentZoomLevel = stateForAllZoomLevels[currentMapZoom];
                setClusterItems(stateForCurrentZoomLevel.clusterMapItems);
                setDeviceItems(stateForCurrentZoomLevel.deviceMapItems);
            }
        };
        populateState();
    }, [mapState, currentMapZoom]);

    useEffect(() => {
        const onMapUpdate = () => {
            setCurrentMapZoom(mapComponent.getZoom());
            setVisibleMapBounds(getExpandedBounds(mapComponent.getBounds()));
        };

        if (mapComponent) {
            mapComponent.on("zoomend", onMapUpdate);
            mapComponent.on("moveend", onMapUpdate);
            onMapUpdate();
        }
        return () => {
            if (mapComponent) {
                mapComponent.off("zoomend", onMapUpdate);
                mapComponent.off("moveend", onMapUpdate);
            }
        };
    }, [mapComponent]);

    const handleDeviceClick = useCallback(
        (_e: React.MouseEvent, mapEntity: MapEntityBase) => {
            if (GlobalOptions.featureFlags.enableNewDeviceCard) {
                // Skip if schedule card is already (managed by focus context)
                if (FocusContext.currentInfoCard?.currentInfoCardType === INFO_CARD_TYPE.SCHEDULER) {
                    return;
                }
                openCard({ entity: mapEntity, cardType: getCardTypeFromEntity(mapEntity) });
            } else {
                FocusContext.onInteraction(_e, mapEntity);
            }
        },
        [openCard],
    );

    const visibleDeviceItems = useMemo(() => {
        if (!visibleMapBounds) return [];
        return deviceItems.filter((item) => visibleMapBounds.contains(new L.LatLng(item.lat, item.long)));
    }, [deviceItems, visibleMapBounds]);

    const visibleClusterItems = useMemo(() => {
        if (!visibleMapBounds) return [];
        return clusterItems.filter((item) => visibleMapBounds.contains(new L.LatLng(item.lat, item.long)));
    }, [clusterItems, visibleMapBounds]);

    const handleIconHover = useCallback((id: string | null) => {
        setHoveredIconId(id);
    }, []);

    // Get icon props from cache or compute
    const getIconStateProps = useCallback((mapEntity: MapEntityBase) => {
        const state = mapEntity.focusState;
        const cacheKey = `${mapEntity.id ?? mapEntity.uid}-${state}`;

        let props = iconStatePropsCache.current.get(cacheKey);
        if (!props) {
            props = makeIconPropsFromContextState(state);
            iconStatePropsCache.current.set(cacheKey, props);
        }

        return props;
    }, []);

    // On state updates, clear the cache to force re-computation
    useEffect(() => {
        const UID = GenerateUID("deviceIconUpdater");

        const handleIconUpdate = debounce(() => {
            iconStatePropsCache.current.clear();
            setTriggeredIconUpdates((prev) => prev + 1);
        }, 150);

        deviceIconUpdateEvent.addListener(handleIconUpdate, UID);

        return () => {
            deviceIconUpdateEvent.removeListener(UID);
            handleIconUpdate.clear();
        };
    }, []);

    if (!mapComponent) {
        return null;
    }

    return (
        <>
            {visibleDeviceItems
                .filter((item) => item.clusterKey === undefined)
                .map((deviceMapItem) => {
                    const { highlighted, dimmed, hidden } = getIconStateProps(deviceMapItem.mapEntity);
                    return (
                        <DeviceMarkerMemo
                            key={deviceMapItem.mapEntity.id ?? deviceMapItem.mapEntity.uid}
                            deviceMapItem={deviceMapItem}
                            currentMapZoom={currentMapZoom}
                            hoveredIconId={hoveredIconId}
                            mapComponent={mapComponent}
                            onDeviceClick={handleDeviceClick}
                            onIconHover={handleIconHover}
                            highlighted={highlighted}
                            dimmed={dimmed}
                            hidden={hidden}
                        />
                    );
                })}
            {visibleClusterItems.map((clusterMapItem) => {
                const { dimmed, hidden } = makeClusterPropsFromContext(clusterMapItem, deviceItems);
                return (
                    <ClusterMarkerMemo
                        key={clusterMapItem.id}
                        clusterMapItem={clusterMapItem}
                        deviceItems={deviceItems}
                        hoveredIconId={hoveredIconId}
                        mapComponent={mapComponent}
                        onIconHover={handleIconHover}
                        dimmed={dimmed}
                        hidden={hidden}
                    />
                );
            })}
            {hoveredIconId && (
                <ZoneHighlightPolygon
                    mapEntity={visibleDeviceItems.find((item) => item.mapEntity.id === hoveredIconId)?.mapEntity}
                />
            )}
        </>
    );
});
