import "./ConfirmChoiceButton.scss";

import { Button, Popover } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default function ConfirmChoiceButton({
    popperProps = {},
    confirmButtonProps = {},
    confirmationQuestion = undefined,
    confirmationText = undefined,
    children,
    onClick,
    color = undefined,
    disableOpen = false,
    ...otherProps
}) {
    const { t } = useTranslation("genericComponents", { keyPrefix: "confirmChoiceButton" });
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(undefined);
    const buttonProps = { ...otherProps };
    const confirmButtonText = confirmationText ?? children;

    return (
        <>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => {
                    setAnchorEl(undefined);
                    setOpen(false);
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                {...popperProps}
                className={`${popperProps?.className || ""} ConfirmChoiceButton__popper`}
            >
                {confirmationQuestion || t("confirmationQuestion")}
                <Button
                    variant={"contained"}
                    color={color}
                    {...confirmButtonProps}
                    onClick={(e) => {
                        setOpen(false);
                        setAnchorEl(undefined);
                        onClick(e);
                    }}
                >
                    {confirmButtonText}
                </Button>
            </Popover>
            <Button
                color={color}
                {...buttonProps}
                onClick={(e) => {
                    if (!disableOpen) {
                        setAnchorEl(e.currentTarget);
                        setOpen(true);
                    }
                }}
            >
                {children}
            </Button>
        </>
    );
}
//
// export default class ConfirmChoiceButton extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             open: false,
//         };
//     }
//
//     render() {
//         const buttonProps = { ...this.props };
//
//         delete buttonProps.onClick;
//
//         const popperProps = this.props.popperProps || {};
//         popperProps.className = `${popperProps.className || ""} ConfirmChoiceButton__popper`;
//         delete buttonProps.popperProps;
//
//         const confirmButtonProps = this.props.confirmButtonProps || {};
//         confirmButtonProps.className = `${confirmButtonProps.className || ""} ConfirmChoiceButton__ConfirmationButton`;
//         if (!confirmButtonProps.color && this.props.color) {
//             confirmButtonProps.color = this.props.color;
//         }
//         delete buttonProps.confirmButtonProps;
//
//         const confirmButtonText = this.props.confirmText || this.props.children;
//         delete buttonProps.confirmText;
//
//         const confirmationQuestion = this.props.confirmationQuestion || "Are you sure?";
//         delete buttonProps.confirmationQuestion;
//
//         return (
//             <>
//                 <Popover
//                     open={this.state.open}
//                     anchorEl={this.state.anchorEl}
//                     onClose={() => {
//                         this.setState({ open: false, anchorEl: undefined });
//                     }}
//                     anchorOrigin={{
//                         vertical: "top",
//                         horizontal: "center",
//                     }}
//                     transformOrigin={{
//                         vertical: "bottom",
//                         horizontal: "center",
//                     }}
//                     {...popperProps}
//                 >
//                     {confirmationQuestion}
//                     <Button
//                         variant={"contained"}
//                         {...confirmButtonProps}
//                         onClick={(e) => {
//                             this.setState({ open: false, anchorEl: undefined });
//                             this.props.onClick(e);
//                         }}
//                     >
//                         {confirmButtonText}
//                     </Button>
//                 </Popover>
//                 <Button
//                     {...buttonProps}
//                     onClick={(e) => {
//                         this.setState({ open: true, anchorEl: e.currentTarget });
//                     }}
//                 >
//                     {this.props.children}
//                 </Button>
//             </>
//         );
//     }
// }
