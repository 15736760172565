import "./JSONDropdown.scss";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import React from "react";

export default function JSONDropdown({ title, body, className = "", ...props }) {
    const [collapsed, setCollapsed] = React.useState(true);

    const finalClassName = `${className || ""} JSONDropdown`;

    return (
        <div className={finalClassName} {...props}>
            <Typography
                onClick={() => {
                    setCollapsed(!collapsed);
                }}
                variant={"h5"}
            >
                {collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />} {title}
            </Typography>
            <Collapse in={!collapsed}>
                <Typography style={{ fontFamily: "monospace", whiteSpace: "pre-wrap" }}>
                    {JSON.stringify(body, null, 4)}
                </Typography>
            </Collapse>
        </div>
    );
}
