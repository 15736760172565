import { SvgIcon, SvgIconProps } from "@mui/material";

interface SVGIconProps extends SvgIconProps {
    icon: React.ElementType;
    size?: number;
    iconColor?: string; // avoided naming as "color" to avoid conflicts with MUI
}
export function SVGIcon({ icon: IconComponent, size = 24, iconColor, ...props }: SVGIconProps) {
    return (
        <SvgIcon
            sx={{ fontSize: `${size}px` }}
            htmlColor={iconColor}
            component={IconComponent}
            inheritViewBox
            {...props}
        />
    );
}
