import { ReactNode } from "react";
import DraginoGatewayIcon from "src/components/icons/DraginoGatewayIcon";
import { ThirdPartyDeviceType } from "verditypes";
import { DeviceConfiguration } from "verditypes/DeviceConfigs";

import { isValve } from "../../../../../../services/deviceDataAccessors/deviceAttributes";
import { DEVICE_ICON_GRAPHICS } from "../../types";
// @ts-ignore (these work in vite due to svgr plugin)
import SensorIcon from "./assets/Sensor.svg?react";
// @ts-ignore
import ValveIcon from "./assets/Valve.svg?react";
import { TextIcon } from "./TextIcon";

/**
 * Scaling factor for the icon graphic (instead of directly mapping to each of the icon sizes)
 */
export const iconGraphicSizeScaling: Record<DEVICE_ICON_GRAPHICS, number> = {
    [DEVICE_ICON_GRAPHICS.GROUP]: 0.5,
    [DEVICE_ICON_GRAPHICS.MANUAL_MODE]: 0.5,
    [DEVICE_ICON_GRAPHICS.SENSOR]: 0.75,
    [DEVICE_ICON_GRAPHICS.VALVE]: 0.75,
    [DEVICE_ICON_GRAPHICS.DRAGINO_GATEWAY]: 0.75,
} as const;

/**
 * default color(s) for the icon graphic
 * gets overridden by the deviceState.colorParams.iconColor prop if its set
 */
export const iconGraphicColors = {
    default: "#424242",
} as const;

/**
 * Lookup for the icon graphic props
 */
export type IconGraphicConfig = {
    icon: React.ElementType;
    type: "SVG" | "Text";
    color?: string;
    centerContent?: ReactNode; // Manually override center
};
const iconGraphics: Record<DEVICE_ICON_GRAPHICS, IconGraphicConfig> = {
    [DEVICE_ICON_GRAPHICS.SENSOR]: {
        icon: SensorIcon,
        type: "SVG",
    },
    [DEVICE_ICON_GRAPHICS.VALVE]: {
        icon: ValveIcon,
        type: "SVG",
    },
    [DEVICE_ICON_GRAPHICS.GROUP]: {
        icon: TextIcon,
        type: "Text",
    },
    [DEVICE_ICON_GRAPHICS.MANUAL_MODE]: {
        icon: TextIcon,
        type: "Text",
        centerContent: "M",
    },
    [DEVICE_ICON_GRAPHICS.DRAGINO_GATEWAY]: {
        icon: DraginoGatewayIcon,
        type: "SVG",
    },
};

/**
 * Logic for selecting the appropriate icon graphic props based on the device state
 */
type MakeIconGraphicConfigProps = {
    manualMode?: boolean;
    deviceType: DeviceConfiguration["type"] | "GROUP" | ThirdPartyDeviceType;
};
export const makeIconGraphicConfig = ({
    manualMode,
    deviceType,
}: MakeIconGraphicConfigProps): { variant: DEVICE_ICON_GRAPHICS; iconGraphicConfig: IconGraphicConfig } => {
    // Slightly awkward to run this through the function instead of just looking up props directly from the object,
    // but here added to unify prop lookup for all icon graphics
    if (deviceType === "GROUP") {
        return {
            variant: DEVICE_ICON_GRAPHICS.GROUP,
            iconGraphicConfig: iconGraphics[DEVICE_ICON_GRAPHICS.GROUP],
        };
    }

    if (manualMode) {
        return {
            variant: DEVICE_ICON_GRAPHICS.MANUAL_MODE,
            iconGraphicConfig: iconGraphics[DEVICE_ICON_GRAPHICS.MANUAL_MODE],
        };
    }

    if(deviceType === "draginoDL0S8N") {
        // Dragino Gateway (DL0S8N)
        return {
            variant: DEVICE_ICON_GRAPHICS.DRAGINO_GATEWAY,
            iconGraphicConfig: iconGraphics[DEVICE_ICON_GRAPHICS.DRAGINO_GATEWAY],
        };
    }

    if (isValve({ deviceType })) {
        return {
            variant: DEVICE_ICON_GRAPHICS.VALVE,
            iconGraphicConfig: iconGraphics[DEVICE_ICON_GRAPHICS.VALVE],
        };
    }
    return {
        variant: DEVICE_ICON_GRAPHICS.SENSOR,
        iconGraphicConfig: iconGraphics[DEVICE_ICON_GRAPHICS.SENSOR],
    };
};
