import { Clear as ClearIcon, Room as MapMarkerIcon } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import L from "leaflet";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { MasterIndex, SessionHandler } from "verdiapi";
import { DeviceConfigurationsByType } from "verditypes/dist/Configurations/DeviceConfiguration";

import FocusContext from "../../../../services/mapManagement/FocusContext";
import { recomputeIconStateEvent } from "../../../../services/mapManagement/iconManager/IconLayer";
import ZoneMapEntity from "../../../../services/mapManagement/mapEntities/ZoneMapEntity";
import { MapComponent } from "../../../../services/mapManagement/MapHelper";
import { URLParams } from "../../../../utils/URLParams";

class _DeviceSubheader extends Component {
    TargetMapEntity;

    _curZone;

    /**
     * Make sure we don't double execute actions
     * @private
     */
    _doingDeviceRepositioning = false;

    mapClickFunction;

    originalLatLng;

    snackbarHandle;

    constructor(props) {
        super(props);
        this.Target = this.props.Target;
        this.state = {
            currentlyRelocatingDevice: false,
            latitude: this.Target.lat,
            longitude: this.Target.long,
        };
        this.TargetMapEntity = FocusContext.MapEntitesByModelID[this.Target.id];
        if (this.TargetMapEntity) {
            if (this.TargetMapEntity.leafletElement) {
                this.originalLatLng = this.TargetMapEntity.leafletElement.getLatLng();
            }
        }
        this.mapClickFunction = (e) => {
            this.state.LatLng = e.latlng;
            this.setState({
                latitude: e.latlng.lat,
                longitude: e.latlng.lng,
            });
            if (this.TargetMapEntity) {
                if (this.TargetMapEntity.leafletElement) {
                    this.TargetMapEntity.leafletElement.setLatLng(e.latlng);
                } else {
                    // Instead of moving the marker itself, move the temp marker
                    this.newDeviceLocationMarker.setLatLng(e.latlng);
                }
            }
            const oldZone = this.curZone;
            const checkIfInZone = (zone) =>
                zone && zone.isPointInZone && zone.isPointInZone([e.latlng.lat, e.latlng.lng]);
            let zoneDefined = checkIfInZone(this.curZone);

            const allZones = MasterIndex.zone.all;
            for (let i = 0; i < allZones.length && !zoneDefined; i++) {
                if (checkIfInZone(allZones[i])) {
                    zoneDefined = true;
                    this.curZone = allZones[i];
                }
            }
            if (oldZone && oldZone !== this.curZone) {
                this.RepositionDeviceContext.updateMapEntityState(oldZone);
            }
            if (this.curZone && oldZone !== this.curZone) {
                this.RepositionDeviceContext.updateMapEntityState(this.curZone);
            }
        };
        this.RepositionDeviceContext = new FocusContext();
        this.RepositionDeviceContext.enableChainedFocus = false;
        this.RepositionDeviceContext.setFocused(this.props.Target);
        this.RepositionDeviceContext.onInteract = (e, mapEntity) => {
            if (mapEntity instanceof ZoneMapEntity) {
                if (this.Target.type === "msense") {
                    this.curZone = mapEntity;
                }
            }
            return false;
        };
        this.RepositionDeviceContext.defaultFunction = (m) => {
            if (m.model === this.props.Device) {
                return "selected";
            }
            if (m.model && m.model.category) {
                if (m.model.category === "zone") {
                    if (m.model === this.curZone) {
                        return "selected";
                    }
                    return "inactive";
                }
            }
            return false;
        };
        FocusContext.onInfoCardChanged.addListener(() => {
            this.finishDeviceRepositioning(false);
        });
    }

    componentWillUnmount() {
        this.finishDeviceRepositioning(false);
    }

    get curZone() {
        if (this._curZone) {
            return this._curZone;
        }
        return this.props.Target.zone;
    }

    set curZone(v) {
        if (this.curZone && this.curZone !== v) {
            this.RepositionDeviceContext.setSelected(this.curZone, false);
        }
        this.RepositionDeviceContext.setSelected(v, true);
        this._curZone = v;
    }

    doDeviceRepositioning() {
        if (this._doingDeviceRepositioning) {
            return;
        }
        this._doingDeviceRepositioning = true;
        MapComponent.on("click", this.mapClickFunction);
        FocusContext.pushContextToStack(this.RepositionDeviceContext);

        // Create a new temporary marker to represent the device's new location
        const icon = L.divIcon({
            className: "custom-marker-icon ",
            html: `<div class="NewDevicePositionMarker"><div></div></div>`,
        });
        this.newDeviceLocationMarker = new L.Marker([this.TargetMapEntity.lat, this.TargetMapEntity.long], {
            icon: icon,
            iconSize: [0, 0],
            draggable: true,
            autoPan: true,
        });
        this.newDeviceLocationMarker.addTo(MapComponent);

        this.snackbarHandle = this.props.enqueueSnackbar("Click map to move device", {
            anchorOrigin: {
                vertical: "top",
                horizontal: "center",
            },
            variant: "info",
            persist: true,
        });
        this.setState({
            currentlyRelocatingDevice: true,
        });
    }

    finishDeviceRepositioning(save = true) {
        if (!this._doingDeviceRepositioning) {
            return;
        }
        this._doingDeviceRepositioning = false;
        FocusContext.releaseStack(this.RepositionDeviceContext);
        MapComponent.off("click", this.mapClickFunction);
        MapComponent.removeLayer(this.newDeviceLocationMarker); // clean up temp marker

        if (save) {
            const args = {
                latitude: this.state.latitude,
                longitude: this.state.longitude,
            };
            if (this.Target.type === "msense") {
                args.zone = this.curZone?.id;
            }

            this.Target.edit(args)
                .then(() => {
                    this.props.enqueueSnackbar("Saved changes!", { variant: "success" });

                    // Update the map entity's lat and long
                    this.TargetMapEntity.lat = this.state.latitude;
                    this.TargetMapEntity.long = this.state.longitude;
                    recomputeIconStateEvent.trigger();
                })
                .catch(() => {
                    this.props.enqueueSnackbar("Failed to save changes", { variant: "error" });
                    this.TargetMapEntity.leafletElement.setLatLng(this.originalLatLng);
                });
        } else if (this.TargetMapEntity && this.TargetMapEntity.leafletElement && this.originalLatLng) {
            this.TargetMapEntity.leafletElement.setLatLng(this.originalLatLng);
        }
        if (this.snackbarHandle) {
            this.props.closeSnackbar(this.snackbarHandle);
        }
        this.setState({
            currentlyRelocatingDevice: false,
        });
    }

    render() {
        const deviceIsValidForRepositioning = true;
        let TargetModel = this.Target;
        if (this.Target.model) {
            TargetModel = this.Target.model;
        }
        let labelText = TargetModel.labelText;
        let labelTextDivider = false;
        if (TargetModel.labelText && TargetModel.labelText.length <= 10) {
            labelTextDivider = " | ";
        } else {
            labelText = "";
        }
        let miniReposButton = false;
        let repositionButtonText = "Reposition";
        if (window.innerWidth <= 400) {
            repositionButtonText = "";
            miniReposButton = true;
        } else if (window.innerWidth <= 500) {
            repositionButtonText = "move";
        }
        let idText = TargetModel.id ? TargetModel.id : "No id on enclosure";
        if (TargetModel.type === "msense") {
            idText = TargetModel.enclosureID;
        }
        return (
            <div
                style={{ display: "flex", alignItems: "center" }}
                className={`DevicePositionSubheader DevicePositionSubheader--${
                    this.state.currentlyRelocatingDevice ? "repositioning" : "idle"
                }`}
            >
                <Typography className={"PositionSubheaderText"}>
                    <span
                        style={{ cursor: "pointer" }}
                        title={"copy coords..."}
                        onClick={() => {
                            navigator.clipboard.writeText(
                                `${this.state.latitude.toString()}, ${this.state.longitude.toString()}`,
                            );
                            this.props.enqueueSnackbar("Coords Copied!", { variant: "success" });
                        }}
                    >
                        {this.state.latitude ? parseFloat(this.state.latitude).toFixed(3) : "null"},
                        {this.state.longitude ? parseFloat(this.state.longitude).toFixed(3) : "null"}
                    </span>

                    <br />
                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                        <span
                            style={{ cursor: "pointer" }}
                            title={"copy coords..."}
                            onClick={() => {
                                navigator.clipboard.writeText(labelText);
                                this.props.enqueueSnackbar("Label Copied!", { variant: "success" });
                            }}
                        >
                            {labelText}
                        </span>
                        {labelTextDivider && labelTextDivider}
                        <span
                            className={"DevSubheaderIDField"}
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                                if ((e.ctrlKey || e.metaKey) && SessionHandler.admin) {
                                    const allParams = URLParams.getAllParams();
                                    allParams.params = {};
                                    allParams.params.tab = "3";
                                    allParams.params.target = idText;
                                    if (DeviceConfigurationsByType[this.Target.type]?.generation === "sprout") {
                                        allParams.params.tab = "4";
                                    }
                                    let newURL = window.location.toString();
                                    newURL = newURL.replace(/#.*/, "");
                                    newURL += `DeviceDebugger${URLParams.generateHashString(
                                        allParams.hash,
                                        allParams.params,
                                    )}`;
                                    window.open(newURL, "_blank").focus();
                                } else {
                                    navigator.clipboard.writeText(idText);
                                    this.props.enqueueSnackbar("ID copied!", { variant: "success" });
                                }
                            }}
                        >
                            ID: {idText}
                        </span>
                    </div>
                </Typography>
                {deviceIsValidForRepositioning && (
                    <Button
                        style={{ marginLeft: "6px", maxHeight: "42px" }}
                        className={`RepositionButton ${miniReposButton ? " RepositionButton--mini " : ""} 
                            ${
                                this.state.currentlyRelocatingDevice
                                    ? " RepositionButton--relocating "
                                    : " RepositionButton--not-relocating "
                            } `}
                        startIcon={this.state.currentlyRelocatingDevice ? <SaveIcon /> : <MapMarkerIcon />}
                        onClick={() => {
                            if (this.state.currentlyRelocatingDevice) {
                                this.finishDeviceRepositioning(true);
                            } else {
                                this.doDeviceRepositioning();
                            }
                        }}
                        variant={"contained"}
                    >
                        {this.state.currentlyRelocatingDevice ? "save" : repositionButtonText}
                    </Button>
                )}
                {deviceIsValidForRepositioning && (
                    <Button
                        className={"cancelButton"}
                        onClick={() => {
                            this.finishDeviceRepositioning(false);
                        }}
                        variant={"contained"}
                    >
                        <ClearIcon />
                    </Button>
                )}
            </div>
        );
    }
}

export const DeviceSubheader = withSnackbar(_DeviceSubheader);
