import DraginoGatewayIcon from "../../../components/icons/DraginoGatewayIcon";
import MoistureSensorMarkerIcon from "../../../components/icons/MapIcons/MoistureSensorMarkerIcon";
import DeviceMapEntity from "./DeviceMapEntity";

export class ThirdPartyDeviceMapEntity extends DeviceMapEntity {
    status = "Unknown";

    pickIcon() {
        if(this.model.deviceType === "draginoDL0S8N") {
            this.icon = DraginoGatewayIcon;
        }
        else {
            this.icon = MoistureSensorMarkerIcon;
        }
    }
}

