import * as turf from "@turf/turf";
import L from "leaflet";
import React, { memo, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { Zone } from "verdiapi/dist/Models";

import { type GroupItem } from "../../../../components/icons/MapIcons/DeviceIcon/components/GroupMenu/types";
import { IconAvatar } from "../../../../components/icons/MapIcons/DeviceIcon/components/IconAvatar";
import {
    iconGraphicSizeScaling,
    makeIconGraphicConfig,
} from "../../../../components/icons/MapIcons/DeviceIcon/components/IconGraphic/constants";
import { IconGraphic } from "../../../../components/icons/MapIcons/DeviceIcon/components/IconGraphic/IconGraphic";
import { iconColorMapping } from "../../../../components/icons/MapIcons/DeviceIcon/constants";
import { DeviceIcon } from "../../../../components/icons/MapIcons/DeviceIcon/DeviceIcon";
import { ICON_VARIANT, WARNING_STATUS } from "../../../../components/icons/MapIcons/DeviceIcon/types";
import { getCardTypeFromEntity, INFO_CARD_TYPE } from "../../../../components/specialized/infoCards/InfoCardTypes";
import { useStore } from "../../../../store/store";
import GlobalOptions from "../../../../utils/GlobalOptions";
import { getDisplayName } from "../../../deviceDataAccessors/deviceAttributes";
import FocusContext from "../../FocusContext";
import {
    makeBadgeParams,
    makeGroupProgressRingParams,
    makeWarnings,
    WARNING_STATUS_PRIORITY,
} from "../../mapEntities/shared";
import { centerInViewableArea, mobilePanEnabled } from "../focus";
import { useIconSize } from "../sizing";
import type { ClusterMapItem, DeviceMapItem } from "../types";

const MENU_ITEM_HEIGHT = 48;
const MAX_MENU_ITEMS_BEFORE_SCROLL = 6;
const MENU_PADDING = 8;
const MENU_MAX_HEIGHT = MENU_ITEM_HEIGHT * MAX_MENU_ITEMS_BEFORE_SCROLL + MENU_PADDING * 2 + 5;

interface DeviceGroupMapEntityIconProps {
    clusterMapItem: ClusterMapItem;
    deviceMapItems: DeviceMapItem[];
    mapComponent: L.Map;
    dimmed?: boolean;
    onHover: (isHovered: boolean) => void;
    hidden?: boolean;
}

/**
 * Renders a device group icon. Wraps the DeviceIcon component, contains logic for what an icon should look like
 * based on the device document and which props to pass in to the DeviceIcon component
 */
export const DeviceGroupMapEntityIcon = memo(
    ({ clusterMapItem, deviceMapItems, mapComponent, dimmed, onHover, hidden }: DeviceGroupMapEntityIconProps) => {
        const openCard = useStore((state) => state.openCard);
        const closeCard = useStore((state) => state.closeCard);
        const [groupWarningStatus, setGroupWarningStatus] = useState<WARNING_STATUS | undefined>(undefined);
        const iconSize = useIconSize({ mapComponent });
        const menuMaxHeight = Math.min(MENU_MAX_HEIGHT, window.innerHeight * 0.4);

        // Create the groupItem list from the members of the cluster
        const groupItems = useMemo(() => {
            let highestPriorityWarningStatus: WARNING_STATUS | undefined;
            const groupItemsArray: GroupItem[] = [];
            turf.featureEach(clusterMapItem.cluster, (feature) => {
                const deviceMapItem = deviceMapItems.find((item) => item.mapEntity.id === feature.properties?.id);
                if (!deviceMapItem || deviceMapItem.mapEntity.focusState === "hidden") {
                    return;
                }

                const { status } = makeWarnings({
                    model: deviceMapItem.mapEntity.model,
                    type: deviceMapItem.mapEntity.model.type,
                });

                if (
                    (status && !highestPriorityWarningStatus) ||
                    (status &&
                        highestPriorityWarningStatus &&
                        WARNING_STATUS_PRIORITY[status] > WARNING_STATUS_PRIORITY[highestPriorityWarningStatus])
                ) {
                    highestPriorityWarningStatus = status;
                }

                const zones = deviceMapItem.mapEntity.model?.connectedZones?.map((zone: Zone) => ({
                    id: zone.id,
                    name: zone.name,
                }));

                groupItemsArray.push({
                    zones,
                    id: deviceMapItem.mapEntity.id,
                    label: getDisplayName({ model: deviceMapItem.mapEntity.model }),
                    icon: <IconAvatar mapEntity={deviceMapItem.mapEntity} />,
                    onClick: (_e: React.MouseEvent<HTMLElement>) => {
                        if (GlobalOptions.featureFlags.enableNewDeviceCard) {
                            openCard({
                                entity: deviceMapItem.mapEntity,
                                cardType: getCardTypeFromEntity(deviceMapItem.mapEntity),
                            });
                        } else {
                            FocusContext.onInteraction(_e, deviceMapItem.mapEntity);
                        }
                    },
                    model: deviceMapItem.mapEntity.model,
                });
            });

            // Set the badge variant to the highest priority warning status
            setGroupWarningStatus(highestPriorityWarningStatus);
            return groupItemsArray;
        }, [clusterMapItem, deviceMapItems, openCard]);

        if (hidden) {
            return <></>;
        }

        const { variant, iconGraphicConfig } = makeIconGraphicConfig({
            deviceType: "GROUP",
        });

        return (
            <DeviceIcon
                tooltip={{
                    enabled: false,
                }}
                dimmed={dimmed}
                size={iconSize}
                forceExpand={true}
                colorParams={iconColorMapping[ICON_VARIANT.GROUP]}
                onMouseEnter={() => onHover(true)}
                onMouseLeave={() => onHover(false)}
                badgeParams={makeBadgeParams({
                    warningStatus: groupWarningStatus,
                })}
                progressRingParams={makeGroupProgressRingParams({ models: groupItems.map((item) => item.model) })}
                onClick={async () => {
                    if (GlobalOptions.featureFlags.enableNewDeviceCard) {
                        // Skip if schedule card is open (managed by focus context)
                        if (FocusContext.currentInfoCard?.currentInfoCardType === INFO_CARD_TYPE.SCHEDULER) {
                            return;
                        }
                    }

                    /**
                     * On mobile, dismiss any open info cards when opening the group menu (mobile menu conflicts with info card)
                     * On desktop, only dismiss device info cards when opening the group menu
                     */
                    if (
                        FocusContext.currentInfoCard &&
                        (isMobile || FocusContext.currentInfoCard.currentInfoCardType === INFO_CARD_TYPE.DEVICE)
                    ) {
                        if (GlobalOptions.featureFlags.enableNewDeviceCard) {
                            closeCard();
                        }
                        await FocusContext.dismissCurrentInfoCard();
                    }

                    if (groupItems.length > 0 && mobilePanEnabled()) {
                        const menuHeight =
                            groupItems.length <= MAX_MENU_ITEMS_BEFORE_SCROLL
                                ? MENU_ITEM_HEIGHT * groupItems.length + MENU_PADDING * 2
                                : menuMaxHeight;

                        centerInViewableArea({
                            mapComponent,
                            cardHeight: menuHeight,
                            iconLatLng: new L.LatLng(clusterMapItem.lat, clusterMapItem.long),
                        });
                    }
                }}
                groupMenu={{
                    menuItems: groupItems,
                    groupMenuOptions: {
                        menuMaxHeight,
                        menuPadding: MENU_PADDING,
                        menuItemHeight: MENU_ITEM_HEIGHT,
                    },
                }}
            >
                <IconGraphic
                    {...iconGraphicConfig}
                    size={iconSize * iconGraphicSizeScaling[variant]}
                    centerContent={groupItems.length.toString()}
                />
            </DeviceIcon>
        );
    },
    (prevProps, nextProps) =>
        prevProps.dimmed === nextProps.dimmed &&
        prevProps.clusterMapItem === nextProps.clusterMapItem &&
        prevProps.hidden === nextProps.hidden,
);
