import { BufferedAction, GenerateUID } from "gardenspadejs/dist/general";
import L from "leaflet";
import { isMobile } from "react-device-detect";
import * as VerdiAPI from "verdiapi";
import { EventHandler } from "verdiapi";

import { INFO_CARD_TYPE } from "../../components/specialized/infoCards/InfoCardTypes";
import { URLParams } from "../../utils/URLParams";
// eslint-disable-next-line import/no-cycle
import { MapComponent } from "./MapHelper";

const innerWindowHeight = window.innerHeight;
export default class FocusContext {
    ghost = false;

    name = "unnamed context";

    /**
     * class that will be added to the body when this focus context is active
     * @type {string}
     */
    bodyClass;

    /**
     *
     * @param {MapEntityBase} mapEntity
     * @return {"hidden" | "focused" | "selected" | "inactive" | "active" | undefined}
     */
    defaultFunction = (mapEntity) => "active";

    static _mapEntityBeingFocused = undefined;
    /**
     *
     * @param {MouseEvent | TouchEvent} event The event (mouse click, double click, etc.)
     * @param {MapEntityBase} mapEntity
     * @param {FocusContext} focusContext The focus context parent
     * @return {boolean} Return true if this interaction should be passed to the next
     * focus handler or the default iteraction handler.
     *
     */

    onInteract = async (event, mapEntity, focusContext, renderInfoCard = true) => {
        if (
            FocusContext.currentInfoCard &&
            FocusContext.currentInfoCard.focusContext &&
            mapEntity.infoCard !== FocusContext.currentInfoCard
        ) {
            await FocusContext.releaseStack(FocusContext.currentInfoCard.focusContext);
            FocusContext.activeContext.setFocused(mapEntity);
        } else {
            // NOTE: This is essentially this.setFocused and doesn't necessarily set the focus if this action
            // will destroy this focus context.
            focusContext.setFocused(mapEntity);
        }
        
        try{
            FocusContext._mapEntityBeingFocused = mapEntity;

            // Skip rendering the info card if its handled elsewhere
            if (renderInfoCard) {
               await FocusContext.GenerateInfoCardForMapEntity(mapEntity);
  
            }
            if (this._curInfoCard) {
                this._curInfoCard.usurp();
            }
   
            if (mapEntity.infoCard) {
                FocusContext.currentInfoCard = mapEntity.infoCard;
            } else {
                FocusContext.currentInfoCard = undefined;
            }
            FocusContext.mapEntityBeingFocused = undefined;
        } catch (e) {
            console.warn("issue generating info card", e);
        }

        try {
            if (event.stopPropagation) {
                event.stopPropagation();
            }
        } catch (e) {
            console.warn(e);
        }
    };

    /**
     *
     *Triggers when the selected map entities change
     *
     */
    onSelectionChange = new EventHandler({});

    /**
     * If true, then an element which was focused in the FocusContext frames beneath this
     * focusContext in the stack will still appear focused,
     *
     * This is useful if you are using a new focus context to change what zone a device belongs to
     * or something similar, where the previously focused item is still relevant.
     * @type {boolean}
     */
    enableChainedFocus = true;

    getPrevContext() {
        if (FocusContext.contextStack.includes(this)) {
            const prevIndex = FocusContext.contextStack.indexOf(this) - 1;
            if (prevIndex === -1) {
                return FocusContext.defaultContext;
            }
            return FocusContext.contextStack[prevIndex];
        }
        if (FocusContext.defaultContext === this) {
            return this;
        }
        return FocusContext.activeContext;
    }

    /**
     * Typically only needed if you have a dynamic defaultFunction that changes it's output based on variables.
     *
     * Updates a map entity when something about it's situation changes that indicates it should be displayed
     * differently. If you set a map entity as selected, or focused, or active etc. using
     * the provided functions, you do not need to call this. But if you modify things like the defaultFunction
     * or manually adjust the `selected` or `active` etc. set directly, then this is how you ensure
     * the map entities actually adjust their focus state.
     * @param mapEntity
     * @param force
     * @param newEntity
     * @return {string|string|*|string|undefined}
     */

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateMapEntityState(mapEntity, force = false, newEntity = false) {
        if (mapEntity instanceof VerdiAPI.Models.ModelBase) {
            mapEntity = FocusContext.MapEntitesByModelID[mapEntity.id];
        }
        if (FocusContext.activeContext === this) {
            let state = this.getMapEntityState(mapEntity);
            if (
                mapEntity.model.linkedAreaOfInterest &&
                FocusContext.selectedAOI &&
                mapEntity.model.linkedAreaOfInterest !== FocusContext.selectedAOI
            ) {
                state = "hidden";
            }
            if (!newEntity) {
                if (FocusContext.cachedMapEntityStates[mapEntity.uid] !== state) {
                    FocusContext.cachedMapEntityStates[mapEntity.uid] = state;
                    mapEntity.updateFocusState();
                }
            } else {
                FocusContext.cachedMapEntityStates[mapEntity.uid] = state;
            }
            return state;
        }
        return undefined;
    }

    MapEntityStatusOptions = {};

    static cachedMapEntityStatusOptions = {};

    setMapEntityStatusOptions(mapEntity, options) {
        if (mapEntity instanceof VerdiAPI.Models.ModelBase) {
            mapEntity = FocusContext.MapEntitesByModelID[mapEntity.id];
        }
        this.MapEntityStatusOptions[mapEntity.uid] = options;
        mapEntity._lastStatusOptions = undefined;
        mapEntity.updateFocusState();
    }

    /**
     *
     * @type {Set<MapEntityBase>}
     */
    hidden = new Set();

    /**
     *
     * @type {undefined | MapEntityBase}
     */
    focused = undefined;

    /**
     *
     * @type {Set<MapEntityBase>}
     */
    selected = new Set();

    /**
     *
     * @type {Set<MapEntityBase>}
     */
    inactive = new Set();

    /**
     *
     * @type {Set<MapEntityBase>}
     */
    active = new Set();

    setHidden(mapEntity, hide = true) {
        if (mapEntity instanceof VerdiAPI.Models.ModelBase) {
            mapEntity = FocusContext.MapEntitesByModelID[mapEntity.id];
        }
        if (hide) {
            this.hidden.add(mapEntity);
        } else {
            this.hidden.delete(mapEntity);
        }
        this.updateMapEntityState(mapEntity);
    }

    setFocused(mapEntity, focus = true) {
        if (mapEntity instanceof VerdiAPI.Models.ModelBase) {
            mapEntity = FocusContext.MapEntitesByModelID[mapEntity.id];
        }
        if (!focus) {
            if (mapEntity === this.focused) {
                const oldFocused = this.focused;
                this.focused = undefined;
                this.updateMapEntityState(oldFocused);
            }
        } else {
            if (this.focused) {
                const oldFocused = this.focused;
                this.focused = undefined;
                this.updateMapEntityState(oldFocused);
            }
            if (mapEntity) {
                this.focused = mapEntity;
                this.updateMapEntityState(mapEntity);
            }
        }
        this.onFocusedChanged.trigger({ newFocus: this.focused, value: this.focused });
    }

    onFocusedChanged = new EventHandler();

    setSelected(mapEntity, select = true) {
        if (mapEntity instanceof VerdiAPI.Models.ModelBase) {
            mapEntity = FocusContext.MapEntitesByModelID[mapEntity.id];
        }
        let triggerEvent = false;
        if (select) {
            triggerEvent = !this.selected.has(mapEntity);
            this.selected.add(mapEntity);
        } else {
            triggerEvent = this.selected.has(mapEntity);
            this.selected.delete(mapEntity);
        }
        this.updateMapEntityState(mapEntity);
        if (triggerEvent) {
            this.onSelectionChange.trigger({ entityChanged: mapEntity, selected: select });
        }
    }

    setInactive(mapEntity, inactive = true) {
        if (mapEntity instanceof VerdiAPI.Models.ModelBase) {
            mapEntity = FocusContext.MapEntitesByModelID[mapEntity.id];
        }
        if (inactive) {
            this.inactive.add(mapEntity);
        } else {
            this.inactive.delete(mapEntity);
        }
        this.updateMapEntityState(mapEntity);
    }

    clearHidden() {
        const Unhide = [...this.hidden];
        Unhide.forEach((me) => {
            this.hidden.delete(me);
            this.updateMapEntityState(me);
        });
    }

    clearFocused() {
        this.setFocused(undefined);
        // let Unhide = [...this.hidden];
        // Unhide.forEach((me) => {
        //     this.hidden.remove(me);
        //     this.updateMapEntityState(me);
        // });
        this.onFocusedChanged.trigger({ newFocus: this.focused, value: this.focused });
    }

    clearSelected() {
        const Unselect = [...this.selected];
        const trigger = this.selected.size > 0;
        Unselect.forEach((me) => {
            this.selected.delete(me);
            this.updateMapEntityState(me);
        });
        if (trigger) {
            this.onSelectionChange.trigger({ entityChanged: undefined, selected: undefined });
        }
    }

    clearInactive() {
        const Activate = [...this.inactive];
        Activate.forEach((me) => {
            this.inactive.delete(me);
            this.updateMapEntityState(me);
        });
    }

    clearActive() {
        const deactivate = [...this.active];
        deactivate.forEach((me) => {
            this.active.delete(me);
            this.updateMapEntityState(me);
        });
    }

    static lastTemporaryMapViewLock = 0;

    static MapViewToPoint(lat, lng) {
        requestAnimationFrame(() => {
            const latitudePerPixel =
                (MapComponent.layerPointToLatLng([0, 0]).lat - MapComponent.layerPointToLatLng([0, 100]).lat) / 100;
            let PixelHeightOfInfoCard = 0;
            if (FocusContext.currentInfoCard) {
                // saves redraws over other method.
                if (FocusContext.currentInfoCard.VisibilityState === "hidden") {
                    PixelHeightOfInfoCard = 0;
                } else if (FocusContext.currentInfoCard.VisibilityState === "ducked") {
                    PixelHeightOfInfoCard = innerWindowHeight * 0.2;
                } else {
                    PixelHeightOfInfoCard = innerWindowHeight * 0.4;
                }
            }
            MapComponent.panTo([lat - (latitudePerPixel * PixelHeightOfInfoCard) / 2.0, lng]);
        });
    }

    static MapViewToEntity(mapEntity, padRatio = undefined) {
        if (!mapEntity) {
            console.info("No map entity given to pan to");
            return;
        }
        if (Math.abs(Date.now() - this.lastTemporaryMapViewLock) < 1000) {
            console.info("Not panning map due to a view lock");
            return;
        }
        requestAnimationFrame(() => {
            if (mapEntity instanceof VerdiAPI.Models.ModelBase) {
                mapEntity = FocusContext.MapEntitesByModelID[mapEntity.id];
            }
            if (!mapEntity) {
                console.info("No map entity given to pan to");
                return;
            }

            const bottomOffset = FocusContext.calculateInfoCardBottomOffset();
            // Zones / AOI have bounds
            if (mapEntity.leafletElement?.getBounds) {
                // reject if bounds are invalid (ie. improperly set)
                if (!mapEntity.leafletElement.getBounds().isValid()) {
                    console.error("Invalid leaflet bounds for map entity", mapEntity);
                    return;
                }
                // Correct zoom only if 4 or more levels off of the default zoom to fit the entire padded bounds
                // or always zoom if padding ratio is provided
                let targetZoom = MapComponent.getZoom();
                const paddedBounds = mapEntity.leafletElement.getBounds().pad(padRatio || 1);
                const boundsZoom = MapComponent.getBoundsZoom(paddedBounds);
                const zoomDiffThreshold = 4;
                if (padRatio || Math.abs(boundsZoom - targetZoom) >= zoomDiffThreshold) {
                    targetZoom = boundsZoom;
                }
                const center = FocusContext.getBoundsCenter(mapEntity.leafletElement.getBounds(), targetZoom, {
                    x: bottomOffset.x,
                    y: bottomOffset.y,
                });
                MapComponent.setView(center, targetZoom, {
                    animate: true,
                    duration: 0.5,
                });
            } else {
                // MapMarkers have LatLng instead of bounds
                MapComponent.flyTo(mapEntity.leafletElement.getLatLng(), 18, {
                    duration: 0.5,
                    paddingBottomRight: [bottomOffset.x, bottomOffset.y],
                });
            }
        });
    }

    static MapViewToEntities(mapEntities) {
        // Get the bounds of each map entity
        const mapEntitiesBounds = mapEntities.map((mapEntity, i) => {
            const { leafletElement } = mapEntity;
            return leafletElement.getBounds();
        });

        // Move the map to centre the entities if there are any
        if (mapEntitiesBounds && mapEntitiesBounds.length > 0) {
            const bottomOffset = FocusContext.calculateInfoCardBottomOffset();
            requestAnimationFrame(() => {
                // eslint-disable-next-line no-undef
                MapComponent.flyToBounds(new L.LatLngBounds(mapEntitiesBounds), {
                    duration: 0.5,
                    paddingBottomRight: [bottomOffset.x, bottomOffset.y],
                });
            });
        }
    }

    /**
     * Zooming out to include all AOIs means the map zooms out unreasonable far when there are satellite sites
     * (See Gallo for an example).
     *
     * To prevent this, we use the manually defined default starting coordinates for each account, and filter out AOIs
     * which are not within a threshold radius from the start position. From here we zoom to fit all AOIs in the view.
     *
     * This is a temporary solution
     * TODO - Replace this when we develop a proper Regioning solution to group AOIs appropriately
     */
    static EntitiesInRadiusOfDefaultCoords(radiusThresholdInKM = 10) {
        const diameterThresholdInKM = radiusThresholdInKM * 2;
        const diameterThresholdInMeters = diameterThresholdInKM * 1000;

        // Compute the boundary around the default position with the specified radius
        const defaultPosition = VerdiAPI.SessionHandler.defaultPosition;
        // eslint-disable-next-line no-undef
        const defaultBoundary = new L.LatLng(defaultPosition[0], defaultPosition[1]).toBounds(
            diameterThresholdInMeters,
        );

        // Return an array of all AOIs within the default boundary
        return VerdiAPI.MasterIndex.aoi.all
            .filter(
                (aoi) =>
                    aoi.MapEntity.leafletElement.getBounds()?.isValid() &&
                    defaultBoundary.contains(aoi.MapEntity.leafletElement.getBounds()),
            )
            .map((aoi) => aoi.MapEntity);
    }

    /**
     * Handles calculation to apply a bottomRight pixel offset to a bounds,
     * such as to center the map on a zone but avoid an info card
     *
     * @param {L.LatLngBounds} bounds
     * @param {number} zoom required for map projection
     * @param {{x: number, y: number}} bottomRightOffset offset to add in px
     * @returns {L.LatLng} center point of the bounds with offset applied
     */
    static getBoundsCenter(bounds, zoom, bottomRightOffset) {
        const southEast = bounds.getSouthEast();
        const projectedSE = MapComponent.project(southEast, zoom);
        const adjustedSE = projectedSE.add([bottomRightOffset.x, bottomRightOffset.y]);
        const newSouthEast = MapComponent.unproject(adjustedSE, zoom);

        const newBounds = new L.LatLngBounds(bounds.getNorthWest(), newSouthEast);
        return newBounds.getCenter();
    }

    // Calculate size of the info card if it is obscuring the map and provide the appropriate
    // offset to ensure that the map area we are panning/zooming to is viewable
    static calculateInfoCardBottomOffset() {
        let bottomOffset = {
            x: 0,
            y: 0,
        };

        if (FocusContext.currentInfoCard) {
            // Only apply specific offsets when card does NOT take up majority of the screen
            const screenDoesNotSupportCustomOffset =
                window.innerWidth <= 2 * FocusContext.currentInfoCard?.RootElement?.offsetWidth;
            if (isMobile && screenDoesNotSupportCustomOffset) {
                bottomOffset.y = FocusContext?.currentInfoCard?.RootElement?.offsetHeight || 0;
            } else {
                bottomOffset = this._calculateOffsetPerCard(FocusContext.currentInfoCard?.currentInfoCardType);
            }
        }
        return bottomOffset;
    }

    static _calculateOffsetPerCard(cardType) {
        const cardsInBottomRight = [
            INFO_CARD_TYPE.DEVICE,
            INFO_CARD_TYPE.ADD_DEVICE,
            INFO_CARD_TYPE.ZONE,
            INFO_CARD_TYPE.SEARCH,
        ];
        if (cardType === INFO_CARD_TYPE.SCHEDULER) {
            return {
                x: 0,
                y: FocusContext?.currentInfoCard?.RootElement?.offsetHeight || 0,
            };
        }
        if (cardsInBottomRight.includes(cardType)) {
            // Cards are in bottom right, leaving the entire left vertical screen space.
            return {
                x: FocusContext?.currentInfoCard?.RootElement?.offsetWidth || 0,
                y: 0,
            };
        }
        return {
            x: 0,
            y: 0,
        };
    }

    getMapEntityState(mapEntity) {
        if (mapEntity instanceof VerdiAPI.Models.ModelBase) {
            mapEntity = FocusContext.MapEntitesByModelID[mapEntity.id];
        }
        let state;
        for (let i = FocusContext.contextStack.length - 1; i >= -1; i--) {
            let context = FocusContext.defaultContext;
            if (i >= 0) {
                context = FocusContext.contextStack[i];
            }

            if (context.focused === mapEntity) {
                state = "focused";
                break;
            }
            if (!context.enableChainedFocus) {
                break;
            }
        }
        if (state === "focused") {
            // state already defined, skip other condiitionals
        } else if (this.hidden.has(mapEntity)) {
            state = "hidden";
        } else if (this.focused === mapEntity) {
            state = "focused";
        } else if (this.selected.has(mapEntity)) {
            state = "selected";
        } else if (this.inactive.has(mapEntity)) {
            state = "inactive";
        } else if (this.active.has(mapEntity)) {
            state = "active";
        } else {
            state = this.defaultFunction(mapEntity);
        }
        if (!state) {
            if (FocusContext.defaultContext === this) {
                return "active";
            }
            const prevContext = this.getPrevContext();
            return prevContext.getMapEntityState(mapEntity);
        }
        return state;
    }

    /**
     *
     * @type {Set<MapEntityBase>}
     */
    static allMapEntites = new Set();

    /**
     *
     * @type {Record<string, MapEntityBase>}
     */
    static MapEntitesByModelID = {};

    static MapEntitesByUID = {};

    /**
     *
     * @type {FocusContext}
     */
    static defaultContext = undefined;

    static GenerateInfoCardForMapEntity = () => {};

    /**
     *
     * @type {InfoCard}
     * @private
     */
    static _curInfoCard = undefined;

    static set currentInfoCard(v) {
        if (this._curInfoCard === v) {
            return;
        }
        if (this._curInfoCard) {
            this._curInfoCard.usurp();
        }
        if (v) {
            console.info("c: pushing new info card");
            v.focus();
        }
        this._curInfoCard = v;
        this.onInfoCardChanged.trigger({ target: this._curInfoCard });
    }

    static get currentInfoCard() {
        return FocusContext._curInfoCard;
    }

    // TODO SWD-1342 potentially not needed anymore. Should be removed before merging
    static async dismissCurrentInfoCard(delay = 0) {
        if (this.currentInfoCard) {
            const closeWithDelay = () =>
                new Promise((resolve) => {
                    setTimeout(() => {
                        FocusContext.currentInfoCard = undefined;
                    resolve();
                }, delay);
            });

            if (FocusContext.activeContext) {
                FocusContext.releaseStack(FocusContext.activeContext).then(() => {
                    FocusContext.defaultContext.clearFocused();
                    FocusContext.defaultContext.clearSelected();
                    return closeWithDelay();
                });
            } else {
                return closeWithDelay();
            }
        }
        return Promise.resolve();
    }

    /**
     *
     * @type {FocusContext[]}
     */
    static contextStack = [];

    static get activeContext() {
        if (this.contextStack.length > 0) {
            let i = 1;
            while (i <= this.contextStack.length) {
                const ctx = this.contextStack[this.contextStack.length - i];
                if (!ctx.ghost) {
                    return ctx;
                }
                i++;
            }
        }
        return this.defaultContext;
    }

    static async pushContextToStack(context) {
        let OwnPromise;
        const newPromise = new Promise((resolve) => {
            OwnPromise = resolve;
        });
        try {
            const aheadInLine = this.queueHandler;
            this.queueHandler = newPromise;
            await aheadInLine;
            this.contextStack.push(context);
            FocusContext.updateMapEntities.trigger();
            this.onContextChanged.trigger({ target: FocusContext.activeContext });
        } catch (e) {
            console.warn("issues pushing context to stack", e);
        }
        OwnPromise();
    }

    static onAOIChange = new VerdiAPI.EventHandler();

    static selectedAOI = undefined;

    static setAOI(aoiToSelect, { panToAOI = true } = {}) {
        if (aoiToSelect === "") {
            aoiToSelect = undefined;
        }
        this.selectedAOI = aoiToSelect;
        this.allMapEntites.forEach((mapEntity) => {
            FocusContext.activeContext.updateMapEntityState(mapEntity);
        });
        if (panToAOI) {
            if (aoiToSelect) {
                FocusContext.MapViewToEntity(aoiToSelect, 0.25);
            } else {
                // Undefined aoiToSelect means we are viewing all AOIs
                const aoiEntitiesNearDefaultPosition = FocusContext.EntitiesInRadiusOfDefaultCoords();

                FocusContext.MapViewToEntities(aoiEntitiesNearDefaultPosition);
            }
        }

        this.onAOIChange.trigger({ aoi: this.selectedAOI });
        if (this.selectedAOI) {
            URLParams.setParam("field", this.selectedAOI.id);
        } else {
            URLParams.setParam("field", undefined);
        }
    }

    /**
     *
     * @type {EventHandler}
     */
    static onContextChanged = new VerdiAPI.EventHandler();

    /**
     *
     * @type {EventHandler}
     */
    static onInfoCardChanged = new VerdiAPI.EventHandler();

    static queueHandler = undefined;

    static async clearStack() {
        if (this.contextStack.length > 0) {
            return this.releaseStack(this.contextStack[0]);
        }
        return undefined;
    }

    /**
     * This function itself typically takes 1-3 ms to run, but the event handlers
     * might take longer and should be investigated as a potential source of lag.
     * @param context
     */
    static async releaseStack(context) {
        let OwnPromise;
        const newPromise = new Promise((resolve) => {
            OwnPromise = resolve;
        });
        const aheadInLine = this.queueHandler;
        this.queueHandler = newPromise;
        await aheadInLine;
        console.info("c: Stack Being Released");
        if (this.contextStack.includes(context)) {
            let PoppedElement;

            const popElement = () => {
                PoppedElement = this.contextStack.pop();

                if (!PoppedElement.ghost) {
                    Object.keys(PoppedElement.MapEntityStatusOptions).forEach((k) => {
                        FocusContext.mapEntityIDsPendingChanges.add(k);
                    });
                }
            };
            popElement();
            while (PoppedElement !== context) {
                popElement();
            }
            FocusContext.updateMapEntities.trigger();
            // clear cache of entity statuses
            this.onContextChanged.trigger({ target: FocusContext.activeContext });
        }
        OwnPromise();
    }

    static async onInteraction(event, MapEntity, renderInfoCard = true) {
        for (let i = this.contextStack.length - 1; i >= 0; i--) {
            const result = await this.contextStack[i].onInteract(event, MapEntity, this.contextStack[i], renderInfoCard);
            if (!result) {
                return;
            }
        }
        this.defaultContext.onInteract(event, MapEntity, this.defaultContext, renderInfoCard);
    }

    static mapEntityIDsPendingChanges = new Set();

    static updateMapEntities = new BufferedAction(
        async () => {
            const affectedEntityCollapsed = [...FocusContext.mapEntityIDsPendingChanges];
            FocusContext.mapEntityIDsPendingChanges = new Set();
            let resolve;
            const resolutionPromise = new Promise((res) => {
                resolve = res;
            });
            window.requestAnimationFrame(() => {
                affectedEntityCollapsed.forEach((EntityUID) => {
                    this.MapEntitesByUID[EntityUID]._lastStatusOptions = undefined;
                });
                this.allMapEntites.forEach((me) => {
                    FocusContext.activeContext.updateMapEntityState(me);
                });
                resolve();
            });
            await resolutionPromise;
        },
        50,
        false,
        true,
    );

    static cachedMapEntityStates = {};

    static _activeLoadHandlers = {};

    static onLoadStatusChange = new EventHandler();

    static startLoadHandler(reason, key) {
        if (key === undefined) {
            key = GenerateUID("loadHandler");
        }
        this._activeLoadHandlers[key] = reason;
        this.onLoadStatusChange.trigger({});
        return key;
    }

    static updateLoadHandler(newReason, key) {
        if (key in this._activeLoadHandlers) {
            this._activeLoadHandlers[key] = newReason;
            this.onLoadStatusChange.trigger({});
        }
        return key;
    }

    static resolveLoadHandler(key) {
        delete this._activeLoadHandlers[key];
        this.onLoadStatusChange.trigger({});
    }
}
FocusContext.defaultContext = new FocusContext();
