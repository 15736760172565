import { Box, SxProps } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SessionHandler } from "verdiapi";

interface TimedComponentProps {
    children: React.ReactNode;
    expiry: Date; // Date object representing date after which the component should be hidden
    showExpiredForAdmin?: boolean; // component will remain visible to admin accounts with special styling
    expiredSx?: SxProps;
}

/**
 * Wrapper component that renders a component until a given expiry date.
 *
 * Optionally can remain visible to admins even after expiry.
 */
export function TimedComponent({ children, expiry, showExpiredForAdmin = false, expiredSx }: TimedComponentProps) {
    const [isExpired, setIsExpired] = useState<boolean>(false);
    const userIsAdmin = Boolean(SessionHandler.admin);

    useEffect(() => {
        // Check if already expired
        const checkExpiry = () => {
            const now = new Date();
            setIsExpired(now > expiry);
        };
        checkExpiry();
        // Check expiry every hour
        const intervalId = setInterval(checkExpiry, 60 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, [expiry]);

    // If expired and not showing for admin, don't render anything
    if (isExpired && (!showExpiredForAdmin || !userIsAdmin)) {
        return null;
    }

    // If expired but showing for admin, render with special styling
    if (isExpired && showExpiredForAdmin && userIsAdmin) {
        return (
            <Box
                sx={{ opacity: 0.5, border: "1px dashed #ff0000", padding: "2px", ...expiredSx }}
                title={"This component is expired but visible to admins"}
            >
                {children}
            </Box>
        );
    }

    // Not expired, render normally
    return <>{children}</>;
}
