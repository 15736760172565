import { memo, useEffect, useState } from "react";

import {
    iconGraphicSizeScaling,
    makeIconGraphicConfig,
} from "../../../../components/icons/MapIcons/DeviceIcon/components/IconGraphic/constants";
import { IconGraphic } from "../../../../components/icons/MapIcons/DeviceIcon/components/IconGraphic/IconGraphic";
import { DeviceIcon } from "../../../../components/icons/MapIcons/DeviceIcon/DeviceIcon";
import { isManualMode } from "../../../deviceDataAccessors/manualMode";
import MapEntityBase from "../../mapEntities/MapEntityBase";
import {
    disabledByNotes,
    makeBadgeParams,
    makeColorParams,
    makeProgressRingParams,
    makeWarnings,
    shouldExpand,
} from "../../mapEntities/shared";
import { useIconSize } from "../sizing";

const MAX_ZOOM_FOR_EXPANDED_ICONS = 19;

const makeIconState = (deviceModel: MapEntityBase["model"]) => ({
    warnings: makeWarnings({ model: deviceModel, type: deviceModel.type }),
    colorParams: makeColorParams({ deviceType: deviceModel.type, notes: deviceModel.notes }),
    manualMode: isManualMode({ model: deviceModel }),
});

interface DeviceMapEntityIconProps {
    deviceModel: MapEntityBase["model"];
    onClickIcon: (e: React.MouseEvent<HTMLDivElement>) => void;
    onHover: (e: React.MouseEvent<HTMLDivElement>, isHovered: boolean) => void;
    mapComponent: L.Map;
    currentMapZoom: number;
    dimmed?: boolean;
    highlighted?: boolean;
    hidden?: boolean;
}

/**
 * Renders an individual device icon. Wraps the DeviceIcon component, and contains logic for what an icon should look like
 * based on the device document and which props to pass in to the DeviceIcon component
 */
export const DeviceMapEntityIcon = memo(
    ({
        deviceModel,
        onClickIcon,
        onHover,
        mapComponent,
        currentMapZoom,
        dimmed,
        highlighted,
        hidden,
    }: DeviceMapEntityIconProps) => {
        const iconSize = useIconSize({ mapComponent });
        const [deviceState, setDeviceState] = useState(() => makeIconState(deviceModel));

        useEffect(() => {
            setDeviceState(makeIconState(deviceModel));
        }, [deviceModel]);

        if (hidden) {
            return <></>;
        }

        // Keep expanded if inManualMode, has a warning, or is irrigating
        const shouldExpandBasedOnState = shouldExpand({
            model: deviceModel,
            warningToShow: deviceState.warnings.warningToShow,
            warningStatus: deviceState.warnings.status,
        });

        const { variant, iconGraphicConfig } = makeIconGraphicConfig({
            manualMode: deviceState.manualMode,
            deviceType: deviceModel.type,
        });

        return (
            <DeviceIcon
                tooltip={{
                    deviceModel,
                    enabled: true,
                }}
                dimmed={dimmed}
                size={iconSize}
                forceExpand={
                    highlighted ||
                    (!disabledByNotes(deviceModel.notes) &&
                        (currentMapZoom >= MAX_ZOOM_FOR_EXPANDED_ICONS || shouldExpandBasedOnState))
                }
                onClick={onClickIcon}
                onMouseEnter={(e) => onHover(e, true)}
                onMouseLeave={(e) => onHover(e, false)}
                colorParams={deviceState.colorParams}
                badgeParams={makeBadgeParams({ warningStatus: deviceState.warnings.status })}
                progressRingParams={makeProgressRingParams({ model: deviceModel })}
            >
                <IconGraphic
                    size={iconSize * iconGraphicSizeScaling[variant]}
                    color={deviceState.colorParams.iconColor}
                    {...iconGraphicConfig}
                />
            </DeviceIcon>
        );
    },
    (prevProps, nextProps) =>
        prevProps.deviceModel === nextProps.deviceModel &&
        prevProps.dimmed === nextProps.dimmed &&
        prevProps.highlighted === nextProps.highlighted &&
        prevProps.currentMapZoom === nextProps.currentMapZoom &&
        prevProps.hidden === nextProps.hidden,
);
