import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { DeviceConfig, ExternalLink, ExternalLinkConfigInfo, ThirdPartyDeviceConfig } from "verditypes";

import ExternalLinkTableRow from "./ExternalLinksRow";

interface ExternalLinksTableProps {
    device: any;
    onSave: (newLinks: Record<string, ExternalLink>) => Promise<void>;
}

function ExternalLinksTable({ device, onSave }: ExternalLinksTableProps) {
    const { enqueueSnackbar } = useSnackbar();

    // Is where the data of the External links will be saved to the DB
    const [externalLinks, setExternalLinks] = useState<Record<string, ExternalLink>>({});

    // Keeps track of the new row that is being added
    const [newRow, setNewRow] = useState<ExternalLink | null>(null);
    // Keeps track of the presetConfigLinks (sampleURL and name)
    const [presetConfigLinks, setPresetConfigLinks] = useState<Record<string, ExternalLinkConfigInfo>>({});

    useEffect(() => {
        // Grab the preconfigured external links associated with the device
        const primaryConfig =
            DeviceConfig.DeviceConfigurationsByType[
                device.type as keyof typeof DeviceConfig.DeviceConfigurationsByType
            ];
        const thirdPartyConfig =
            ThirdPartyDeviceConfig.ThirdPartyDeviceConfigurations[
                device.type as keyof typeof ThirdPartyDeviceConfig.ThirdPartyDeviceConfigurations
            ];
        const externalLinksConfig: Record<string, ExternalLinkConfigInfo> =
            primaryConfig?.externalLinksConfigInfo || thirdPartyConfig?.externalLinksConfigInfo || {};

        setPresetConfigLinks(externalLinksConfig);

        const savedLinks: Record<string, ExternalLink> = {};

        // Store all the available links in the saved links with an empty url
        Object.entries(externalLinksConfig).forEach(([k, v]) => {
            savedLinks[k] = { name: v.friendlyName, key: k, url: "" };
        });

        // retrieve all the saved external links
        Object.values(device?.externalLinks || {}).forEach((link) => {
            const { name, key, url } = link as ExternalLink;
            savedLinks[key] = { name, key, url };
        });

        setExternalLinks(savedLinks);
    }, [device]);

    const validateURL = (updatedLink: ExternalLink): boolean => {
        // Check for empty fields
        if (!updatedLink.name || !updatedLink.url) {
          enqueueSnackbar("An external link's name and URL cannot be empty", { variant: "error" });
          return false;
        }
      
        // Check for duplicate names
        const linkNameAlreadyExists = Object.entries(externalLinks).some(
          ([key, availableLink]) => key !== updatedLink.key && availableLink.name === updatedLink.name
        );
      
        if (linkNameAlreadyExists) {
          enqueueSnackbar("An external link with same name already exists", { variant: "error" });
          return false;
        }

      
        return true;
      };

    const saveExternalLinks = async (newLinks: Record<string, ExternalLink>): Promise<boolean> => {
        try {
          await onSave(newLinks);
          enqueueSnackbar("External links saved successfully", { variant: "success" });
          return true;
        } catch (error) {
          console.error("Failed to save external links:", error);
          enqueueSnackbar("Failed to save external links", { variant: "error" });
          return false;
        }
      };

    const handleSave = async (rowKey: string, updatedRow: ExternalLink, isNewRow: boolean): Promise<boolean> => {
        // Validate the link before saving
        if (!validateURL(updatedRow)) {
          return false;
        }
      
        // Create updated links object
        const updatedLinks = {
          ...externalLinks,
          [rowKey]: updatedRow
        };
      
        // Save the updated links
        const saveSuccess = await saveExternalLinks(updatedLinks);
        
        if (saveSuccess) {
          // Update state only after successful save
          setExternalLinks(updatedLinks);
          
          // Clear new row if this was a new entry
          if (isNewRow) {
            setNewRow(null);
          }
          
          return true;
        }
        
        return false;
      };

    const handleDelete = (key: string) => {
        // if the user wants to delete additional external links that are not preconfigured
        // deletes it and then saves it
        setExternalLinks((prev) => {
            const updated = { ...prev };
            delete updated[key];
            onSave(updated);
            return updated;
        });

    };

    const styles = {
        nameWidth: "25%",
        urlWidth: "35%",
        actionWidth: "40%",
        cellPadding: "4px",
        defaultStyles: {
            textAlign: "center" as const, // this makes the defaultStyles assignable to React.CSSProperties
            overflowWrap: "break-word" as const,
            verticalAlign: "middle" as const,
            wordBreak: "break-word" as const,
            whiteSpace: "normal" as const,
        },
        buttonStyles: { minWidth: 0, padding: "2px 4px", fontSize: "0.75rem" },
    };

    // Every row is a url, the first few rows that render are the preconfigured ones
    // if you are editing a row then it will show the editing options which are Save, Cancel and Delete (if this is a non-preconfigured link row)
    // if you aren't editing the row then it will show you: open in a new tab, copy, and edit
    // And at the bottom is an add new row button that will only let you add one link at a time.

    return (
        <div style={{ maxWidth: "100%", margin: "auto", padding: "1px" }}>
            <Typography variant={"subtitle2"} sx={{ mb: 2 }}>
                External Links [Admin-only]
            </Typography>

            <TableContainer component={Paper} sx={{ maxWidth: "100%", margin: "auto", mt: 2, p: 2 }}>
                <Table sx={{ tableLayout: "fixed" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: styles.nameWidth, textAlign: "center" }}>
                                <strong>Name</strong>
                            </TableCell>
                            <TableCell
                                sx={{
                                    width: styles.urlWidth,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textAlign: "center",
                                }}
                            >
                                <strong>URL</strong>
                            </TableCell>
                            <TableCell sx={{ width: styles.actionWidth, textAlign: "center" }}>
                                <strong>Actions</strong>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.values(externalLinks).map((externalLink) => (
                            <ExternalLinkTableRow
                                key={externalLink.key}
                                externalLink={externalLink}
                                isPresetLink={Object.keys(presetConfigLinks).some((key) => key === externalLink.key)}
                                presetLinkConfig={presetConfigLinks[externalLink.key]}
                                handleSave={handleSave}
                                onDelete={handleDelete}
                                styles={styles}
                            />
                        ))}
                        {/* This is where the added row will appear, special handling for the delete and save  */}
                        {newRow && (
                            <ExternalLinkTableRow
                                key={newRow.key}
                                externalLink={newRow}
                                isPresetLink={false}
                                handleSave={handleSave}
                                onDelete={() => setNewRow(null)}
                                styles={styles}
                                isNewRow={true}
                            />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Button
                variant={"contained"}
                color={"primary"}
                sx={{ mt: 2, display: "block", mx: "auto" }}
                onClick={() => {
                    const newKey = `additionalExternalLink_new_${Date.now()}`;
                    setNewRow({ name: "", url: "", key: newKey });
                }}
                disabled={newRow !== null}
            >
                Add New Link
            </Button>
        </div>
    );
}

export default ExternalLinksTable;
