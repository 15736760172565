import type MapEntityBase from "../mapManagement/mapEntities/MapEntityBase";

/**
 * Manual mode transitioning when target mode is different from the current mode
 */
export function isManualModeTransitioning({ model }: { model: MapEntityBase["model"] }) {
    const manualOverrideInfo = model?.sproutNetworkInfo?.manualOverrideInfo;
    if (manualOverrideInfo) {
        // If the device updated more recently, use the device's mode, otherwise the target is the remote mode
        const deviceUpdatedMostRecently =
            new Date(manualOverrideInfo.deviceOverrideSettingLastUpdated?.valueOf()).valueOf() >
            new Date(manualOverrideInfo.remoteOverrideSettingLastUpdated?.valueOf()).valueOf();
        const targetMode = deviceUpdatedMostRecently
            ? manualOverrideInfo?.deviceOverrideSetting
            : manualOverrideInfo?.remoteOverrideSetting;

        const currentMode = manualOverrideInfo?.deviceOverrideSetting;
        return {
            isTransitioning: targetMode !== currentMode,
            targetMode: targetMode as ManualModeOverrideSettings,
        };
    }
    return { isTransitioning: false, targetMode: null };
}

/**
 * Represents the possible manual mode override states
 * Named as "OverrideSettings" since thats how they are named in the DeviceModel
 * (eg. model.sproutNetworkInfo?.manualOverrideInfo?.RemoteOverrideSetting)
 */
export enum ManualModeOverrideSettings {
    NONE = "none", // Indicates that manual mode is not active
    OPEN = "open",
    CLOSE = "closed",
}

/**
 * Device is in manual mode (not necessarily manual mode irrigating) if manualOverrideEngaged is true
 */
export function isManualMode({ model }: { model: MapEntityBase["model"] }) {
    if (model.saplingNetworkInfo) {
        return model.saplingNetworkInfo?.valveState?.manualOverrideEngaged;
    }
    if (model.sproutNetworkInfo?.manualOverrideInfo) {
        return model.sproutNetworkInfo?.manualOverrideInfo?.deviceOverrideSetting !== ManualModeOverrideSettings.NONE;
    }
    return false;
}
