import { Stack, Typography } from "@mui/material";

import { SPACING, typographyOptions } from "../constants";
import {
    ImageContainer,
    LargeImage,
    MediumImage,
    SmallImage,
    StyledList,
    StyledListItem,
    TwoColumnLayout,
} from "../ProductUpdateComponents";
// Import media assets
import statusBadges from "./assets/all-badges.png";
import deviceIcons from "./assets/all-icons.png";
import closingValve from "./assets/closing_valve.gif";
import fullRing from "./assets/full_ring.png";
import groupingDemo from "./assets/grouping-demo.gif";
import smartDisplayDemo from "./assets/icon-expansion-demo.gif";
import openingValve from "./assets/opening_valve.gif";
import overviewDemo from "./assets/overview-demo.gif";
import partialFill from "./assets/partial_fill.png";
import scheduledIrrigation from "./assets/scheduled_irrigation.png";
import spinningRing from "./assets/spinning.gif";
import valveOpen from "./assets/valve_open.png";

export function DeviceMarkersProductUpdate() {
    return (
        <Stack sx={{ gap: SPACING.XLARGE }}>
            <section>
                <Typography sx={typographyOptions.pageTitle.sx} variant={typographyOptions.pageTitle.variant}>
                    Smarter Device Markers: See Your Farm at a Glance 🗺️
                </Typography>
                <ImageContainer>
                    <LargeImage src={overviewDemo} alt={"General marker demonstration"} />
                </ImageContainer>
                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    We're excited to share our latest improvement to the Verdi Dashboard: completely redesigned device
                    markers that make monitoring your farm easier and more intuitive.
                </Typography>

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    You've shared your feedback about our map, and we've been listening carefully. After lots of work
                    reimagining how these markers could be improved, we're thrilled to introduce improvements that
                    directly address pain points and make your day-to-day operations smoother and more intuitive.
                </Typography>

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    We've focused on what matters most—helping you quickly spot active irrigations, potential issues,
                    and device statuses without cluttering your view.
                </Typography>
            </section>

            <section>
                <Typography sx={typographyOptions.title.sx} variant={typographyOptions.title.variant}>
                    All-New Device Markers 🎨
                </Typography>

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    We've given your devices a fresh new look on the map! You'll notice:
                </Typography>

                <StyledList>
                    <StyledListItem>
                        Redesigned icons for Valves and Sensors for better visibility across the Dashboard
                    </StyledListItem>
                    <StyledListItem>
                        Devices in Manual Mode are now clearly indicated with an{" "}
                        <code style={{ marginLeft: "2px" }}>M</code>
                    </StyledListItem>
                </StyledList>

                <ImageContainer>
                    <MediumImage src={deviceIcons} alt={"New device icons showcase"} />
                </ImageContainer>
            </section>

            <section>
                <Typography sx={typographyOptions.title.sx} variant={typographyOptions.title.variant}>
                    Intelligent Device Grouping 📍
                </Typography>

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    As you zoom in and out, nearby devices automatically group together to give you a clearer overview
                    and reduce clutter.
                </Typography>

                <StyledList>
                    <StyledListItem>Groups show their total device count in their centre</StyledListItem>
                    <StyledListItem>
                        Groups show a status badge if any of their devices have a status badge
                        <StyledList>
                            <StyledListItem>
                                Status badge colours correspond to the highest priority status of a contained device
                            </StyledListItem>
                        </StyledList>
                    </StyledListItem>
                    <StyledListItem>
                        Groups show a full irrigation status ring if any of their devices have any irrigation status
                        ring
                    </StyledListItem>
                    <StyledListItem>Click on a group to view a list of all the devices it contains</StyledListItem>
                </StyledList>

                <ImageContainer>
                    <LargeImage src={groupingDemo} alt={"Device grouping demonstration"} />
                </ImageContainer>
            </section>

            <section>
                <Typography sx={typographyOptions.title.sx} variant={typographyOptions.title.variant}>
                    Smarter Display for Better Visibility 🎯
                </Typography>

                <StyledList>
                    <StyledListItem>
                        Inactive devices appear as collapsed dot markers to reduce map clutter. Hover over them to
                        expand and click as usual to view more
                    </StyledListItem>
                    <StyledListItem>
                        Devices that require your attention (i.e. irrigating or displaying a warning) automatically show
                        as full markers
                    </StyledListItem>
                </StyledList>

                <ImageContainer>
                    <LargeImage src={smartDisplayDemo} alt={"Smart display demonstration"} />
                </ImageContainer>
            </section>

            <section>
                <Typography sx={typographyOptions.title.sx} variant={typographyOptions.title.variant}>
                    Valve and Irrigation Status 💧
                </Typography>

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    A device with an open valve will appear with a blue ring showing its irrigation status.
                </Typography>

                <TwoColumnLayout sx={{ mt: 2 }}>
                    <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                        <strong>Spinning</strong>: A device is either entering Manual mode, or already in Manual mode,
                        and is transitioning from open to closed or vice versa.
                    </Typography>
                    <SmallImage src={spinningRing} alt={"Spinning ring animation"} />
                </TwoColumnLayout>

                <TwoColumnLayout>
                    <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                        <strong>Partial fill</strong>: Shows the progress of a scheduled irrigation.
                    </Typography>
                    <SmallImage src={partialFill} alt={"Partial fill indicator"} />
                </TwoColumnLayout>

                <TwoColumnLayout>
                    <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                        <strong>Full ring</strong>: Valve opened but not due to a scheduled irrigation (e.g. device is
                        in manual mode).
                    </Typography>
                    <SmallImage src={fullRing} alt={"Full ring indicator"} />
                </TwoColumnLayout>
            </section>

            <section>
                <Typography sx={typographyOptions.title.sx} variant={typographyOptions.title.variant}>
                    Status Badges 🚦
                </Typography>

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    We've made it much easier to quickly spot which devices require your attention with status badges.
                </Typography>
                <ImageContainer>
                    <MediumImage src={statusBadges} alt={"Status badge examples"} />
                </ImageContainer>
                <TwoColumnLayout>
                    <div>
                        <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                            <strong>🟠 Orange → Warnings</strong>
                        </Typography>
                        <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                            The device needs attention but is still operational (with potentially reduced performance).
                        </Typography>
                        <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                            Examples: Low battery, Weak signal strength
                        </Typography>
                    </div>
                    <div>
                        <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                            <strong>🔴 Red → Errors</strong>
                        </Typography>
                        <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                            The device needs immediate action in order to restore functionality.
                        </Typography>
                        <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                            Example: Device offline
                        </Typography>
                    </div>
                </TwoColumnLayout>
            </section>

            <section style={{ display: "flex", flexDirection: "column", gap: SPACING.MEDIUM }}>
                <div>
                    <Typography sx={typographyOptions.title.sx} variant={typographyOptions.title.variant}>
                        New Marker Tooltips ℹ️
                    </Typography>

                    <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                        Hovering over a device marker will open a tooltip containing an overview of your device's status
                        and valve state (if applicable).
                    </Typography>
                </div>

                <div>
                    <Typography sx={typographyOptions.subtitle.sx} variant={typographyOptions.subtitle.variant}>
                        Scheduled irrigation in progress
                    </Typography>
                    <ImageContainer>
                        <MediumImage src={scheduledIrrigation} alt={"Tooltip showing scheduled irrigation status"} />
                    </ImageContainer>
                </div>

                <div>
                    <Typography sx={typographyOptions.subtitle.sx} variant={typographyOptions.subtitle.variant}>
                        Non-scheduled open valve (e.g. in Manual mode)
                    </Typography>
                    <ImageContainer>
                        <MediumImage src={valveOpen} alt={"Tooltip showing manual mode status"} />
                    </ImageContainer>
                </div>

                <div>
                    <Typography sx={typographyOptions.subtitle.sx} variant={typographyOptions.subtitle.variant}>
                        Non-scheduled valve transitions
                    </Typography>
                    <Stack
                        direction={"row"}
                        gap={SPACING.MEDIUM}
                        alignItems={"center"}
                        overflow={"hidden"}
                        flexWrap={"wrap"}
                        justifyContent={"center"}
                    >
                        <MediumImage src={openingValve} alt={"Animation showing valve opening transition"} />
                        <MediumImage src={closingValve} alt={"Animation showing valve closing transition"} />
                    </Stack>
                </div>
            </section>

            <section>
                <Typography sx={typographyOptions.title.sx} variant={typographyOptions.title.variant}>
                    What does this mean for your operations? 💪
                </Typography>

                <StyledList>
                    <StyledListItem>
                        <strong>Faster Issue Detection</strong>: Critical alerts and warnings stand out immediately
                    </StyledListItem>
                    <StyledListItem>
                        <strong>Cleaner View</strong>: Focus on active operations while maintaining visibility of all
                        devices
                    </StyledListItem>
                    <StyledListItem>
                        <strong>Easier Navigation</strong>: Smart grouping helps manage farms and fields of any size
                    </StyledListItem>
                </StyledList>
            </section>

            <section>
                <Typography sx={typographyOptions.title.sx} variant={typographyOptions.title.variant}>
                    Let us know what you think! 💭
                </Typography>

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    As always, we want to make sure these changes work for you and improve your growing experience. We'd
                    love to get your feedback on ways we can improve the design and any issues you run into.
                </Typography>

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    Have any feedback or questions?
                </Typography>

                <StyledList>
                    <StyledListItem>Use the feedback form on the Verdi Dashboard</StyledListItem>
                    <StyledListItem>
                        Email us at <a href={"mailto:support@verdiag.com"}>support@verdiag.com</a>
                    </StyledListItem>
                    <StyledListItem>
                        Call or text us at <a href={"tel:+1-855-945-5628"}>1-855-945-5628</a>
                    </StyledListItem>
                </StyledList>

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    We really hope you love this new update and we look forward to sharing more exciting releases soon!
                </Typography>

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    Happy growing! 🌱
                </Typography>

                <Typography sx={typographyOptions.body.sx} variant={typographyOptions.body.variant}>
                    The Verdi Team
                </Typography>
            </section>
        </Stack>
    );
}
