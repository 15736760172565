import { Box, Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers";

import { DateRangeButtons } from "./DateRangeButtons";

interface DateRangeSelectorProps {
    startDate: Date;
    setStartDate: (newStartDate: Date) => void;
    endDate: Date;
    setEndDate: (newEndDate: Date) => void;
    presetDateButtonOptions: { label: string; getDates(): { startDate: Date; endDate: Date } }[] | undefined;
    showDescriptionText:boolean;
    useStartOfDayEndOfDay?:boolean;
}

export function DateRangeSelector({ startDate, setStartDate, endDate, setEndDate, presetDateButtonOptions, showDescriptionText = false, useStartOfDayEndOfDay=false}: DateRangeSelectorProps) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (
        <Box>
            {showDescriptionText&&(<><Typography variant={"h4"}>Date Range</Typography>
            <Typography variant={"subtitle1"}>
                Dates are <strong>inclusive</strong> and in your local timezone.
            </Typography>
            <Typography variant={"subtitle1"}>
                Local timezone: <i>{`${timeZone}`}</i>
            </Typography>
            </>
            )}

            <Box sx={{ mt: 6 }}>
                <DateRangeButtons
                    onClick={(newStartDate, newEndDate) => {
                        setStartDate(newStartDate);
                        setEndDate(newEndDate);
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    sx={{ mb: 6 }}
                    presetDateButtonOptions={presetDateButtonOptions}
                />

                <Stack direction={"row"} spacing={4}>
                    <DatePicker
                        label={"Start Date"}
                        renderInput={(params) => <TextField {...params} />}
                        value={startDate}
                        onChange={(newStartDate: Date | null) => {
                            if(useStartOfDayEndOfDay){
                                newStartDate?.setHours(0,0,0,0);
                            }
                            if (newStartDate) {
                                setStartDate(newStartDate);
                            }
                        }}
                        maxDate={endDate}
                    />
                    <DatePicker
                        label={"End Date"}
                        renderInput={(params) => <TextField {...params} />}
                        value={endDate}
                        onChange={(newEndDate: Date | null) => {
                            if(useStartOfDayEndOfDay){
                                newEndDate?.setHours(23,59,59,999);
                            }
                            if (newEndDate) {
                                setEndDate(newEndDate);
                            }
                        }}
                        minDate={startDate}
                    />
                </Stack>
            </Box>
        </Box>
    );
}
