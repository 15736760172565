import { Polygon } from "react-leaflet";
import { MasterIndex, Models } from "verdiapi";

import type MapEntityBase from "../../mapEntities/MapEntityBase";

interface ZoneHighlightPolygonProps {
    mapEntity?: MapEntityBase;
}

export function ZoneHighlightPolygon({ mapEntity }: ZoneHighlightPolygonProps) {
    if (!mapEntity) {
        return null;
    }

    const { model } = mapEntity;
    const connectedZones =
        model?.connectedZones ?? model?.connectedZoneIDs?.map((id: string) => MasterIndex.zone.byID[id]);
    return (
        <div key={`${mapEntity?.id}-highlight-polygon`}>
            {connectedZones?.map((zone: Models.Zone) => (
                <Polygon
                    key={`${zone?.id}-highlight-polygon`}
                    positions={zone.polygon}
                    pathOptions={{
                        color: "#c4dd98",
                        weight: 3,
                        fillOpacity: 0.2,
                    }}
                />
            ))}
        </div>
    );
}
