// Keys for localstorage
export enum LocalStorageKey {
    PRODUCT_UPDATE_DISMISSED = "product-update-dismissed",
    PRODUCT_UPDATE_VERSION = "product-update-version",
}

// Get value from localStorage
const getValue = (key: LocalStorageKey): unknown | undefined => {
    try {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : undefined;
    } catch (error) {
        console.error(`Error reading localStorage key "${key}":`, error);
        return undefined;
    }
};

// Set value in state and localStorage
const setValue = (key: LocalStorageKey, value: unknown) => {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error(`Error setting localStorage key "${key}":`, error);
    }
};

// Clear value from localStorage
const clear = (key: LocalStorageKey) => {
    try {
        window.localStorage.removeItem(key);
    } catch (error) {
        console.error(`Error clearing localStorage key "${key}":`, error);
    }
};

export { setValue, getValue, clear };
