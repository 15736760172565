import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

export default function DraginoGatewayIcon(props) {
    // Dragino Gateway Icon (DL0S8N)
    return (
        <SvgIcon viewBox={"0 0 25 24"} {...props}>
            <path
                fill={"#31332E"}
                d={
                    "M13.9419 20.895C14.002 21.255 13.8067 21.6 13.4761 21.72L13.2508 21.81C12.8151 21.975 12.3644 22.05 11.8987 22.05C11.433 22.05 10.9822 21.975 10.5465 21.795L10.3362 21.72C10.0057 21.6 9.8104 21.255 9.87049 20.895L11.1325 13.23C10.7268 12.975 10.4564 12.525 10.4564 12C10.4564 11.205 11.1174 10.56 11.9137 10.56C12.7099 10.56 13.356 11.205 13.356 12C13.356 12.525 13.0855 12.975 12.6799 13.23L13.9419 20.895Z"
                }
            />
            <path
                fill={"#31332E"}
                d={
                    "M8.72868 15.3149C8.57844 15.4349 8.41317 15.4949 8.24791 15.4949C8.02256 15.4949 7.81225 15.4049 7.66201 15.2249C6.91083 14.3249 6.49017 13.1699 6.49017 11.9699C6.49017 11.0249 6.74557 10.0949 7.24135 9.28488C7.46671 8.93988 7.93244 8.83488 8.27799 9.04488C8.63855 9.25488 8.74369 9.71988 8.53336 10.0799C8.1728 10.6499 7.99253 11.2949 7.99253 11.9699C7.99253 12.8249 8.27796 13.6349 8.81881 14.2649C9.07422 14.5799 9.04417 15.0449 8.72868 15.3149Z"
                }
            />
            <path
                fill={"#31332E"}
                d={
                    "M14.9485 8.27988C14.7983 8.47488 14.573 8.59488 14.3476 8.59488C14.1974 8.59488 14.0321 8.54988 13.8969 8.44488C13.6415 8.26488 13.371 8.11488 13.0555 8.00988C12.3344 7.73988 11.463 7.73988 10.6968 8.00988C10.4414 8.11488 10.186 8.24988 9.96069 8.39988C9.61515 8.63988 9.14939 8.56488 8.90902 8.21988C8.66864 7.87488 8.75879 7.40988 9.10433 7.16988C9.43485 6.94488 9.79544 6.74988 10.156 6.61488C11.2678 6.19488 12.5297 6.19488 13.5814 6.58488C14.002 6.73488 14.4077 6.94488 14.7833 7.21488C15.1138 7.45488 15.1889 7.91988 14.9485 8.26488V8.27988Z"
                }
            />
            <path
                fill={"#31332E"}
                d={
                    "M17.3373 11.9849C17.3373 13.1999 16.9016 14.3849 16.1054 15.2999C15.9552 15.4649 15.7448 15.5549 15.5345 15.5549C15.3542 15.5549 15.189 15.4949 15.0387 15.3749C14.7232 15.1049 14.6932 14.6249 14.9636 14.3099C15.5345 13.6649 15.835 12.8549 15.835 11.9849C15.835 11.2949 15.6397 10.6349 15.2791 10.0499C15.0538 9.70488 15.1589 9.23988 15.5045 9.01488C15.85 8.78988 16.3157 8.89488 16.5411 9.23988C17.0669 10.0649 17.3373 11.0099 17.3373 11.9849Z"
                }
            />
            <path
                fill={"#31332E"}
                d={
                    "M20.5824 11.9849C20.5824 14.7599 19.2002 17.3549 16.8865 18.9149C16.7513 18.9899 16.6011 19.0349 16.4659 19.0349C16.2255 19.0349 15.9852 18.9149 15.8349 18.7049C15.6096 18.3599 15.6997 17.8949 16.0452 17.6699C17.9382 16.3949 19.08 14.2649 19.08 11.9849C19.08 10.4699 18.5992 9.02994 17.6828 7.82994C17.4274 7.51494 17.4875 7.03494 17.8181 6.77994C18.1486 6.52494 18.6143 6.59994 18.8697 6.92994C19.9964 8.38494 20.5824 10.1399 20.5824 11.9849Z"
                }
            />
            <path
                fill={"#31332E"}
                d={
                    "M17.1119 6.1049C16.9616 6.2849 16.7513 6.3749 16.541 6.3749C16.3757 6.3749 16.1954 6.3149 16.0602 6.1949C15.0086 5.3399 13.7315 4.8299 12.3794 4.7399C12.1691 4.7099 12.0489 4.7099 11.9137 4.7099C11.7484 4.7099 11.5982 4.7099 11.4479 4.7249C10.0507 4.8449 8.78879 5.3399 7.76719 6.1949C7.43667 6.4649 6.9709 6.4199 6.70048 6.1049C6.44507 5.7749 6.49016 5.3099 6.80565 5.0399C8.08266 3.9899 9.61505 3.3749 11.2526 3.2399C11.8536 3.1949 12.139 3.1799 12.5447 3.2399C14.1522 3.3449 15.7297 3.9749 17.0217 5.0399C17.3372 5.3099 17.3823 5.7749 17.1119 6.1049Z"
                }
            />
            <path
                fill={"#31332E"}
                d={
                    "M7.9325 18.69C7.78226 18.915 7.54188 19.02 7.31653 19.02C7.16629 19.02 7.01609 18.975 6.88088 18.9C4.59729 17.34 3.2301 14.745 3.2301 11.97C3.2301 10.08 3.84607 8.29496 5.01792 6.79496C5.27332 6.47996 5.73907 6.41996 6.06959 6.67496C6.40011 6.92996 6.44516 7.39496 6.18976 7.72496C5.22824 8.95496 4.73246 10.41 4.73246 11.97C4.73246 14.25 5.84421 16.38 7.73719 17.655C8.08273 17.88 8.15785 18.36 7.9325 18.69Z"
                }
            />
        </SvgIcon>
    );
}
