import { StateCreator } from "zustand";

import MapEntityBase from "../services/mapManagement/mapEntities/MapEntityBase";

export interface CurrentSlice {
    selectedMapEntity: MapEntityBase | null;
    setSelectedMapEntity: (entity: MapEntityBase | null) => void;
}

export const createCurrentSlice: StateCreator<CurrentSlice, [], [], CurrentSlice> = (set) => ({
    selectedMapEntity: null,
    setSelectedMapEntity: (entity: MapEntityBase | null) => set({ selectedMapEntity: entity }),
});
