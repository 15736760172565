import { Avatar, Box, CircularProgress } from "@mui/material";
import { forwardRef, ReactNode } from "react";

import { dimmedIconStyles, iconColorMapping } from "../constants";
import { ICON_VARIANT, SHADOW_STYLE, SharedIconProps } from "../types";
import { Badge } from "./Badge";

const PROGRESS_SIZE_OFFSET = 7;
const DEFAULT_BADGE_SIZE = 14;
export type FullIconProps = SharedIconProps & {
    children?: ReactNode;
    dimmed?: boolean;
    hoverEffect?: boolean;
};

/**
 * FullIcon represents a full, expanded icon. Provides the actual presentation and layout
 * of the "expanded" icon. Composes the badge and progress ring components
 */
export const FullIcon = forwardRef<HTMLElement, FullIconProps>(
    (
        {
            onClick,
            progressRingParams,
            size = 35,
            badgeParams,
            colorParams = iconColorMapping[ICON_VARIANT.VALVE],
            children,
            dimmed = false,
            hoverEffect = true,
        },
        ref,
    ) => (
        <Box
            ref={ref}
            sx={{
                position: "relative",
                cursor: "pointer",
                width: size + (progressRingParams?.progressRing ? PROGRESS_SIZE_OFFSET : 0),
                height: size + (progressRingParams?.progressRing ? PROGRESS_SIZE_OFFSET : 0),
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: dimmed ? dimmedIconStyles.opacity : 1,
                filter: dimmed ? dimmedIconStyles.filter : undefined,
            }}
            onClick={onClick}
        >
            <Avatar
                sx={{
                    "position": "relative",
                    "cursor": "pointer",
                    "display": "flex",
                    "justifyContent": "center",
                    "alignItems": "center",
                    "boxShadow": `0px -1px 1px 1px ${colorParams.shadow} inset,
                        0px 1px 1px 1px #FFFFFF inset,
                        ${
                            colorParams.shadowStyle === SHADOW_STYLE.SPARSE
                                ? `0px 1px 1px 1px rgba(0,0,0,0.25),
                                0px 0.2px 0.5px 0px rgba(0,0,0,0.5)`
                                : `0px 1px 1px 1px rgba(0,0,0,0.25),
                                0px 4px 4px 0px rgba(0,0,0,0.25)`
                        }`,
                    "width": size,
                    "height": size,
                    "zIndex": 1,
                    "overflow": "visible",
                    "backgroundColor": colorParams.iconBackground,
                    "border": colorParams.border ? `1px solid ${colorParams.border}` : undefined,
                    "transition": "all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)",
                    ".MuiBox-root:hover &": hoverEffect
                        ? {
                              boxShadow: `
                            0px -1px 1px 1px ${colorParams.shadow} inset,
                            0px 1px 1px 1px #FFFFFF inset,
                            0 1px 3px rgba(0,0,0,0.12),
                            0 1px 2px rgba(0,0,0,0.24),
                            0 0 ${progressRingParams?.progressRing ? 18 : 14}px rgba(255,255,255,0.35),
                            0 0 ${progressRingParams?.progressRing ? 14 : 10}px rgba(255,255,255,0.25),
                            0 0 ${progressRingParams?.progressRing ? 10 : 6}px rgba(255,255,255,0.15)
                        `,
                          }
                        : undefined,
                }}
            >
                {children}
                {badgeParams?.badge && (
                    <Badge
                        size={badgeParams?.badgeOptions?.size || DEFAULT_BADGE_SIZE}
                        bgColor={badgeParams?.badgeOptions?.bgColor || "white"}
                        borderColor={badgeParams?.badgeOptions?.borderColor || "white"}
                        sx={{
                            position: "absolute",
                            top: "-7px",
                            right: "-4px",
                            zIndex: 250, // Ensure badge is above the icon
                            ...badgeParams?.badgeOptions?.sx,
                        }}
                    />
                )}
            </Avatar>
            {progressRingParams?.progressRing && (
                <CircularProgress
                    size={size + PROGRESS_SIZE_OFFSET}
                    thickness={4}
                    variant={progressRingParams.progressRingOptions.spinning ? "indeterminate" : "determinate"}
                    value={
                        progressRingParams.progressRingOptions.spinning
                            ? undefined
                            : progressRingParams.progressRingOptions.percentage
                    }
                    sx={{
                        color: "#0094FF",
                        position: "absolute",
                        boxShadow: "inset 0 0 1px 3.6px #fff",
                        borderRadius: "50%",
                    }}
                />
            )}
        </Box>
    ),
);
