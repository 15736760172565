import { ToggleButtonGroup } from "@mui/lab";
import { Box, ToggleButton, Typography } from "@mui/material";
import ReactECharts from "echarts-for-react";
import { useSnackbar } from "notistack";
import React from "react";
import { DateRangeSelector } from "src/pages/DataExport/components/DateRangeSelector";
import { ThirdPartyDeviceBase } from "verdiapi/dist/Models/Devices/ThirdPartyDeviceBase";
import { HistoricalDataBase } from "verdiapi/dist/Models/HistoricalData/HistoricalDataBase";
import { DataTypeSpecifier } from "verditypes";
import { dayMs, hourMs } from "verditypes/dist/timeConstants";

import { NumericFieldWithUnit } from "../../generic/NumericFieldWithUnit/NumericFieldWithUnit";
import { useMultiDepthDataProcessing } from "./MultiDepthDataProcessing";
import { SentekSoilMoistureGraphDataStream } from "./types";
import { useMultiDepthSoilMoistureGraphOptions } from "./useMultiDepthSoilMoistureGraphOptions";

const durationByTimeRangeOption = {
    month: dayMs * 30,
    week: dayMs * 7,
    day: dayMs,
    hour: hourMs,
} as const;

export function MultiDepthSoilMoistureGraph({
    device,
    moistureDataType = "moisture_drillAndDrop",
    salinityDataType = "salinity_drillAndDrop",
    temperatureDataType = "soilTemperature",
    graphSubtitle = "From Sentek Probe",
    graphTitle = "Multi Depth Probe Data",
}: {
    device: ThirdPartyDeviceBase;
    moistureDataType?: DataTypeSpecifier;
    salinityDataType?: DataTypeSpecifier;
    temperatureDataType?: DataTypeSpecifier;
    graphSubtitle?: string;
    graphTitle?: string;
}) {
    const relevantDataTypes: DataTypeSpecifier[] = [
        "moisture",
        moistureDataType,
        salinityDataType,
        temperatureDataType,
    ];

    const { enqueueSnackbar } = useSnackbar();

    const [latestDateToGet, setLatestDateToGet] = React.useState(new Date(Date.now()));
    const [oldestDateToGet, setOldestDataToGet] = React.useState(new Date(Date.now() - dayMs * 5));

    type TimeRangeOption = keyof typeof durationByTimeRangeOption;

    const [timeRangeOption, setTimeRangeOption] = React.useState<TimeRangeOption | null>("week");
    const [stackDepths, setStackDepths] = React.useState<boolean>(true);

    // means that when we say to go back 1 week or 1 day, we are doing that from a constant point to eliminate performance
    // problems trying to fetch an extra 3 minutes of data a week ago.
    const timeToCalculateTimeRangeFrom = React.useMemo(
        () => new Date(Date.now()),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [new Date(Date.now()).getHours()],
    );

    React.useEffect(() => {
        if (!timeRangeOption) return;
        setOldestDataToGet(new Date(Date.now() - durationByTimeRangeOption[timeRangeOption]));
    }, [timeRangeOption, timeToCalculateTimeRangeFrom]);

    const [dataTypesToShow, setDataTypesToShow] = React.useState(relevantDataTypes);
    const [splitMode, setSplitMode] = React.useState("byDataType");
    const [numDatapoints, setNumDatapoints] = React.useState(0);

    const [onsetOfStress, setOnsetOfStress] = React.useState(
        device.deviceTypeSpecificInformation.onsetOfStress || undefined,
    );
    const [fieldCapacity, setFieldCapacity] = React.useState(
        device.deviceTypeSpecificInformation.saturation || undefined,
    );
    // const [refillPoint, setRefillPoint] = React.useState(device.deviceTypeSpecificInformation.refillPoint || undefined);

    const historicalDatabase = React.useMemo(
        () =>
            // @ts-ignore
            new HistoricalDataBase([device], relevantDataTypes, 1000, {
                worryAboutPosition: true,
                worryAboutRelevantZones: false,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [relevantDataTypes.toString()],
    );

    const { allDataSeriesArray, dataSeriesByID } = useMultiDepthDataProcessing({
        numDatapoints,
        historicalDatabase,
    });

    // fetches data when needed
    React.useEffect(() => {
        const endTimeOfSample = new Date(latestDateToGet.valueOf());
        endTimeOfSample.setMinutes(60);

        // Cancel any in-progress fetches
        let isCancelled = false;

        historicalDatabase
            .getData(new Date(oldestDateToGet.valueOf()), new Date(endTimeOfSample.valueOf()))
            .then(() => {
                if (!isCancelled) {
                    setNumDatapoints(historicalDatabase.data.value.length);
                }
            })
            .catch((e) => {
                if (!isCancelled) {
                    console.warn("graph error", e);
                    enqueueSnackbar("failed to load data for graph", { variant: "error" });
                }
            })
            .finally(() => {
                isCancelled = true;
            });

        return () => {
            isCancelled = true;
        };
    }, [enqueueSnackbar, historicalDatabase, latestDateToGet, oldestDateToGet]);

    // @ts-ignore
    const depths: number[] = [...new Set(allDataSeriesArray.map((ds) => ds.depth))]
        .filter<number>(
            // @ts-ignore
            (depth) => !Number.isNaN(depth),
        )
        .sort((a, b) => a - b);

    /**
     * Only show data types that actually have related data
     * @type {Array<DataTypeSpecifier>}
     */
    const dataTypesToShowOnGraph = dataTypesToShow
        .filter((dt) => allDataSeriesArray.some((ds) => ds.dataType === dt))
        .filter((dt) => relevantDataTypes.indexOf(dt) !== -1);
    dataTypesToShowOnGraph.sort((a, b) => relevantDataTypes.indexOf(a) - relevantDataTypes.indexOf(b));

    const { getXAxes, getXAxisIndexForDataStream, getYAxes, getYAxisIndexForDataStream, getGrids, numberOfGrids } =
        useMultiDepthSoilMoistureGraphOptions({
            seperateDataTypes: splitMode === "byDataType",
            seperateDepths: splitMode === "byDepth",
            useBoostToSeperateDepths: stackDepths && !(splitMode === "byDepth"),
            dataTypes: dataTypesToShowOnGraph,
            depths: depths,
        });

    const xAxes = getXAxes();

    const summedDataSeries: undefined | SentekSoilMoistureGraphDataStream = allDataSeriesArray.find(
        (ds) => ds.dataType === "moisture",
    );

    /**
     *
     * @type {Array<never>}
     */
    const coloredAreaDataSeries: SentekSoilMoistureGraphDataStream[] = [];
    if (summedDataSeries && onsetOfStress > 0) {
        coloredAreaDataSeries.push({
            ...summedDataSeries,
            id: "onsetOfStressThreshold",
            data: [
                {
                    dateValue: oldestDateToGet.valueOf(),
                    value: [oldestDateToGet.valueOf(), onsetOfStress],
                },
                {
                    dateValue: latestDateToGet.valueOf(),
                    value: [latestDateToGet.valueOf(), onsetOfStress],
                },
            ],
            areaStyle: {
                color: "#E55651", // Red
                origin: "start", 
                opacity: 0.15,
            },
            lineStyle: {
                color: "#E55651", // Red
                opacity: 0.2,
            },
            z: 2,
        });
    }
    if (summedDataSeries && fieldCapacity > onsetOfStress && fieldCapacity > 0 && onsetOfStress > 0) {
        coloredAreaDataSeries.push({
            ...summedDataSeries,
            id: "saturationThreshold",
            data: [
                {
                    dateValue: oldestDateToGet.valueOf(),
                    value: [oldestDateToGet.valueOf(), onsetOfStress],
                },
                {
                    dateValue: latestDateToGet.valueOf(),
                    value: [latestDateToGet.valueOf(), onsetOfStress],
                },
            ],
            areaStyle: {
                color: "#76e551", // Green
                origin: fieldCapacity,
                opacity: 0.15,
            },
            lineStyle: {
                color: "#76e551",
                opacity: 0,
            },
            z: 2,
        });
    }

    if (summedDataSeries && fieldCapacity > 0) {
        coloredAreaDataSeries.push({
            ...summedDataSeries,
            id: "aboveSaturation",
            data: [
                {
                    dateValue: oldestDateToGet.valueOf(),
                    value: [oldestDateToGet.valueOf(), fieldCapacity],
                },
                {
                    dateValue: latestDateToGet.valueOf(),
                    value: [latestDateToGet.valueOf(), fieldCapacity],
                },
            ],
            areaStyle: {
                color: "#4286f4", // Blue
                origin: "end",
                opacity: 0.15,
            },
            lineStyle: {
                color: "#4286f4", // Blue
                opacity: 0.2,
            },
            z: 99,
        });
    }
    const allSeries = [...allDataSeriesArray, ...coloredAreaDataSeries]
        .filter((dataSeries) => dataTypesToShow.includes(dataSeries.dataType as unknown as DataTypeSpecifier))
        .map((ds) => {
            ds.yAxisIndex = getYAxisIndexForDataStream(ds);
            ds.xAxisIndex = getXAxisIndexForDataStream(ds);

            return ds;
        })
        ?.sort((a, b) => `${a.yAxisIndex} ${a.depth}`.localeCompare(`${b.yAxisIndex} ${b.depth}`));

    // Create a filtered view of the series for display
    const filteredSeries = React.useMemo(
        () =>
            allSeries.map((series) => ({
                ...series,
                data: series.data.filter(
                    (point: any) =>
                        point.dateValue >= oldestDateToGet.valueOf() && point.dateValue <= latestDateToGet.valueOf(),
                ),
            })),
        [allSeries, oldestDateToGet, latestDateToGet],
    );

    const options = React.useMemo(
        () => ({
            title: {
                text: graphTitle,
                subtext: graphSubtitle,
                left: "center",
            },
            tooltip: {
                trigger: "axis",
                valueFormatter: (value: number) => value?.toFixed(2) ?? "N/A",
                axisPointer: {
                    type: "cross",
                    snap: false,
                },
            },
            toolbox: {
                feature: {
                    dataZoom: {
                        yAxisIndex: "none",
                        bottom:10,
                    },
                    restore: {},
                    saveAsImage: {},
                },
            },

            xAxis: getXAxes().map((axis) => ({
                ...axis,
                min: oldestDateToGet.valueOf(),
                max: latestDateToGet.valueOf(),
                type: "time",
                alignTicks: true,
            })),
            dataZoom: [
                {
                    // The first dataZoom component - manages showing only the specified time range
                    xAxisIndex: xAxes.map((_v, i) => i),
                    startValue: oldestDateToGet.valueOf(),
                    endValue: latestDateToGet.valueOf(),

                    // controls the first and the third xAxis
                },
            ],
            grid: getGrids(),
            yAxis: getYAxes(dataSeriesByID),
            series: filteredSeries,
        }),
        [
            graphTitle,
            graphSubtitle,
            getXAxes,
            xAxes,
            oldestDateToGet,
            latestDateToGet,
            getGrids,
            getYAxes,
            dataSeriesByID,
            filteredSeries,
        ],
    );

    const noDataFoundForGivenTimeRange = React.useMemo(() => {
        if (allSeries.length === 0) return true;

        const timeRange = new Date(oldestDateToGet.valueOf());

        const endDate = new Date(latestDateToGet.valueOf());

        const dataPointsInTimeRange = allSeries.reduce(
            (count, series) =>
                count +
                series.data.filter(
                    (d: any) =>
                        d.dateValue <= endDate.valueOf() &&
                        d.dateValue >= timeRange.valueOf() &&
                        d.value[1] !== undefined,
                ).length,
            0,
        );

        // We previously added in 6 points for the summed moisture data thresholds (refill, onset of stress, etc) for coloring
        const threshold = 7;
        return dataPointsInTimeRange < threshold;
    }, [allSeries, latestDateToGet, oldestDateToGet]);

    const now = React.useMemo(
        () => new Date(Date.now()),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [new Date(Date.now()).getHours()],
    );
    const eodToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

    return (
        <div>
            <Box display={"flex"} flexDirection={"column"} gap={2} marginTop={2} marginBottom={2}>
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={2}
                    flexWrap={"wrap"}
                    width={"100%"}
                >
                    <ToggleButtonGroup
                        sx={{ padding: 2 }}
                        value={dataTypesToShow}
                        onChange={(_e, v) => {
                            setDataTypesToShow(v);
                        }}
                        aria-label={"text formatting"}
                    >
                        <ToggleButton value={moistureDataType} aria-label={"bold"}>
                            Moisture
                        </ToggleButton>
                        <ToggleButton value={temperatureDataType} aria-label={"italic"}>
                            Temperature
                        </ToggleButton>
                        <ToggleButton value={salinityDataType} aria-label={"underlined"}>
                            Salinity
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <ToggleButtonGroup
                        sx={{ padding: 2 }}
                        value={splitMode}
                        exclusive={true}
                        onChange={(_e, v) => {
                            setSplitMode(v);
                        }}
                    >
                        <ToggleButton value={"byDepth"} aria-label={"bold"}>
                            Split By Depth
                        </ToggleButton>
                        <ToggleButton value={"byDataType"} aria-label={"italic"}>
                            Split By Data Type
                        </ToggleButton>
                        <ToggleButton value={"byNothing"} aria-label={"underlined"}>
                            Combine
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <ToggleButtonGroup
                        sx={{ padding: 2 }}
                        value={stackDepths}
                        exclusive={true}
                        onChange={(_e, v: boolean) => {
                            setStackDepths(v);
                        }}
                    >
                        <ToggleButton value={true} aria-label={"bold"}>
                            {stackDepths ? "Unstack Trends" : "Stack Trends"}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>

                <Box
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={2}
                    marginTop={2}
                    flexWrap={"wrap"}
                    width={"100%"}
                >
                    <DateRangeSelector
                        startDate={oldestDateToGet}
                        setStartDate={(e: Date | null) => {
                            if (e === null) {
                                return;
                            }
                            const newDate = new Date(e);
                            setOldestDataToGet(newDate);
                            setTimeRangeOption(null); // Unselect time-range buttons since mutually exclusive with custom date selection
                        }}
                        setEndDate={(e: Date | null) => {
                            if (e === null) {
                                return;
                            }
                            const newDate = new Date(e);
                            setLatestDateToGet(newDate);
                            setTimeRangeOption(null); // Set timeRangeOption to null
                        }}
                        endDate={latestDateToGet}
                        presetDateButtonOptions={[
                            {
                                label: "Last 30 days",
                                getDates: () => ({
                                    startDate: new Date(
                                        eodToday.getFullYear(),
                                        eodToday.getMonth(),
                                        eodToday.getDate() - 30,
                                        0,
                                        0,
                                        0,
                                        1,
                                    ),
                                    endDate: eodToday,
                                }),
                            },
                            {
                                label: "Last 7 days",
                                getDates: () => ({
                                    startDate: new Date(
                                        eodToday.getFullYear(),
                                        eodToday.getMonth(),
                                        eodToday.getDate() - 7,
                                        0,
                                        0,
                                        0,
                                        1,
                                    ),
                                    endDate: eodToday,
                                }),
                            },
                            {
                                label: "Last 24 Hours",
                                getDates: () => ({
                                    startDate: new Date(Date.now() - dayMs + 1),
                                    endDate: new Date(Date.now()),
                                }),
                            },
                            {
                                label: "Last hour",
                                getDates: () => ({
                                    startDate: new Date(Date.now() - hourMs + 1),
                                    endDate: new Date(Date.now()),
                                }),
                            },
                        ]}
                        showDescriptionText={false}
                        useStartOfDayEndOfDay={true}
                    />
                </Box>
            </Box>

            {noDataFoundForGivenTimeRange && (
                <Typography
                    variant={"h2"}
                    sx={{
                        textAlign: "center",
                        mt: 6,
                        mb: 16,
                        fontWeight: "bold",
                        color: "#78716C",
                    }}
                >
                    No data found for given time range
                </Typography>
            )}
            <ReactECharts
                notMerge={true}
                style={{
                    height: Math.min(2000, numberOfGrids * 250),
                    width: "100%",
                    opacity: noDataFoundForGivenTimeRange ? 0.3 : 1, // Grey out the graph if no data is found
                }}
                option={options}
            />
            <div>
                {/* @ts-ignore */}
                <NumericFieldWithUnit
                    value={fieldCapacity ?? 0}
                    showUnits={true}
                    databaseUnit={"m"}
                    alternateUnits={["m", "mm", "in", "ft"]}
                    onChange={(e: { value: number }) => {
                        if (e.value === 0) {
                            setFieldCapacity(undefined);
                            device.deviceTypeSpecificInformation.saturation = undefined;
                            return;
                        }
                        setFieldCapacity(e.value);
                        device.deviceTypeSpecificInformation.saturation = e.value;

                        device.edit({
                            deviceTypeSpecificInformation: device.deviceTypeSpecificInformation,
                        });
                    }}
                    label={"Field Capacity"}
                    bufferUpdates={true}
                    isRange={false}
                    unitUsecase={"stressThresholds"}
                />
                {/* @ts-ignore */}
                <NumericFieldWithUnit
                    value={onsetOfStress ?? 0}
                    showUnits={true}
                    databaseUnit={"m"}
                    alternateUnits={["m", "mm", "in", "ft"]}
                    onChange={(e: { value: number }) => {
                        if (e.value === 0) {
                            setOnsetOfStress(undefined);
                            device.deviceTypeSpecificInformation.onsetOfStress = undefined;
                            return;
                        }
                        setOnsetOfStress(e.value);
                        device.deviceTypeSpecificInformation.onsetOfStress = e.value;

                        device.edit({
                            deviceTypeSpecificInformation: device.deviceTypeSpecificInformation,
                        });
                    }}
                    label={"Onset of stress"}
                    bufferUpdates={true}
                    isRange={false}
                    unitUsecase={"stressThresholds"}
                />
            </div>
        </div>
    );
}
