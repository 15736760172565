import { LatLng, Map } from "leaflet";
import { isMobile } from "react-device-detect";

import { deviceCardPanBreakpoint } from "../../../constants";
import GlobalOptions from "../../../utils/GlobalOptions";
// Manually measured constants
const MOBILE_PAN_OFFSET = 50;
const HEADER_BAR_HEIGHT = 45;
const CHIPS_HEIGHT = 45;
const GROUP_MENU_BOTTOM_PADDING = 15;

// Only pan on mobile if the card would take up most of viewport width
export const mobilePanEnabled = () => isMobile && window.innerWidth < deviceCardPanBreakpoint;

/**
 * Pans the map to the coordinates in order to bring center the icon in the viewable map area and avoid the menu/card
 */
interface CenterInViewableAreaProps {
    mapComponent: Map;
    cardHeight: number;
    iconLatLng: LatLng;
    padding?: number;
    buffer?: number;
}
export const centerInViewableArea = ({
    mapComponent,
    cardHeight,
    iconLatLng,
    padding = GROUP_MENU_BOTTOM_PADDING,
    buffer = MOBILE_PAN_OFFSET,
}: CenterInViewableAreaProps) => {
    const mapBounds = mapComponent.getBounds();
    const bottomRightLatLng = mapBounds.getSouthEast();
    const bottomRightContainerPoint = mapComponent.latLngToContainerPoint(bottomRightLatLng);

    // Calculate menu height & project on to the map to check if the icon's coordinates are obscured
    const totalHeight = cardHeight + padding + HEADER_BAR_HEIGHT;
    const { lat: latOfTopOfMenu } = mapComponent.containerPointToLatLng([
        0,
        bottomRightContainerPoint.y - totalHeight - buffer,
    ]);

    const latitudePerPixel =
        (mapComponent.containerPointToLatLng([0, 0]).lat - mapComponent.containerPointToLatLng([0, 100]).lat) / 100;

    // Check if the icon would be obscured by the menu, accounting for hemisphere
    const isNorthernHemisphere = iconLatLng.lat >= 0;
    const isIconObscured = isNorthernHemisphere
        ? latOfTopOfMenu > iconLatLng.lat // Northern hemisphere: menu top should be below icon
        : latOfTopOfMenu < iconLatLng.lat; // Southern hemisphere: menu top should be above icon

    if (isIconObscured) {
        // How much to pan to bring the icon into view
        const panOffset =
            (latitudePerPixel * (totalHeight - (GlobalOptions.showChipsAtSameTimeAsInfoCards ? CHIPS_HEIGHT : 0))) /
            2.0;

        mapComponent.panTo({
            lat: iconLatLng.lat + (isNorthernHemisphere ? -panOffset : panOffset),
            lng: iconLatLng.lng,
        });
    }
};
