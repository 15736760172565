import "./ManualModeToggle.scss";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GenerateUID, minuteMs, secondMs } from "verdiapi/dist/HelperFunctions";

import { useSnackbar } from "../../../hooks/useSnackbar";
import { ManualModeButtonGroup } from "./ManualModeButtonGroup";
import { ManualModeExplanation } from "./ManualModeExplanation";
import { StatusTimers } from "./StatusTimers";
import { ManualModeTransitionStatuses } from "./types";
import { useManualModeTransitionStatus } from "./useManualModeTransitionStatus";

export function ManualModeToggle({ device }) {
    const { t } = useTranslation(["specializedComponents"], { keyPrefix: "manualModeToggle" });
    const { enqueueSnackbar } = useSnackbar();

    const [manualOverrideInfo, setManualOverrideInfo] = useState({
        ...device?.sproutNetworkInfo?.manualOverrideInfo,
    });
    const [deviceLastContact, setDeviceLastContact] = useState(device?.sproutNetworkInfo?.lastContact);
    const [lastRefresh, setLastRefresh] = useState(new Date(device.lastRefreshed.valueOf()).valueOf());
    const [requestSending, setRequestSending] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmDialogText, setConfirmDialogText] = useState(t("confirmDialog.areYouSure"));
    const [confirmDialogSetting, setConfirmDialogSetting] = useState("none");

    const { manualModeTransitionStatus, resetManualModeTransitionStatus, targetState } = useManualModeTransitionStatus({
        deviceLastContact,
        manualOverrideInfo,
    });
    const [showExplanation, setShowExplanation] = useState(manualOverrideInfo.deviceOverrideSetting !== targetState);

    // Show explanation text even after the transition is complete
    useEffect(() => {
        let explanationTimeout;

        if (manualModeTransitionStatus === ManualModeTransitionStatuses.Complete) {
            explanationTimeout = setTimeout(() => {
                setShowExplanation(false);
            }, 5000);
        }

        return () => {
            clearTimeout(explanationTimeout);
        };
    }, [manualModeTransitionStatus, setShowExplanation]);

    useEffect(() => {
        if (!showExplanation) {
            resetManualModeTransitionStatus();
        }
    }, [showExplanation, resetManualModeTransitionStatus]);

    const updateFromDevice = (minAgeOfLastRefresh = 1.5 * secondMs) => {
        if (Math.abs(Date.now() - (device.lastRefreshed || 0).valueOf()) > minAgeOfLastRefresh && device?.refresh) {
            device.refresh().catch((e) => {
                console.warn("Failed to refresh device on button click");
                console.warn(e);
            });
        }
    };

    React.useEffect(() => {
        const listenerID = GenerateUID("DeviceManualOverrideUpdateListener");
        device.onChange.addListener(() => {
            setManualOverrideInfo({ ...device?.sproutNetworkInfo?.manualOverrideInfo });
            setDeviceLastContact(device?.sproutNetworkInfo?.lastContact);
            setLastRefresh(new Date(device.lastRefreshed.valueOf()).valueOf());
        }, listenerID);
        return () => {
            device.onChange.removeListener(listenerID);
        };
    }, []);

    // make sure the device is up to date whenever this component appears
    React.useEffect(() => {
        updateFromDevice(10 * secondMs);
    }, []);

    // In the worst case, we can 99% guarantee the valve action occurs in 3 uplink periods
    // and the action+confirmation occurs in 5 uplink periods
    // If uplink period is not given, we default to 20 minutes
    const uplinkPeriod = device.sproutNetworkInfo?.currentStandardUplinkPeriod ?? 20 * minuteMs;
    const timeToCompleteMinutes = (5 * uplinkPeriod) / minuteMs;
    const valveActionMinutes = (3 * uplinkPeriod) / minuteMs;

    const setManualMode = async (targetMode, successStatement, failStatement) => {
        setRequestSending(true);
        try {
            await device.setManualMode(targetMode);
            enqueueSnackbar(
                successStatement || t("valveActionTimeEstimate", { action: "$t(sync)", minutes: valveActionMinutes }),
                {
                    variant: "success",
                },
            );
            setManualOverrideInfo({ ...device?.sproutNetworkInfo?.manualOverrideInfo });
            setDeviceLastContact(device?.sproutNetworkInfo?.lastContact);
            setLastRefresh(Date.now());
            setShowExplanation(targetMode !== manualOverrideInfo.deviceOverrideSetting);
        } catch (e) {
            enqueueSnackbar(failStatement || t("snackbarText.deviceSyncFailed"), { variant: "error" });
        }
        setConfirmDialogOpen(false);
        setRequestSending(false);
    };

    const onClickManualModeButton = async (mode) => {
        if (requestSending) {
            return;
        }
        // Reset transtion status when transitioning to another mode
        if (targetState !== mode) {
            resetManualModeTransitionStatus();
        }
        if (mode === "none") {
            setManualMode(
                "none",
                t("valveActionTimeEstimate", {
                    action: t("sync").toLowerCase(),
                    minutes: valveActionMinutes,
                }),
                t("snackbarText.deviceSyncFailed"),
            );
            return;
        }
        setConfirmDialogSetting(mode);
        setConfirmDialogText(
            t("confirmDialog.confirmAction", {
                action: {
                    open: t("open", { ns: "common", keyPrefix: "states" }).toLowerCase(),
                    closed: t("close", { ns: "common", keyPrefix: "states" }).toLowerCase(),
                }[mode],
            }),
        );
        setConfirmDialogOpen(true);
    };

    if (!device.supportsSettingManualMode || !manualOverrideInfo) {
        return null;
    }

    const valveOperationText =
        targetState === "open"
            ? t("open", { ns: "common", keyPrefix: "states" }).toLowerCase()
            : t("close", { ns: "common", keyPrefix: "states" }).toLowerCase();

    return (
        <div className={"ManualModeToggle"}>
            <Typography variant={"h5"} className={"SubcardTitle"}>
                {" "}
                {t("manualOverrideTitle")}{" "}
            </Typography>
            <ManualModeButtonGroup
                deviceLastContact={deviceLastContact}
                manualOverrideInfo={manualOverrideInfo}
                manualModeTransitionStatus={manualModeTransitionStatus}
                onClick={onClickManualModeButton}
                requestSending={requestSending}
                targetState={targetState}
            />
            {showExplanation && (
                <ManualModeExplanation
                    manualOverrideInfo={manualOverrideInfo}
                    manualModeTransitionStatus={manualModeTransitionStatus}
                    valveActionMinutes={valveActionMinutes}
                    valveOperationText={valveOperationText}
                    timeToCompleteMinutes={timeToCompleteMinutes}
                    targetState={targetState}
                />
            )}

            <div style={{ marginRight: "auto", marginTop: "12px" }}>
                <StatusTimers
                    lastRefresh={lastRefresh}
                    deviceLastContact={deviceLastContact}
                    onClickRefresh={() => {
                        updateFromDevice();
                    }}
                />
            </div>
            <Dialog
                onClose={() => {
                    if (!requestSending) {
                        setConfirmDialogOpen(false);
                    }
                }}
                open={confirmDialogOpen}
            >
                <DialogTitle>{confirmDialogText}</DialogTitle>
                <DialogContent>
                    <DialogContentText id={"alert-dialog-description"}>
                        {t("confirmDialog.explanation")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={requestSending}
                        onClick={() => {
                            setConfirmDialogOpen(false);
                        }}
                    >
                        {t("cancel", { ns: "common", keyPrefix: "actions" })}
                    </Button>
                    <Button
                        disabled={requestSending}
                        onClick={async () => {
                            setManualMode(
                                confirmDialogSetting,
                                t("valveActionTimeEstimate", {
                                    minutes: valveActionMinutes,
                                    action: valveOperationText,
                                }),
                                t("snackbarText.failedToSetManualMode"),
                            );
                        }}
                        autoFocus
                    >
                        {t("confirmDialog.buttons.useManual")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
