import { Box } from "@mui/material";

import { dimmedIconStyles } from "../constants";
import { SharedIconProps } from "../types";
import { Badge } from "./Badge";

export type DotIconProps = SharedIconProps & {
    expandedClickZone?: boolean;
};

const DEFAULT_BADGE_SIZE = 10;

/**
 * Dot Icon represents a small, collapsed, "dot" icon
 */
export function DotIcon({
    onClick,
    size,
    colorParams = {
        iconBackground: "white",
        border: "black",
        shadow: "black",
    },
    badgeParams,
    dimmed = false,
    expandedClickZone = false,
}: DotIconProps) {
    return (
        <Box onClick={onClick} sx={{ cursor: "pointer", padding: expandedClickZone ? "5px" : "0px" }}>
            <Box
                sx={{
                    position: "relative",
                    cursor: "pointer",
                    width: size,
                    height: size,
                    borderRadius: "50%",
                    boxShadow: `0px 1px 1px 1px rgba(0,0,0,0.25),
                        0px -1px 1px 1px ${colorParams.shadow} inset,
                        0px 1px 1px 1px #FFFFFF inset,
                        0px 0.2px 0.5px 0px rgba(0,0,0,0.5),
                        0 0 4px 1px rgba(0,0,0,0.2),
                        0 0 0px 0.5px rgba(0,0,0,0.2)`,
                    backgroundColor: colorParams.iconBackground,
                    border: `1px solid ${colorParams.border}`,
                    opacity: dimmed ? dimmedIconStyles.opacity : 1,
                    filter: dimmed ? dimmedIconStyles.filter : undefined,
                }}
            >
                {badgeParams?.badge && (
                    <Badge
                        size={DEFAULT_BADGE_SIZE}
                        bgColor={badgeParams?.badgeOptions?.bgColor || "white"}
                        borderColor={badgeParams?.badgeOptions?.borderColor || "black"}
                        sx={{
                            borderWidth: "1px",
                            transform: "translate(55%, -50%)",
                        }}
                    />
                )}
            </Box>
        </Box>
    );
}
